import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CredentialsService, LocalizationService } from '@app/core';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { PositionTemplatesListItem } from '@app/vinter/models/position-templates-list-item';
import { PositionService } from '@app/vinter/services/position.service';
import { ConfirmationService, MenuItem, MessageService, PrimeNGConfig } from 'primeng/api';
import { QuestionCollectionCreateComponent } from '../../question-collection/question-collection-create/question-collection-create.component';
import { OrganizationEnum, PositionFormEditModeEnum, UserGroupEnum } from '@app/shared/common/enums';
import { UserGroupService } from '@app/vinter/services/user-group.service';
import { Paginator } from 'primeng/paginator';
import { paginatorRowCount } from '@app/shared/common/constants';
import { PositionCategoryListItem } from '@app/vinter/models/position-category-list-item';
import { PositionCategoryService } from '@app/vinter/services/position-category.service';

@Component({
  selector: 'app-position-template-list',
  templateUrl: './position-template-list.component.html',
  styleUrls: ['./position-template-list.component.scss'],
  providers: [ConfirmationService, MessageService]
})
export class PositionTemplateListComponent implements OnInit {

  positionTemplate: PositionTemplatesListItem[] = [];
  positionTemplateDisplay: any[] = [];
  searchText: string;
  @Input() organizationID;
  @Input() showVinterLogo;
  visible: boolean;
  selectedItemId: string;
  selectedItemTitle: string;
  selectedItemDescription: string;
  disabledEdit: boolean;
  userRole = "";
  positionCategories: PositionCategoryListItem[] = [];
  selectedPositionCategory: PositionCategoryListItem;
  displayArray: any[] = [];
  positionCategoryId : any = null;
  languageId : any = null;
  positionLanguageId : string;
  currentLanguageId : string;
  languageCategories: any[] = [];
  selectedLanguageCategory: any;
  stepCount : number;
  welcomeText:string;
  videoURL: string;
  stepList: any [];

  @ViewChild(QuestionCollectionCreateComponent) child: QuestionCollectionCreateComponent;
  @ViewChild('paginator', { static: true }) paginator: Paginator;

  totalRecords: number = 0;
  rows: number = paginatorRowCount;
  currentPage: number = 0;
  paginatorFirst: number = 0;
  passiveCardStyle = { 'background': '#f5f5f5' };

  constructor(private service: PositionService,
    private primengConfig: PrimeNGConfig,
    public router: Router,
    private localizationService: LocalizationService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    private userGroupService: UserGroupService,
    private credentialService: CredentialsService,
    private positionCategoryService: PositionCategoryService,
    private changeDetector : ChangeDetectorRef,
    private appInfoService: AppInfoService) { }

  ngOnInit(): void {
    this.currentLanguageId = this.credentialService.getLanguage();
    this.languageId = this.currentLanguageId;
    this.positionCategoryService.getListByFilter({languageId: this.currentLanguageId}).subscribe(data => {
      this.positionCategories = data;
    });
    this.primengConfig.ripple = true;
    this.getPositionLibrary();
    this.checkUserRole();
    this.languageCategories = this.appInfoService.getLanguageCategories();
    this.selectedLanguageCategory = this.languageCategories.find(category => category.id === this.currentLanguageId);

  }

  createTemplatePosition(){
    this.router.navigate(['/position/edit'], {
        queryParams: {
            positionId: '',
            positionEditFormMode: PositionFormEditModeEnum.TemplateMode
        }
      });
  }

  getPositionLibrary() {
    this.service.GetListPositionWithOrganizationAndResponsible({languageID:this.languageId,isVinterSearch:this.isVinterList(), isTemplate: true}).subscribe(data => {
      this.createStepDisplayArray(data);
      this.positionTemplate = data.map(x => ({ show: false, ...x }));
      this.positionTemplateDisplay = JSON.parse(JSON.stringify(data.map(x => ({ show: false, ...x }))));
      for (let i = 0; i < Math.min(this.rows, this.positionTemplateDisplay.length); i++) {
        this.positionTemplateDisplay[i].show = true;
      }
      this.totalRecords = this.positionTemplate.length;
    });
  }

  createStepDisplayArray(data: any) {
    data.forEach(element => {
      const items: MenuItem[] = [];
      for (let i = 0; i < element.stepCount; i++) {
        if (i === element.stepCount - 1)
          items.push({ label: 'step', styleClass: 'last-step' });
        else
          items.push({ label: '', styleClass: 'small-step' });
      }
      element.steps = items
    });
  }

  addClicked() {
    this.router.navigate(['/../form']);
  }

  previewPosition(itemId: string, itemDescription: string, itemTitle: string,itemLanguageId:string,stepCount:number,welcomeText:string,videoURL:string) {
    this.visible = true;
    this.selectedItemId = itemId;
    this.selectedItemDescription = itemDescription;
    this.selectedItemTitle = itemTitle;
    this.positionLanguageId = itemLanguageId;
    this.stepCount = stepCount;
    this.welcomeText = welcomeText
    this.videoURL = videoURL;
  }

  changeDialogVisibility(visibility) {
     this.visible = visibility;
  }

  checkUserRole() {
    const userID = this.credentialService.getNameIdentifier();
    const userRoleObs = this.userGroupService.getUserGroupByUserID(userID).subscribe(result => {
      this.userRole = result[0].userGroupID;
      this.setDisabledStatus();
    });

  }

  setDisabledStatus() {
    if (this.userRole === UserGroupEnum.VINTERADMIN) {
      this.disabledEdit = true;
    } else if (this.userRole != UserGroupEnum.VINTERADMIN) {
      this.disabledEdit = false;
    }
    return this.userRole;

  }

  createPositionClicked() {
    this.router.navigate(['/position/edit'], {
        queryParams: {
            positionId: '',
            positionEditFormMode: PositionFormEditModeEnum.UseFromMode
        }
      });
  }

  isVinterList(){
    if(this.organizationID === OrganizationEnum.Vinter){
        return true;
    }else{
        return false;
    }
  }

  searchLanguageCategory(event: any){
    this.positionCategoryService.getListByFilter({languageID: event === null ? this.currentLanguageId : event.id}).subscribe(data => {
      this.positionCategories = data;
      this.positionCategoryId = null;
    });
    this.languageId = event?.id;
    this.service.GetListPositionWithOrganizationAndResponsible({languageID: event === null ? null : event.id,isVinterSearch:this.isVinterList(), isTemplate: true}).subscribe(data => {
        this.positionTemplate = data;
        this.createStepDisplayArray(data);
        this.positionTemplateDisplay = JSON.parse(JSON.stringify(data));
        this.onSearchChange();
    });
}

  searchCategory(event: any){
    this.positionTemplateDisplay = JSON.parse(JSON.stringify(this.positionTemplate));
    const filteredData = this.positionTemplateDisplay.filter(item => {
        return item.positionCategoryID === (event === null ? null : event.id);
    });
    this.positionTemplateDisplay = filteredData;
    this.positionCategoryId = event.id;
    this.onSearchChange();
    this.totalRecords = this.positionTemplateDisplay.length;
    this.onPageChange({first: 0, rows: this.rows, page: 0, pageCount: this.totalRecords/this.rows});
    this.changeDetector.detectChanges();
 }

  onCategoryChange(event: any){
    this.selectedPositionCategory= event.value;
    this.searchCategory(event.value);
}


  onFilterCategoryClear(){
    this.positionCategoryId = null;
    this.onSearchChange();
  }

  onFilterLanguageClear(){
    this.languageId = null;
    this.getPositionLibrary();
    this.onSearchChange();
    this.positionCategoryService.getListByFilter({languageID: this.currentLanguageId}).subscribe(data => {
      this.positionCategories = data;
    });
  }


  onFilterChange(event : any){
      this.positionCategoryId = event?.id;
         this.onSearchChange();
         this.changeDetector.detectChanges();
  }
  onSearchChange(){
    if(this.searchText !== undefined && this.searchText !== "" && this.positionCategoryId != null){
      let searchString = this.searchText.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0131]/g, "i").toLowerCase();
      this.positionTemplateDisplay = this.positionTemplateDisplay.filter(x => x.title.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0131]/g, "i").toLowerCase().includes(searchString));
      this.positionTemplateDisplay = this.positionTemplateDisplay.filter(x => x.positionCategoryID === this.positionCategoryId);
    }
    else if(this.searchText !== undefined && this.searchText !== "" && this.positionCategoryId == null){
      let searchString = this.searchText.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0131]/g, "i").toLowerCase();

      this.positionTemplateDisplay = this.positionTemplate.filter(x => x.title.normalize("NFKD").replace(/[\u0300-\u036f]/g, "").replace(/[\u0131]/g, "i").toLowerCase().includes(searchString));
    }
    else if(!(this.searchText !== undefined && this.searchText !== "") && this.positionCategoryId != null){
        this.positionTemplateDisplay = this.positionTemplate.filter(x => x.positionCategoryID === this.positionCategoryId);
        this.totalRecords = this.positionTemplateDisplay.length;
        this.onPageChange({ first: 0, rows: this.rows, page: 0, pageCount: this.totalRecords / this.rows });
        this.changeDetector.detectChanges();
    }

    else if((this.searchText == undefined || this.searchText == "") && (this.positionCategoryId == null)){
      this.positionTemplateDisplay = JSON.parse(JSON.stringify(this.positionTemplate));
    }
    this.totalRecords = this.positionTemplateDisplay.length;
    this.onPageChange({first: 0, rows: this.rows, page: 0, pageCount: this.totalRecords/this.rows});
    this.changeDetector.detectChanges();
  }


  onPageChange(event: any) {
    for(let i = 0; i<this.positionTemplateDisplay.length; i++){
      this.positionTemplateDisplay[i].show=false;
    }
    var upperBound = event.rows * (event.page + 1);
    if(upperBound > this.positionTemplateDisplay.length){
      upperBound = this.positionTemplateDisplay.length;
    }
    for(let i = event.first; i<upperBound; i++){
      this.positionTemplateDisplay[i].show=true;
    }
  }


}
