<div class="card mt-4 card-shadow" >
    <div class="mb-5">
        <p class="card-text1">{{ "Key.QuestionListCreateTitle" | translate }}</p>
        <p class="card-text2">{{ "Key.QuestionListCreateDescription" | translate }}</p>
    </div>
    <form [formGroup]="form" class="form flex ">
        <div class="w-6">
            <div class="p-fluid ">
                <div class="p-formgrid grid collection-grid">
                    <div class="field col pl-0">
                        <div class="flex ">
                            <label class="p-float-label flex label-color">{{ "Key.QuestionListName" | translate }}</label>
                            <div class="error-message flex">
                                <p class="require-tag" *ngIf="form.controls.title.touched && form.controls.title.invalid">*</p>
                            </div>
                        </div>
                        <input type="text" pInputText formControlName="title"
                            placeholder="{{'Key.QuestionListName' | translate}}">
                    </div>
                </div>
            </div>
            <div class="p-formgrid collection-grid">
                <div class="p-formgrid grid collection-grid">
                    <div class="field col pl-0 description-text">
                        <div class="flex ">
                            <label class=" p-float-label flex label-color">{{ "Key.QuestionListDescription" | translate}}</label>
                            <div class="error-message flex">
                                <p class="require-tag" *ngIf="form.controls.description.touched && form.controls.description.invalid">*</p>
                            </div>
                        </div>
                        <textarea rows="17" cols="30" pInputTextarea style="resize: vertical;min-height: 14rem;"
                            placeholder="{{'Key.QuestionListDescription' | translate}}"
                            formControlName="description">
                        </textarea>
                    </div>
                </div>
            </div>
        </div>
        <div  class="vl ml-4 mr-4"></div>
        <div class="w-6" >
            <div class="p-fluid ">
                <div *ngIf="isFormSetCompleted" class="p-formgrid grid collection-grid">
                    <div class="field col mb-0 pr-0">
                        <div class="flex ">
                            <label  class="p-float-label flex label-color">{{ "Key.QuestionListCategory" | translate}}</label>
                        </div>
                        <div class="flex flex-wrap">
                            <div *ngFor="let category of categories"
                                class="field-checkbox radio-padding col-12 md:col-6 lg:col-6">
                                <p-radioButton [inputId]="category.id" [value]="category.id"
                                    formControlName="questionCollectionCategory" class="mr-2"></p-radioButton>
                                <label [for]="category.id" class="ml-2 label-position">{{ category.name }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="p-formgrid  collection-grid">
                    <div class="field grid flex flex-wrap mr-0">
                        <div class=" pr-3 col-12 md:col-6 lg:col-6">
                            <div class="flex ">
                                <label class="p-float-label flex label-color mb-2">{{ "Key.QuestionListLanguage" | translate}}</label>
                            </div>
                            <p-dropdown class="w-full" [options]="languageCategories" formControlName="languageId"
                                value="id" optionValue="id" optionLabel="name" [filter]="false"
                                [autoDisplayFirst]="false" [showClear]="false"
                                placeholder="{{'Key.SelectLanguage' | translate}}">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>

</div>
