import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/core/configuration.service';
import { HttpBaseService } from '@app/core/http/http-base.service';
import { LocalizationService } from '@app/core/localization.service';
import { NotifyService } from '@app/core/notify.service';
import { ApiControllerEnum, EntityStateEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs/internal/Observable';
import { UserGroupListItem } from '../models/user-group-list-item';

@Injectable({
  providedIn: 'root'
})
export class UserGroupService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.UserGroup, notifyServ, localizationService, configService);
  }

  getUserGroupByUserID(userId: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('userID', userId);
    return this.getWithParams("GetUserUserGroupRelationByUserID", params);
  }
  getMemberGroupListByFilter(filter: any): Observable<UserGroupListItem[]> {
    return this.post("getMemberGroupListByFilter", filter, true);
  }
}
