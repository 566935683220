import { Component, EventEmitter, OnInit,Output} from '@angular/core';
import { LicensePlanCodeEnum, LicensePlanEnum, LicensePlanPriceType, PaymentPeriodEnum } from '@app/shared/common/enums';
import { OrganizationPlan } from '@app/vinter/models/license-plan-list-item';
import { LicensePlanUpgradeItem } from '@app/vinter/models/license/license-plan-upgrade-item';
import { LicensePlanService } from '@app/vinter/services/license-plan.service';
import { MailService } from '@app/vinter/services/mail.service';
import { OrganizationService } from '@app/vinter/services/organization.service';
import {Router} from '@angular/router';
import { MembershipService } from '@app/vinter/services/membership.service';

@Component({
  selector: 'app-license-plan-dialog',
  templateUrl: './license-plan-dialog.component.html',
  styleUrls: ['./license-plan-dialog.component.scss']
})
export class LicensePlanDialogComponent implements OnInit {
  organizationPlans: OrganizationPlan;
  organizationPlansBase: OrganizationPlan;

  licensePlanID:string;
  priceTypeEnum = LicensePlanPriceType;
  licencePlanEnum = LicensePlanEnum;
  licensePlanCodeEnum = LicensePlanCodeEnum
  isUpgradePlanDialogVisible = false;
  isUpgradePlanSentDialogVisible = false;
  isAnnual : boolean = false;
  clickedLicensePlan: LicensePlanUpgradeItem;

  constructor(private organizationService: OrganizationService,
    private mailService: MailService,
    private router: Router,
    private licenseService: LicensePlanService,
    private membershipService: MembershipService,
  ) { }

  @Output() dialogVisibiltyEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.getOrganizationPlan();
  }
  isAnnualChanged() {
    this.switchChange();
  }

  getOrganizationPlan() {
    this.dialogVisibiltyEmitter.emit(false);
    this.licenseService.GetLicensePlanList().subscribe(plans => {
      this.organizationPlans = plans;
      this.organizationPlans.licensePlans.forEach(element => {
        element.compareValue = this.comparePrices(this.organizationPlans,element)
      });
      this.organizationPlansBase=JSON.parse(JSON.stringify(plans));
      this.switchChange();
    });
  }

  redirectToVinterMe() {
    window.open('https://vinter.me/contact', '_blank');
    }

 switchChange(){
  if (!this.isAnnual) {
    this.organizationPlans.licensePlans = this.organizationPlansBase.licensePlans.filter(x => x.paymentPeriod !== LicensePlanPriceType.Yearly);
      this.dialogVisibiltyEmitter.emit(true);
  }

  if (this.isAnnual) {
    this.organizationPlans.licensePlans = this.organizationPlansBase.licensePlans.filter(x => x.paymentPeriod === LicensePlanPriceType.Yearly);
      this.dialogVisibiltyEmitter.emit(true);
  }
 }
  toggleUpgradeDialog(licensePlanName: string, licencePlanId) {
    this.clickedLicensePlan = new LicensePlanUpgradeItem(licensePlanName,licencePlanId);
    this.isUpgradePlanDialogVisible = true;
   }

  sendUpgradeLicenseMail() {
    this.mailService.SendUpgradeLicenseMail(this.clickedLicensePlan).subscribe(x => {
      this.isUpgradePlanDialogVisible = false;
      this.isUpgradePlanSentDialogVisible = true;
    });
  }

  redirectToStripeCheckoutSessionUpgradePlan(licensePlanId){
    this.membershipService.requestMemberSessionUpgradePlan({licensePlanID:licensePlanId}).subscribe((session) => {
      this.membershipService.redirectToCheckout(session);
  });
  }

  comparePrices(organizationLicense:any,license:any) : boolean {
    if (organizationLicense.licensePlanCode == LicensePlanCodeEnum.Enterprise || organizationLicense.licensePlanCode == LicensePlanCodeEnum.EnterpriseAnnual || organizationLicense.licensePlanCode == LicensePlanCodeEnum.EnterpriseAnnualPlus)
    {
      return true;
    }
    else if(license.licenseCode != LicensePlanCodeEnum.Enterprise 
      && license.licenseCode != LicensePlanCodeEnum.EnterpriseAnnual && license.licenseCode != LicensePlanCodeEnum.EnterpriseAnnualPlus
      && organizationLicense.paymentPeriod == license.paymentPeriod){
        const currentLicensePrice = +organizationLicense.licencePrice;
        const licensePrice = +license.price;
        return currentLicensePrice > licensePrice;
    }
    else if (license.licenseCode != LicensePlanCodeEnum.Enterprise && license.licenseCode != LicensePlanCodeEnum.EnterpriseAnnual && license.licenseCode != LicensePlanCodeEnum.EnterpriseAnnualPlus
      && organizationLicense.paymentPeriod != license.paymentPeriod){
        if(organizationLicense.paymentPeriod === PaymentPeriodEnum.Monthly){
          if(organizationLicense.licensePlanCode === LicensePlanCodeEnum.Basic){
            return false;
          }else if(organizationLicense.licensePlanCode === LicensePlanCodeEnum.Essential){
            return !(license.licenseCode === LicensePlanCodeEnum.PremiumAnnual || license.licenseCode === LicensePlanCodeEnum.EssentialAnnual)
          }else if(organizationLicense.licensePlanCode === LicensePlanCodeEnum.Premium){
            return !(license.licenseCode === LicensePlanCodeEnum.PremiumAnnual )
          }else if(organizationLicense.licensePlanCode === LicensePlanCodeEnum.EnterpriseOneShot){
            return false;
          }else if(organizationLicense.licensePlanCode === LicensePlanCodeEnum.Trial){
            return false;
          }else{
            return true;
          }
        }else{
          if(organizationLicense.licensePlanCode === LicensePlanCodeEnum.BasicAnnual){
            return license.licenseCode === LicensePlanCodeEnum.Basic
          }else if(organizationLicense.licensePlanCode === LicensePlanCodeEnum.EssentialAnnual){
            return !(license.licenseCode === LicensePlanCodeEnum.Premium)
          }else if(organizationLicense.licensePlanCode === LicensePlanCodeEnum.PremiumAnnual){
            return true;
          }else if(organizationLicense.licensePlanCode === LicensePlanCodeEnum.EnterpriseOneShot){
            return false;
          }else if(organizationLicense.licensePlanCode === LicensePlanCodeEnum.Trial){
            return false;
          }else{ 
            return true;
          }
        }
    }
    return false;
  }

}
