<div class="page-mask">
    <div class="image-container">
        <img src="../../../../assets/vinter/icons/zoom-out.svg" class="close-icon cursor-pointer" width="30px" (click)="zoomOut()" />
        <img src="../../../../assets/vinter/icons/zoom-in.svg" class="close-icon cursor-pointer" width="30px" (click)="zoomIn()" />
        <img *ngIf="isDownloadVisible" src="../../../../assets/vinter/icons/download.svg" class="close-icon cursor-pointer" width="28px" (click)="downloadPdf()" />
        <img src="../../../../assets/vinter/icons/close-white.svg" class="close-icon cursor-pointer" (click)="closeClicked()" />
    </div>
    <pdf-viewer [src]="pdfFileUrl" [render-text]="renderText" [fit-to-page]="true" [original-size]="true"
        style="height: 90%; z-index: 9999; position: fixed; top:5%;" class="center-responsive"></pdf-viewer>
</div>
