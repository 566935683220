<div>
    <div class="formgrid grid pr-4">
        <form (ngSubmit)="addItem()" class="flex w-full">
            <div class="field col-11">
                <span class="p-input-icon-left w-full">
                    <i class="pi pi-envelope"></i>
                    <input type="text" placeholder='{{ "Key.WriteEmail" | translate }}' pInputText [(ngModel)]="mailItem" name="newItem"
                        class="w-full">
                </span>
            </div>
            <div class="field col-1">
                <p-button icon="pi pi-plus" type="submit" styleClass="mr-2 mb-2 p-button-outlined"></p-button>
            </div>
        </form>
    </div>

    <div [ngStyle]="{'display': (isWarningVisible) ? '' : 'none'}">
        <div class="m-2 flex justify-content-start send-mail-warning">
            <span class="pi pi-exclamation-triangle warning-icon flex align-item-center"></span>
            <label class="flex font-medium message-color ml-2">{{ "Key.SendMailWarningMessage" | translate }}</label>
        </div>
    </div>

    <div class="col-12 flex flex-wrap">
        <div *ngFor="let item of values; let i = index" class="mail-invite-item">
            <div *ngIf="item.dataValidation" class="custom-chip"
                [ngClass]="item.isMailHasError ? 'custom-chip-wrong' : 'custom-chip'">
                <p-avatar label="{{item.mail[0].toUpperCase()}}" styleClass="mr-2"
                    [style]="{ 'background-color': '#E5EDFF', color: '#002995' }" shape="circle"></p-avatar>
                <p class="mail-text"> {{item.mail}} </p>
                <p-button icon="pi pi-times" class="close mail" (click)="deleteCard(i)"
                    styleClass="p-button-rounded p-button-text"></p-button>
            </div>
            <div *ngIf="!item.dataValidation" class="custom-chip-wrong" [pTooltip]="item.errorMessage"
                tooltipPosition="top">
                <p-avatar class="wrong" label="{{item.mail[0].toUpperCase()}}" styleClass="mr-2"
                    [style]="{ 'background-color': '#FFE3E3', color: '#9A0101' }" shape="circle"></p-avatar>
                <p class="mail-text"> {{item.mail}} </p>
                <p-button icon="pi pi-times" (click)="deleteCard(i)" class="wrongData close mail"
                    styleClass="p-button-rounded p-button-text"></p-button>
            </div>
        </div>
    </div>
</div>
<p-toast class="dialogToaster" position="bottom-center"></p-toast>
