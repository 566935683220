<div>
    <p-timeline [value]="applicationSteps">
        <ng-template pTemplate="marker" let-item>
            <div class="wrapper" [ngClass]="{
                'HRCompletedLine': item.status === applicationStepStatus.HRCompleted || item.status === applicationStepStatus.ApplicantCompleted,
                'ReadyLine': item.status === applicationStepStatus.InProgress || item.status === applicationStepStatus.Ready}">
                <span class="custom-marker">
                    <div *ngIf="item.status === applicationStepStatus.HRCompleted || item.status === applicationStepStatus.ApplicantCompleted">
                        <img class="custom-svg-icon" src="/assets/vinter/icons/timeline-ellipse-green.svg" height="35px" width="35px" />
                    </div>
                    <div *ngIf="item.status === applicationStepStatus.InProgress || item.status === applicationStepStatus.Ready">
                        <img class="custom-svg-icon" src="/assets/vinter/icons/positionDetailEllipse.svg" height="35px" width="35px" />
                    </div>
                    <div *ngIf="item.status === applicationStepStatus.NotStarted">
                        <img class="custom-svg-icon" src="/assets/vinter/icons/timeline-ellipse-gray.svg" height="35px" width="35px" />
                    </div>
                </span>
                <p class="text-over">{{item.order}}</p>
            </div>
        </ng-template>

        <ng-template pTemplate="content" let-item>
            <div class="grid flex flex-nowrap overflow-hidden align-content-center">
                <div class="col-2 w-auto step-text align-items-center justify-content-center pr-0 step-text-display">{{'Key.Step' | translate}}</div>
                <div class="col-6 w-auto shorten-text flex align-items-center justify-content-center overflow-x-hidden" [ngClass]="{'step-title-text-pending': item.status === applicationStepStatus.NotStarted, 'step-title-text': item.status !== applicationStepStatus.NotStarted} ">
                    <div class="shorten-text" style="max-width: -webkit-fill-available;">{{ item.name }}</div>
                </div>

                <div class="col-4 w-auto flex align-items-center justify-content-center">
                    <div *ngIf="item.status === applicationStepStatus.HRCompleted || item.status === applicationStepStatus.ApplicantCompleted" style="margin-left: -1.25rem;">
                        <p-chip label="{{'Key.Completed' | translate}}" [style]="{'background-color': '#CFF0BF', 'color': '#37AC00'}"></p-chip>
                    </div>
                    <div *ngIf="item.status === applicationStepStatus.InProgress || item.status === applicationStepStatus.Ready" style="margin-left: -1.25rem;">
                        <p-chip label="{{'Key.StepInProgress' | translate}}" [style]="{'background-color': '#CFDBF9', 'color': '#2962FF'}"></p-chip>
                    </div>
                    <div *ngIf="item.status === applicationStepStatus.NotStarted" style="margin-left: -1.25rem;">
                        <p-chip label="{{'Key.StepPending' | translate}}" [style]="{'background-color': '#E6E7E8', 'color': '#9B9EA3'}"></p-chip>
                    </div>
                </div>
            </div>

        </ng-template>
    </p-timeline>
</div>
