import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { PositionService } from '@app/vinter/services/position.service';
import { vinterOrganizationGuid } from './../../../../shared/common/constants';
import { CredentialsService } from '@app/core/authentication/credentials.service';

@Component({
  selector: 'app-vinter-library-list',
  templateUrl: './vinter-library-list.component.html',
  styleUrls: ['./vinter-library-list.component.scss']
})
export class VinterLibraryListComponent implements OnInit{

  data: string;
  showVinterLogo: boolean;


  constructor(private service: PositionService,
    private appInfoService: AppInfoService,
    private credentialService: CredentialsService,
    public router: Router) { }

  ngOnInit(): void {
    
    this.setData();
  }

  setData(){
    this.data=this.credentialService.getVinterOrganizationID();
    this.showVinterLogo = true;
  }


}
