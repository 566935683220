<div class="grid flex mt-4">
    <div class="field flex w-6 pl-3 justify-content-start">
        <p-button *ngIf="!isPersonalityOrLanguageSelected && !isFromEditCurrent" styleClass="p-button-custom" label="{{'Key.AddQuestionToList' | translate}}" (click)="saveQuestionList()">
        </p-button>
        <p-button *ngIf="!isPersonalityOrLanguageSelected && isFromEditCurrent" [disabled]="!(tempSourceQuestions.length > 0 || (yourQuestionListTitle ?? false))" styleClass="p-button-custom" label="{{'Key.Update' | translate}}" (onClick)="saveQuestionList()">
        </p-button>
    </div>
</div>
<div>
    <div class="p-formgrid grid collection-grid">
        <div class="field col mt-3">
            <input type="text" ngClass="title-text-area" *ngIf="(formType === formTypeEnum.Position && isFromEditCurrent) && !isPersonalityOrLanguageSelected" class="w-full" rows="5" cols="30" pInputTextarea
                [(ngModel)]="yourQuestionListTitle" required>
            <span *ngIf="(formType !== formTypeEnum.Position || !isFromEditCurrent) || isPersonalityOrLanguageSelected" class="add-question-text-span">
                <label class="add-question-text-label font-bold text-800 text-2xl"> {{yourQuestionListTitle }}</label>
            </span>
            <span *ngIf="(formType !== formTypeEnum.Position || !isFromEditCurrent) || isPersonalityOrLanguageSelected" >
                <label class="categoryNameOcean position-card-org-header font-bold ml-2" [ngStyle]="getStepStyle(selectedQuestionList.category)"> {{categoryName }}</label>
            </span>
        </div>
    </div>

    <div  *ngIf = "yourQuestionListDescription.length > 0" class="p-formgrid grid collection-grid">
        <div class="field col">
            <label class=" p-float-label flex label-color" *ngIf = "selectedQuestionList.organizationID != VinterOrganization" >{{ "Key.StepDescription" | translate }}</label>
            <textarea *ngIf="(formType === formTypeEnum.Position && isFromEditCurrent) && !isPersonalityOrLanguageSelected" class="w-full" rows="5" cols="30" pInputTextarea
                [(ngModel)]="yourQuestionListDescription" required></textarea>
            <span *ngIf="(formType !== formTypeEnum.Position || !isFromEditCurrent) || isPersonalityOrLanguageSelected" class="add-question-text-span">
                <label class="add-question-description"> {{yourQuestionListDescription}}</label>
            </span>
        </div>
    </div>
</div>

<div class="flex w-full">
    <div *ngIf="questionListDuration || yourQuestionListDuration || viewQuestionListDuration" class="cardSidebar flex grid ">
        <span class="flex col-12 p-0 pb-1  justify-content-center align-items-center sideBarFont">
            <img class="custom-svg-icon pr-2" src="/assets/vinter/icons/timerGray.svg" height="25px" width="25px" />
            <span class="question-info">{{"Key.TestDuration" | translate}}</span>
        </span>
        <div class="flex col-12 p-0 justify-content-center">
            <div class="lineSidebar col-12 p-0"></div>
        </div>
        <span  class="flex col-12 p-0 pt-1 justify-content-center sideBarTimer">
            <span *ngIf="(questionListDuration || viewQuestionListDuration) && (this.selectedQuestionCategory == QuestionCollectionCategoryEnum.TechnicalTest || this.selectedQuestionCategory == QuestionCollectionCategoryEnum.CompetenceTest)"
             class="pr-1 ">
             <div *ngIf="isFromEditCurrent">
                {{questionListDuration}}
             </div>
             <div *ngIf="!isFromEditCurrent">
                {{viewQuestionListDuration}}
             </div>
             </span>
            <span *ngIf="yourQuestionListDuration && this.selectedQuestionCategory != QuestionCollectionCategoryEnum.TechnicalTest && this.selectedQuestionCategory != QuestionCollectionCategoryEnum.CompetenceTest"
             class="pr-1 ">{{yourQuestionListDuration}}</span>
            <span>{{"Key.Minute" | translate}}</span>
        </span>
    </div>

    <div class="cardSidebar flex grid ">
        <span class="flex col-12 p-0 pb-1  justify-content-center align-items-center sideBarFont">
            <img class="custom-svg-icon pr-2" src="/assets/vinter/icons/positionDetaillanguage.svg" height="25px"
                width="25px" />
            <span class="question-info">{{"Key.Language" | translate}}</span>
        </span>
        <div class="flex col-12 p-0 justify-content-center">
            <div class="lineSidebar col-12 p-0"></div>
        </div>
        <span class="flex col-12 pt-1 p-0 justify-content-center sideBarTimer">
            {{selectedLanguageDisplay}}
        </span>
    </div>


    <div class="cardSidebar wide flex grid ">
        <img class="mr-1 ml-3" src="../../../../../assets/vinter/icons/openEndedBlue.svg" width="24px">
        <div class="mr-2 align-item-center align-self-center font-semibold  open-ended-answer-style">
            {{openEndedAnswerCount}}</div>
        <div class="vl"></div>
        <img class="mr-1 ml-3" src="../../../../../assets/vinter/icons/multipleChoiceAnswerPurple.svg" width="24px">
        <div class="mr-2 align-item-center align-self-center font-semibold  multiple-choice-answer-style">
            {{multipleChoiceAnswerCount}}</div>
        <div class="vl"></div>
        <img class="mr-1 ml-3" src="../../../../../assets/vinter/icons/videoAnswerBlue.svg" width="24px">
        <div class="mr-2 align-item-center align-self-center font-semibold  video-answer-style" >{{videoAnswerCount}}
        </div>
        <div class="vl"></div>
        <img class="mr-1 ml-3" src="../../../../../assets/vinter/icons/coding-color.svg" width="24px">
        <div class="mr-2 align-item-center align-self-center font-semibold  coding-answer-style" >{{codingAnswerCount}}
        </div>
    </div>
</div>

<div *ngIf="isLanguageSelected" class="flex w-full">
    <div class="cardSidebar wide flex grid align-items-center">
        <app-custom-chip [label]="writingCount" [svgUrl]="'assets/vinter/icons/writing-icon.svg'" [alt]="'Writing'" [bgColorClass]="'writing-class'" [imgSize]="'16px'"></app-custom-chip>
        <div class="vl"></div>
        <app-custom-chip [label]="readingCount" [svgUrl]="'assets/vinter/icons/reading-icon.svg'" [alt]="'Reading'" [bgColorClass]="'reading-class'" [imgSize]="'16px'"></app-custom-chip>
        <div class="vl"></div>
        <app-custom-chip [label]="listeningCount" [svgUrl]="'assets/vinter/icons/listening-icon.svg'" [alt]="'Listening'" [bgColorClass]="'listening-class'" [imgSize]="'16px'"></app-custom-chip>
        <div class="vl"></div>
        <app-custom-chip [label]="vocabularyCount" [svgUrl]="'assets/vinter/icons/vocabulary-icon.svg'" [alt]="'Vocabulary'" [bgColorClass]="'vocabulary-class'" [imgSize]="'16px'"></app-custom-chip>
        <div class="vl"></div>
        <app-custom-chip [label]="grammerCount" [svgUrl]="'assets/vinter/icons/grammer-icon.svg'" [alt]="'Grammer'" [bgColorClass]="'grammer-class'" [imgSize]="'16px'"></app-custom-chip>
        <div *ngIf="selectedQuestionList.subCategory === EQuestionCollectionSubCategory.GeneralEnglish" class="vl"></div>
        <app-custom-chip *ngIf="selectedQuestionList.subCategory === EQuestionCollectionSubCategory.GeneralEnglish" [label]="speakingCount" [svgUrl]="'assets/vinter/icons/speaking-icon.svg'" [alt]="'speaking'" [bgColorClass]="'speaking-class'" [imgSize]="'16px'"></app-custom-chip>
    </div>
</div>

<div *ngIf="checkboxDisabled" class="checkbox-div">
    <p-checkbox *ngIf="!isPersonalityOrLanguageSelected && (selectedQuestions.length > 0 || !isFromEditCurrent)" [(ngModel)]="selectAllItems" [binary]="true" inputId="binary" (onChange)="selectAll($event.checked)"
        label="Select All"></p-checkbox>
</div>

<div class="w-full select-question-page-h" *ngIf="showQuestions">
    <div *ngIf="tempSourceQuestions" class=" list-max-height">
        <ng-container *ngIf="tempSourceQuestions.length > 0">
            <div *ngFor="let question of tempSourceQuestions; let i = index" class="question-container">
                <div class="grid" [ngClass]="{'opacity-60': question.isDeprecated}">
                    <div class = "question-number-container ml-3 -mr-5">
                        <div class="question-number col-1  mr-0 pr-3 img-position" style="padding-top: 2.2rem;">
                            {{ i + 1 }}.
                        </div>
                    </div>

                    <div *ngIf="checkboxDisabled && !isPersonalityOrLanguageSelected" class="col-1 justify-content-center flex align-items-start checkbox-position
                     -ml-3">
                        <p-checkbox *ngIf="!question.isDeprecated" [(ngModel)]="selectedItems[i]" [binary]="true" (onChange)="checkChanged(i)">
                        </p-checkbox>
                    </div>

                    <div class="col-1 w-min pt-4 pr-0 img-position pl-0 ml-2"
                        *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Video">
                        <img class="custom-svg-question-icon align-self-center ml-2"
                            src="/assets/vinter/icons/videoQuestion.svg" />
                    </div>
                    <div class="col-1 w-min pt-4 pr-0 img-position pl-0 ml-2"
                        *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.OpenEnded">
                        <img class="custom-svg-question-icon align-self-center ml-2"
                            src="/assets/vinter/icons/openEndedQuestion.svg" />

                    </div>
                    <div class="col-1 w-min pt-4 pr-0 img-position pl-0 ml-2"
                        *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Coding">
                        <img class="custom-svg-question-icon align-self-center ml-2"
                            src="/assets/vinter/icons/coding.svg" />

                    </div>
                    <div class="col-1 w-min pt-4 pr-1 img-position pl-0 ml-2"
                        *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice">
                        <img class="custom-svg-question-icon align-self-center ml-2" src="/assets/vinter/icons/Quiz.svg" />
                    </div>
                    <div class="w-min pr-0 mt-4 flex pl-0 pt-2 -mr-4" *ngIf="question.questionCategory === questionCategoryType.Writing">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/writing-icon-circled.svg'" [alt]="'Writing'" [bgColorClass]="'writing-class'" [imgSize]="'24px'"></app-custom-chip>
                    </div>
                    <div class="w-min pr-0 align-items-center mt-1 flex pl-0 pt-2 -mr-4" *ngIf="question.questionCategory === questionCategoryType.Listening">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/listening-icon-circled.svg'" [alt]="'Listening'" [bgColorClass]="'listening-class'" [imgSize]="'24px'"></app-custom-chip>
                    </div>
                    <div class="w-min pr-0 mt-4 flex pl-0 pt-2 -mr-4" *ngIf="question.questionCategory === questionCategoryType.Reading">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/reading-icon-circled.svg'" [alt]="'Reading'" [bgColorClass]="'reading-class'" [imgSize]="'24px'"></app-custom-chip>
                    </div>
                    <div class="w-min pr-0 mt-4 flex pl-0 pt-2 -mr-4" *ngIf="question.questionCategory === questionCategoryType.Vocabulary">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/vocabulary-icon-circled.svg'" [alt]="'Vocabulary'" [bgColorClass]="'vocabulary-class'" [imgSize]="'24px'"></app-custom-chip>
                    </div>
                    <div class="w-min pr-0 mt-4 flex pl-0 pt-2 -mr-4" *ngIf="question.questionCategory === questionCategoryType.Grammer">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/grammer-icon-circled.svg'" [alt]="'Grammer'" [bgColorClass]="'grammer-class'" [imgSize]="'24px'"></app-custom-chip>
                    </div>
                    <div class="w-min pr-0 mt-4 flex pl-0 pt-2 -mr-4" *ngIf="question.questionCategory === questionCategoryType.Speaking">
                        <app-custom-chip [svgUrl]="'assets/vinter/icons/speaking-icon-circled.svg'" [alt]="'speaking'" [bgColorClass]="'bg-transparent'" [imgSize]="'23px'"></app-custom-chip>
                    </div>
                    <div class="question-content col flex">
                        <div class="flex w-full flex-wrap">
                            <div class="w-full ml-0 flex align-items-center gap-2" *ngIf="!question.isDeprecated && isFromEditCurrent">
                                    <span class="question-origin origin-collection-title font-medium">
                                        {{question?.originQuestionCollection?.title}}
                                    </span>
                                    <ng-container *ngIf="this.selectedQuestionCategory == QuestionCollectionCategoryEnum.TechnicalTest || this.selectedQuestionCategory == QuestionCollectionCategoryEnum.CompetenceTest">
                                        <div class="flex align-items-center">
                                            <div class="flex align-items-center mr-3">
                                                <div *ngIf="question?.originQuestionCollection?.title ?? false" class="border-right-2 mr-3 h-1rem border-blue-50"></div>
                                                <img class="" src="/assets/vinter/icons/timer.svg" height="16px" width="16px" />
                                                <span *ngIf="!editingQuestions[question.id ?? question.dummyID].duration" class="font-medium ml-1 mr-2 text-blue-400">{{question.duration}} '</span>
                                                <input *ngIf="editingQuestions[question.id ?? question.dummyID].duration" class="" type="number" pInputText [(ngModel)]="tempDurations[question.id ?? question.dummyID].duration" min="1" pattern="^[0-9]*$" (input)="validateWholeNumber($event)"
                                                required>
                                                <img *ngIf="!editingQuestions[question.id ?? question.dummyID].duration" class="cursor-pointer" (click)="editDuration(question.id ?? question.dummyID)" src="/assets/vinter/icons/edit.svg" height="16px" width="16px" />
                                                <i *ngIf="editingQuestions[question.id ?? question.dummyID].duration" class="pi pi-check cursor-pointer" (click)="saveDuration(question.id ?? question.dummyID)"></i>
                                            </div>
                                            <div class="flex align-items-center">
                                                <div class="border-right-2 mr-3 h-1rem border-blue-50"></div>
                                                <img class="" src="/assets/vinter/icons/question-weight-icon.svg" height="16px" width="16px" />
                                                <span *ngIf="!editingQuestions[question.id ?? question.dummyID].weight" class="font-medium ml-1 mr-2 text-blue-800">{{question.weight}}</span>
                                                <input *ngIf="editingQuestions[question.id ?? question.dummyID].weight" class="" type="number" pInputText [(ngModel)]="tempDurations[question.id ?? question.dummyID].weight" min="1" max="5" pattern="^[1-5]*$" required  (input)="validateWeight(question.id ?? question.dummyID)">
                                                <img *ngIf="!editingQuestions[question.id ?? question.dummyID].weight" class="cursor-pointer" (click)="editWeight(question.id ?? question.dummyID)" src="/assets/vinter/icons/edit.svg" height="16px" width="16px" />
                                                <i *ngIf="editingQuestions[question.id ?? question.dummyID].weight" class="pi pi-check cursor-pointer" (click)="saveWeight(question.id ?? question.dummyID)"></i>
                                            </div>

                                        </div>

                                    </ng-container>
                            </div>

                            <div class="w-full ml-0 flex align-items-center gap-2" *ngIf="!isFromEditCurrent">

                                <ng-container *ngIf="this.selectedQuestionCategory == QuestionCollectionCategoryEnum.TechnicalTest || this.selectedQuestionCategory == QuestionCollectionCategoryEnum.CompetenceTest">
                                    <div class="flex align-items-center">
                                        <div class="flex align-items-center mr-3">
                                            <img class="" src="/assets/vinter/icons/timer.svg" height="16px" width="16px" />
                                            <span *ngIf="!editingQuestions[question.id ?? question.dummyID].duration" class="font-medium ml-1 mr-2 text-blue-400">
                                                <div *ngIf="(tempDurations[question.id ?? question.dummyID].duration ?? false)">
                                                    {{tempDurations[question.id ?? question.dummyID].duration}} '
                                                </div>
                                                <div *ngIf="!(tempDurations[question.id ?? question.dummyID].duration ?? false)">
                                                    {{question.duration}} '
                                                </div>
                                            </span>
                                            <input *ngIf="editingQuestions[question.id ?? question.dummyID].duration" class="" type="number" pInputText [(ngModel)]="tempDurations[question.id ?? question.dummyID].duration" min="1" pattern="^[0-9]*$" (input)="validateWholeNumber($event)"
                                            required>
                                            <img *ngIf="!editingQuestions[question.id ?? question.dummyID].duration" class="cursor-pointer" (click)="editDuration(question.id ?? question.dummyID)" src="/assets/vinter/icons/edit.svg" height="16px" width="16px" />
                                            <i *ngIf="editingQuestions[question.id ?? question.dummyID].duration" class="pi pi-check cursor-pointer" (click)="saveDuration(question.id ?? question.dummyID)"></i>

                                        </div>
                                        <div class="flex align-items-center">
                                            <div class="border-right-2 mr-3 h-1rem border-blue-50"></div>
                                            <img class="" src="/assets/vinter/icons/question-weight-icon.svg" height="16px" width="16px" />
                                            <span *ngIf="!editingQuestions[question.id ?? question.dummyID].weight" class="font-medium ml-1 mr-2 text-blue-800">
                                                <div *ngIf="(tempDurations[question.id ?? question.dummyID].weight ?? false)">
                                                    {{tempDurations[question.id ?? question.dummyID].weight}}
                                                </div>
                                                <div *ngIf="!(tempDurations[question.id ?? question.dummyID].weight ?? false)">
                                                    {{question.weight}}
                                                </div>
                                            </span>
                                            <input *ngIf="editingQuestions[question.id ?? question.dummyID].weight" class="" type="number" pInputText [(ngModel)]="tempDurations[question.id ?? question.dummyID].weight" min="1" max="5" pattern="^[1-5]*$" required  (input)="validateWeight(question.id ?? question.dummyID)">
                                            <img *ngIf="!editingQuestions[question.id ?? question.dummyID].weight" class="cursor-pointer" (click)="editWeight(question.id ?? question.dummyID)" src="/assets/vinter/icons/edit.svg" height="16px" width="16px" />
                                            <i *ngIf="editingQuestions[question.id ?? question.dummyID].weight" class="pi pi-check cursor-pointer" (click)="saveWeight(question.id ?? question.dummyID)"></i>
                                        </div>

                                    </div>


                                </ng-container>
                        </div>

                            <div class="flex w-full flex-nowrap">
                            <div *ngIf="!question.isDeprecated"
                            [ngClass]="{'displayMore': question.showMoreText,'description2': question.showMoreText,'description1': !question.showMoreText}"
                            [innerHTML]="question.questionContent.questionText">
                            <!-- {{ question.questionContent.questionText }} -->
                        </div>
                        <div *ngIf="question.isDeprecated"
                        [ngClass]="{'displayMore': question.showMoreText,'description2': question.showMoreText,'description1': !question.showMoreText}"
                        [innerHTML]="question.questionContent.questionText" pTooltip="{{'Key.DisabledQuestionFromCollection' | translate}}" tooltipPosition="top" placeholder="Top">
                        <!-- {{ question.questionContent.questionText }} -->
                        </div>
                        <div class="flex align-items-start mr-2 cursor-pointer" (click)="toggleDisplay(question)">
                            <p class="more-field mb-0" *ngIf="!question.showMoreText">{{'Key.More' | translate}}</p>
                            <img *ngIf="!question.showMoreText" class="align-self-start more-icon ml-2"
                                src="/assets/vinter/icons/more-icon-right.svg" />
                            <img *ngIf="question.showMoreText" class="align-self-start more-icon ml-2"
                                src="/assets/vinter/icons/more-icon-down.svg" />
                        </div>
                    </div>
                        </div>

                    </div>
                </div>
                <div *ngIf="question.showMoreText">
                    <div class="content-position">
                        <div *ngIf="question.questionContent.contentURLType == QuestionContentType.Image" class="mb-3">
                            <p-image [src]="question.questionContent.contentURLSigned ?? question.questionContent.contentURL"
                                [imageStyle]="{'width': '14rem','border-radius':'6px'}" [preview]="true"></p-image>
                        </div>

                        <div *ngIf="question.questionContent.contentURLType == QuestionContentType.Video">
                            <video class="mb-3 video-question-style" [src]="question.questionContent.contentURLSigned ?? question.questionContent.contentURL"
                                 controls controlslist="nodownload"
                                disablepictureinpicture></video>
                        </div>

                        <div *ngIf="question.questionContent.contentURLType == QuestionContentType.Audio">
                            <audio class="mb-3 audio-question-style" [src]="question.questionContent.contentURLSigned ?? question.questionContent.contentURL" controls
                                controlslist="nodownload"></audio>
                        </div>


                        <div *ngIf="question.keywords.length > 0" class="question-container-sideBar-text">
                            <div class="flex mb-3">
                                <div class="keyword-text font-medium">
                                    {{'Key.Keywords' | translate}}
                                </div>
                                <div>
                                    <p-badge class="radius" value={{question.keywords.length}}></p-badge>
                                </div>
                            </div>
                            <div>
                                <div class="inline -ml-2" *ngFor="let item of question.keywords">
                                    <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice"
                            class="answer-container">
                            <div class="flex">
                                <div class="flex flex-column gap-2">
                                    <div *ngFor="let choice of question.choiceContents; let i = index"
                                        class="radio-button-card field-checkbox"
                                        [ngClass]="{'correct-choice': choice.isCorrect}">
                                        <div class="flex align-items-center">
                                            <p-radioButton [disabled]="true" [value]="choice.isCorrect"
                                                [ngModel]="true" ></p-radioButton>
                                            <label [for]="choice.choiceText"
                                                [ngClass]="{'choice-text': !choice.isCorrect,'correct-choice-text':choice.isCorrect}"
                                                class="ml-2" [innerHTML]="choice.choiceText"></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.OpenEnded"
                            class="video-answer-container">
                            <p class="video-answer-text">{{'Key.OpenEndedQuestionExplanation' | translate}}</p>
                        </div>
                        <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Video"
                            class="video-answer-container">
                            <p class="video-answer-text">{{'Key.VideoQuestionExplanation' | translate}}</p>
                        </div>

                        <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Coding" class="content-position-coding">

                            <div *ngIf="question.questionContent.contentDetail ?? false">
                                <div class="flex">
                                    <p class = "font-semibold" >{{'Key.TestCases' | translate}}</p>
                                    <p-badge class="ml-2 radius" value={{getTestCases(question.questionContent.contentDetail).length}}></p-badge>

                                </div>

                                <div class="video-answer-container">
                                    <div *ngFor="let testCase of getTestCases(question.questionContent.contentDetail); let i = index"  class="coding-testcase-container">
                                        <div>
                                            <p class="mb-2 font-semibold">{{'Key.TestCase' | translate}} {{ i + 1 }} </p>
                                            <p class="mb-2">{{'Key.InputParameters' | translate}} {{ testCase.input }}</p>
                                            <p class="mb-0">{{'Key.ExpectedResult' | translate}} {{ testCase.output }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="video-answer-container">
                                <p class="text-gray-700 font-medium">{{'Key.CodingQuestionExplanation' | translate}}</p>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </ng-container>
        <div *ngIf="tempSourceQuestions.length === 0  && !isFromEditCurrent" class="no-questions-message">{{'Key.NoQuestions' | translate}}
        </div>
        <div *ngIf="tempSourceQuestions.length === 0 && isFromEditCurrent" class="no-questions-message">{{'Key.NotAddedYet' | translate}}
        </div>
    </div>
</div>

<div *ngIf="(!showQuestions && (selectedQuestionList.subCategory === OCEAN50 || selectedQuestionList.subCategory === OCEAN120))">
    <div  class="no-blur-question pr-1" [ngClass]="{'question-container': !showQuestions}">
        <div class="grid unselectable pt-3 w-full ml-1">
            <div class="question-number col-1 pt-4 mr-0 pr-0 mt-2">
                {{ 1 }}.
            </div>
                <div class="col-1 pt-2.5 mt-2 img-position">
                    <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/Quiz.svg"/>
                </div>
            <div class="question-content flex col" (click)="toggleDisplay(questionsCopyTest)">
                <p [ngClass]="{'displayMore': questionsCopyTest.showMoreText,'description2': questionsCopyTest.showMoreText,'description1': !questionsCopyTest.showMoreText}"
                [innerHTML]="questionsCopyTest.questionContent.questionText"></p>
                <div class="flex align-items-start  ml-4 cursor-pointer" (click)="toggleDisplay(questionsCopyTest)">
                    <p class="more-field mb-0" *ngIf="!questionsCopyTest.showMoreText"></p>
                    <img *ngIf="!questionsCopyTest.showMoreText" class="align-self-start more-icon ml-2" src="/assets/vinter/icons/more-icon-right.svg" (click)="toggleDisplay(questionsCopyTest)"/>
                    <img *ngIf="questionsCopyTest.showMoreText" class="align-self-start more-icon ml-2" src="/assets/vinter/icons/more-icon-down.svg" (click)="toggleDisplay(questionsCopyTest)"/>
                </div>
            </div>
        </div>
          <div *ngIf="questionsCopyTest.showMoreText" [ngClass]="{'opacity-60': questionsCopyTest.isDeprecated && !showDeprecatedQuestions}">
            <div class="content-position unselectable">
                <div *ngIf="questionsCopyTest.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice" class="answer-container">
                    <div class="flex">
                        <div class="flex flex-column gap-2">
                            <div *ngFor="let choice of sortChoicesByOrder(questionsCopyTest); let i = index"
                                class="radio-button-card field-checkbox"
                                [ngClass]="{'correct-choice': choice.isCorrect}">
                                <div class="flex align-items-center">
                                    <p-radioButton [disabled]="true" [value]="choice.isCorrect"
                                    [ngModel]="true" ></p-radioButton>
                                    <label [for]="choice.choiceText" [ngClass]="{'choice-text': !choice.isCorrect,'correct-choice-text':choice.isCorrect}" class="ml-2" [innerHTML]="choice.choiceText"></label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="flex flex-wrap justify-content-center align-item-center">
        <div class="warning-text-container unselectable mt-7" *ngIf = "!showQuestions" >
            <div class="col-2 pt-2.5 mt-2 img-position d-flex justify-content-center align-items-center">
                <img class="warning-custom-svg-question-icon align-self-center" src="/assets/vinter/icons/candidates_only.svg"/>
            </div>
                <p class="font-semibold flex align-items-center justify-content-center text-800 -mb-1 text-xl">{{'Key.hrWarningTitle' | translate}}</p>
                <p class="warning-text flex align-items-center justify-content-center line-height-4">{{'Key.hrWarningText' | translate}}</p>
        </div>
        <div class="absolute ml-1 unselectable">
            <div *ngFor="let i of [1, 2, 3, 4, 5, 6]"
            [ngClass]="{'question-container-ocean': !showQuestions}" class="w-full">
                <div class="grid " [ngClass]="{'opacity-60': questionsCopyTest2.isDeprecated && !showDeprecatedQuestions}">
                    <div class="question-number col-1 pt-4 mr-0 pr-0 mt-2">
                        {{ i + 1 }}.
                    </div>
                        <div class="col-1 pt-2.5 mt-2 img-position" *ngIf="questionsCopyTest2.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice">
                            <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/Quiz.svg"/>
                        </div>
                    <div class="question-content flex col" (click)="toggleDisplay(questionsCopyTest2)">
                        <p [ngClass]="{'displayMore': questionsCopyTest2.showMoreText,'description2': questionsCopyTest2.showMoreText,'description1': !questionsCopyTest2.showMoreText}"
                        [innerHTML]="questionsCopyTest2.questionContent.questionText"></p>
                        <div class="flex align-items-start  ml-4 cursor-pointer" (click)="toggleDisplay(questionsCopyTest2)">
                            <p class="more-field mb-0" *ngIf="!questionsCopyTest2.showMoreText"></p>
                            <img *ngIf="!questionsCopyTest2.showMoreText" class="align-self-start more-icon ml-2" src="/assets/vinter/icons/more-icon-right.svg"/>
                            <img *ngIf="questionsCopyTest2.showMoreText" class="align-self-start more-icon ml-2" src="/assets/vinter/icons/more-icon-down.svg"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-center"></p-toast>
