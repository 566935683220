import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { AppLayoutComponent } from './layout/app.layout.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AuthenticationGuard } from './core';
import { LoginComponent } from './shared/components/login/login.component';
import { ApplicantWelcomeComponent } from './shared/components/applicant-welcome/applicant-welcome.component';
import { SignupComponent } from './shared/components/signup/signup.component';
import { ResetPasswordComponent } from './shared/components/reset-password/reset-password.component';
import { LandingComponent } from './shared/components/landing/landing/landing.component';
import { PrivacyPolicyComponent } from './shared/components/privacy-policy/privacy-policy.component';

const routerOptions: ExtraOptions = {
    anchorScrolling: 'enabled'
};


const routes: Routes = [
    { path:'',component: LoginComponent, canActivate: [AuthenticationGuard] },
    { path:'privacy-policy',component:PrivacyPolicyComponent,pathMatch: 'full'},
    { path: 'general-application/:id', loadChildren: () => import('./shared/components/general-application-form/general-application-form.module').then(m => m.GeneralApplicationFormModule)},
    { path: 'login', component: LoginComponent, canActivate: [AuthenticationGuard] },
    { path: 'membership', loadChildren: () => import('./vinter/components/membership/membership.module').then(m => m.MembershipModule),canActivate: [AuthenticationGuard] },
    { path: 'logout', component: LoginComponent, canActivate: [AuthenticationGuard] },
    { path: 'invite', component: ApplicantWelcomeComponent, canActivate: [AuthenticationGuard] },
    { path: 'start-free-trial', component: ApplicantWelcomeComponent, canActivate: [AuthenticationGuard] },
    { path: 'signup', component: SignupComponent, canActivate: [AuthenticationGuard] },
    { path: 'reset-password', component: ResetPasswordComponent, canActivate: [AuthenticationGuard] },
    { path: '', component: AppLayoutComponent, canActivate: [AuthenticationGuard], children: [{ path: '', loadChildren: () => import('./vinter/vinter.module').then(m=>m.VinterModule)}]},
    { path:'**',component:NotFoundComponent},
  ];

@NgModule({
    imports: [RouterModule.forRoot(routes, routerOptions)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
