import { Component, Input, OnInit } from '@angular/core';
import { QuestionCollectionListItem } from '@app/vinter/models/question-collection-list';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { QuestionCollectionService } from '@app/vinter/services/question-collection.service';

@Component({
  selector: 'app-question-collection-view-question',
  templateUrl: './question-collection-view-question.component.html',
  styleUrls: ['./question-collection-view-question.component.scss']
})
export class QuestionCollectionViewQuestionComponent implements OnInit{

  questions: QuestionListItem[] = [];
  questionCollections: QuestionCollectionListItem;
  questionCollectionDisplay: QuestionCollectionListItem[]= [];

  private questionCollectionId: string;

  @Input()
  set itemId(value: string) {
      this.questionCollectionId = value;

      if(this.itemId)
        this.getQuestionsByQuestionCollectionId();
      else
        this.questions=[];
  }
  get itemId(): string {
      return this.questionCollectionId;
  }

  constructor(private questionCollectionService : QuestionCollectionService) { }

  ngOnInit(): void {

  }
  getQuestionsByQuestionCollectionId() {
    this.questionCollectionService.getDataByFilter({ Id: this.questionCollectionId }).subscribe(questionCollections => {
      this.questionCollections = questionCollections;
    });
    this.questionCollectionService.GetQuestionCollectionWihtQuestionsChoice(this.questionCollectionId).subscribe((result)=>{
      this.questions = result.questions;
    });
}

}
