import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { LocalizationService } from '@app/core';
import { maxImageSize } from '@app/shared/common/constants';
import { SpinnerHandlerService } from '@app/shared/components/loader/spinner-handler.service';
import { UploadFileService } from '@app/vinter/services/upload-file.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-video-uploader-simple',
  templateUrl: './video-uploader-simple.component.html',
  styleUrls: ['./video-uploader-simple.component.scss']
})
export class VideoUploaderSimpleComponent implements OnInit {

  @Output() onFileSelect: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onVideoConfirm: EventEmitter<any> = new EventEmitter<any>();
  isSelected: boolean = false;
  uploadedFiles: any[] = [];
  visible: boolean = true;
  fileName: string;
  videoUrl: string;
  media: any;
  maxSizeImage: number = maxImageSize;

  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadVidoeUrl: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild(FileUpload, { static: false }) fileUpload: FileUpload;
  @ViewChild('fileInput') fileInput: FileUpload;

    constructor(private api: VgApiService,
      public service: UploadFileService,
      private spinner: SpinnerHandlerService,
      private messageService: MessageService,
      private localizationService: LocalizationService) { this.api = api; }


  ngOnInit() {
  }

  onFileSelected(event: any): void {
    if (event.files[0].type != "video/webm"){
        this.messageService.add({
            severity: 'error',
            summary: this.localizationService.translatedTextList.Error,
            detail: this.localizationService.translatedTextList.InvalidImageType,
            life: 3000
        });
    }
    else{
        if(event.files[0].size > this.maxSizeImage){
            this.messageService.add({
                severity: 'error',
                summary: this.localizationService.translatedTextList.Error,
                detail: this.localizationService.translatedTextList.InvalidImageSize,
                life: 3000
            });
        }
        else{
            this.fileName = event.files[0].name;
            this.onFileSelect.emit(true);
            this.videoUrl = URL.createObjectURL(event.files[0]);
            this.isSelected = true;
            this.uploadedFiles.push(event.files[0]);
        }
    }
}

selectFile(){
    this.fileInput.choose();
}

onConfirm(): Promise<any> {
  return new Promise<any>(async(resolve, reject) => {
      let blob = await fetch(this.videoUrl).then((r) => r.blob());
      var file = new File([blob], "video1.webm");
      let formData = new FormData();
      formData.append("video", file);
      this.service.uploadVideoSimpleToS3(formData).subscribe(response => {
          this.onVideoConfirm.emit({ link: response, id: null });
          this.uploadVidoeUrl.emit(response);
          this.closeDialog.emit(null);
          resolve(response);
      },
      err => {
          reject(err);
      });
      this.onClear();
  });
}


onClear(){
    this.isSelected = false;
    this.videoUrl = null;
    this.uploadedFiles = [];
    this.fileUpload.clear();
}
}
