import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-user-avatar',
  templateUrl: './user-avatar.component.html',
  styleUrls: ['./user-avatar.component.scss']
})
export class UserAvatarComponent {

  @Input() UserFirstName: string;
  @Input() UserSurname: string;

  @Input() UserTitle: string;
  @Input() UserEmail: string;
  @Input() UserImageURL: string = null;

}
