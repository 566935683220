<div>
    <div  class="grid filter">
        <div class="col-12 md:col-8 lg:col-4 flex pr-2 pl-0 flex-grow-1 flex-shrink-1 pb-2">
            <span class="p-input-icon-left w-full ml-3 mr-2">
                <i class="pi pi-search"></i>
                <input type="text" class="border-round-1xl w-full" pInputText [(ngModel)]="searchText"
                    (ngModelChange)="onSearchChange()" placeholder="{{'Key.Search' | translate}}" />
            </span>
            <div class="pr-2 pl-2 flex flex-wrap">
                <p-dropdown class="w-full flex" [options]="positionCategories"
                [(ngModel)]="selectedPositionCategory" (onChange)="onCategoryChange($event)"
                [showClear]="true" optionLabel="name"  [filter]="true"
                 (onClear)="onFilterCategoryClear()"
                placeholder="{{'Key.SelectPositionCategory' | translate}}">
                <ng-template pTemplate="selectedItem">
                    <div class="flex align-items-center gap-2" *ngIf="selectedPositionCategory">
                        <div>{{ selectedPositionCategory.name }}</div>
                    </div>
                </ng-template>
                </p-dropdown>
            </div>
            <div class="pr-2 pl-2 flex flex-wrap">
                <p-dropdown class="w-full flex" [options]="languageCategories"
                    [(ngModel)]="selectedLanguageCategory" (ngModelChange)="searchLanguageCategory($event)"
                    (onClear)="onFilterLanguageClear()" optionLabel="name" [filter]="true"  [autoDisplayFirst]="true"
                    [showClear]="true" placeholder="{{'Key.SelectLanguage' | translate}}">
                    <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2" *ngIf="selectedLanguageCategory">
                            <div>{{ selectedLanguageCategory.name }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </div>
        </div>
    </div>
    <div>
        <div *ngIf="positionTemplateDisplay.length === 0 || !(positionTemplateDisplay ?? false)" class="no-position justify-content-center flex flex-column align-items-center">
            <img class="no-position-gift" [width]="219" height="219" src="assets/vinter/gifs/cannot-found-past-position.gif">
            <span class="justify-content-start font-semibold text-center flex text-gray-800	">{{"Key.NoActivePositionLibrary" | translate}}</span>
            <span class="justify-content-start text-center flex text-gray-400 mt-3 ">{{"Key.CreateNewPositionSuggest" | translate}}</span>
            <p-button iconPos="right" label="{{'Key.CreateYourPositionTemplate' | translate}}" styleClass="mr-2 mt-4 p-button-outlined border-blue-300" icon="pi pi-arrow-right" (onClick)="createTemplatePosition()"></p-button>
        </div>
        <div  *ngIf="positionTemplateDisplay.length > 0" class="flex flex-wrap card-container grid mt-0">
            <ng-template ngFor let-item [ngForOf]="positionTemplateDisplay" let-i="index">
                <p-card *ngIf="item.isActive === 1"
                class="col-12 md:col-6 lg:col-4 positionCard"
                [ngStyle]="{'display': (item?.show) ? 'block' : 'none'}"
                (click)="previewPosition(item.id, item.description, item.title,item.languageID,item.stepCount,item.welcomeText,item.videoURL)"
                >
                    <ng-template pTemplate="header">
                        <div class="grid">
                            <div class="col-12 position-card-org-header-div">
                               <p class="position-category">{{item.positionCategoryName}}</p>
                            </div>
                            <div class="col-12 position-card-header-div" >
                                <h5 class="position-card-header">{{item.title}}</h5>
                             </div>
                        </div>
                    </ng-template>

                    <div class="content-wrapper position-card-content mt-2" [innerHTML]="item.description">
                        <!-- {{item.description}} -->
                    </div>
                    <div class="grid" style="margin-top: 8px">
                        <div class="col-10" style="display: flex;">
                            <app-step-count [stepCount]="item.stepCount" [maxCount]="6"></app-step-count>
                        </div>
                        <div class="col-2" style="padding-top: 21px;">
                            <i class="pi pi-arrow-right cardIcon fixed-position-icon" style="color: var(--blue-500);"></i>
                        </div>
                    </div>
                </p-card>
                <p-card *ngIf="item.isActive === 0"
                class="col-12 md:col-6 lg:col-4 disabledCard"
                [ngStyle]="{'display': (item?.show) ? 'block' : 'none'}"
                [style]="passiveCardStyle">
                    <ng-template pTemplate="header">
                        <div class="">
                            <div class="position-card-org-header-div">
                               <span class="position-card-org-header"
                               [ngStyle]="{'padding-left': '1.2rem','padding-right': '1.2rem','background-color': item.orgBackColor,'color': item.orgTextColor}"
                               >{{item.organizationName}}</span>
                            </div>
                            <div class="position-card-header-div" >
                                <h5 class="position-card-header">{{item.title}}</h5>
                             </div>
                        </div>
                    </ng-template>
                    <div class="content-wrapper position-card-content mt-2" [innerHTML]="item.welcomeText">
                        <!-- {{item.welcomeText}} -->
                    </div>
                    <div class="grid" style="margin-top: 0px">
                        <div class="col-10" style="display: flex;">
                            <app-step-count [stepCount]="item.stepCount"></app-step-count>
                        </div>
                        <div class="col-2" style="padding-top: 21px;">
                            <i class="pi pi-arrow-right cardIcon fixed-position-icon" style="color: var(--blue-500);"></i>
                        </div>
                    </div>
                </p-card>
            </ng-template>
            <div>
                <app-position-preview *ngIf="visible" (dialogVisible)="changeDialogVisibility($event)"
                    [positionId]="selectedItemId" [positionDescription]="selectedItemDescription"
                    [positionTitle]="selectedItemTitle" [positionLanguageId]="positionLanguageId" [stepCount]="stepCount"
                    [welcomeText]="welcomeText" [videoURL]="videoURL" [stepList]="stepList" [showVinterLogo]="showVinterLogo">
                </app-position-preview>
            </div>
        </div>
    </div>


    <p-paginator *ngIf="positionTemplateDisplay.length > 0" #paginator (onPageChange)="onPageChange($event)" [first]="paginatorFirst" [rows]="rows"
        [totalRecords]="totalRecords" [showCurrentPageReport]="true"
        currentPageReportTemplate="{{'Key.Total' | translate}}: {totalRecords}">
    </p-paginator>

</div>

<p-toast position="bottom-center"></p-toast>

<p-confirmDialog [style]="{width: '25vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
