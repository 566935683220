import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { GeneralApplicationFormComponent } from './general-application-form/general-application-form.component';

 const routes: Routes = [{ path: '', component: GeneralApplicationFormComponent }];

@NgModule({
   imports: [RouterModule.forChild(routes)],
//   imports: [RouterModule.forChild([
//     { path: ':id', component: GeneralApplicationFormComponent }
// ])],
  exports: [RouterModule]
})
export class GeneralApplicationFormRoutingModule { }
