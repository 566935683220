<div>
    <div class="overflow-y-auto max-h-30rem">
        <ng-container>
            <div class="question-container">
                <div class="grid">
                    <div class="col-1 pt-4">
                        <p-chip class="alternative-chip" [style]="{'background-color': '#377BFF'}"
                            icon="pi pi-align-justify"></p-chip>
                    </div>
                    <div class="question-content col-10">
                        {{ "Key.EnglishExamStepDescription" | translate }}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
