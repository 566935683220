import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigurationService } from '@app/core';

@Component({
  selector: 'app-general-application-form',
  templateUrl: './general-application-form.component.html',
  styleUrls: ['./general-application-form.component.scss']
})
export class GeneralApplicationFormComponent implements OnInit {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private route: ActivatedRoute,
    private configurationService: ConfigurationService
  ) { }

  ngOnInit(): void {
    const positionLink = this.route.snapshot.paramMap.get('id');
    this.goToUrl(positionLink);
  }

  goToUrl(param: string): void {
    const applicantDomain = this.configurationService.config != undefined ? this.configurationService.config.applicantDomain : undefined;
    this.document.location.href = applicantDomain + 'general-application/' + param;
  }
}
