import { Injectable } from '@angular/core';
import { ELanguages } from '@app/shared/common/enums';

@Injectable({
    providedIn: 'root'  
  })
export class LicenseUnsubscribeReasonsService {
    LanguageEnum = ELanguages;

    getData(selectedLanguage: string) {
        if (selectedLanguage == this.LanguageEnum.English) {
            return [
                { reason: 'The price is too high', order: '1' },
                { reason: 'I found a more affordable alternative', order: '2' },
                { reason: 'I don\'t use the app enough', order: '3' },
                { reason: 'The features offered by the app don\'t meet my needs', order: '4' },
                { reason: 'I am experiencing technical issues', order: '5' },
                { reason: 'I am not happy with the customer service', order: '6' },
                { reason: 'Privacy or security concerns', order: '7' },
                { reason: 'I don\'t like the app\'s interface or user experience', order: '8' },
                { reason: 'The quality of the content or service has decreased', order: '9' },
                { reason: 'Personal or financial reasons', order: '10' },
                { reason: 'I have switched to another service', order: '11' },
                { reason: 'I am temporarily stopping it, I may restart it later', order: '12' },
            ];
        }
        if (selectedLanguage == this.LanguageEnum.Turkish) 
            {
            return [
                { reason: 'Fiyat çok yüksek', order: '1' },
                { reason: 'Daha uygun fiyatlı bir alternatif buldum', order: '2' },
                { reason: 'Uygulamayı yeterince kullanmıyorum', order: '3' },
                { reason: 'Uygulamanın sunduğu özellikler ihtiyacımı karşılamıyor', order: '4' },
                { reason: 'Teknik sorunlar yaşıyorum', order: '5' },
                { reason: 'Müşteri hizmetlerinden memnun değilim', order: '6' },
                { reason: 'Gizlilik veya güvenlik endişeleri', order: '7' },
                { reason: 'Uygulamanın arayüzü veya kullanıcı deneyimi hoşuma gitmiyor', order: '8' },
                { reason: 'İçerik veya hizmet kalitesi düştü', order: '9' },
                { reason: 'Kişisel veya finansal sebepler', order: '10' },
                { reason: 'Başka bir hizmete geçiş yaptım', order: '11' },
                { reason: 'Geçici olarak durduruyorum, ileride yeniden başlatabilirim', order: '12' },
              ];
            }      
                
    }

    getUnsubscribeReasonData(selectedLang: string) {
        return Promise.resolve(this.getData(selectedLang));
    }
}