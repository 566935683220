import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomChipComponent } from '@app/shared/components/custom-chip/custom-chip.component';



@NgModule({
  declarations: [
    CustomChipComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    CustomChipComponent
  ]
})
export class SharedCustomChipVinterModule { }
