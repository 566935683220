export class LicensePlanUpgradeItem{
    licensePlanName: string;
    licencePlanId: string;

    constructor(licensePlanName: string, licencePlanId: string) {
        this.licensePlanName = licensePlanName;
        this.licencePlanId = licencePlanId;

    }
}
