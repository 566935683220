import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { InviteApplicantViaEmailComponent } from './invite-applicant-via-email/invite-applicant-via-email.component';
import { InviteApplicantViaFileComponent } from './invite-applicant-via-file/invite-applicant-via-file.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-invite-applicant',
    templateUrl: './invite-applicant.component.html',
    styleUrls: ['./invite-applicant.component.scss'],
})
export class InviteApplicantComponent {
    visible: boolean;
    items: MenuItem[] | undefined;
    activeTabIndex: number = 0;
    activeItem: MenuItem | undefined;
    sendInvitationData: boolean = false;
    sendMailInvitationData: boolean = false;
    invitedDialogVisible: boolean = false;
    isCopied: boolean;

    @Input() positionId: string;
    @Input() organizationId: string;
    @Input() generalApplicationLink: string;
    @ViewChild(InviteApplicantViaEmailComponent) inviteApplicantEmailChild: InviteApplicantViaEmailComponent;
    @ViewChild(InviteApplicantViaFileComponent) inviteApplicantViaFileChild: InviteApplicantViaFileComponent;

    constructor(
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.setItems();
        this.activeItem = this.items[0];
    }

    receiveData(data) {
        this.sendInvitationData = data;
    }

    receiveMailData(data) {
        if (data.length > 0) {
            this.sendMailInvitationData = true;
        }
        else {
            this.sendMailInvitationData = false;
        }

    }

    setItems() {
        this.items = [{ label: 'via Email Adress' }, { label: 'via File' }];
    }

    closeDialog() {
        this.visible = false;
        this.inviteApplicantEmailChild.invitationList = [];
    }

    toogleInviteDialog() {
        this.visible = false;
        this.invitedDialogVisible = !this.invitedDialogVisible;
    }

    close() {
        if (this.inviteApplicantEmailChild ?? false) {
            this.inviteApplicantEmailChild.values = [];
            this.inviteApplicantEmailChild.invitationList = [];
        }
        if (this.inviteApplicantViaFileChild ?? false) {
            this.inviteApplicantViaFileChild.isFileSelected = false;
            this.inviteApplicantViaFileChild.excelResultFromDb = [];
            this.inviteApplicantViaFileChild.uploadedFiles = [];
            this.inviteApplicantViaFileChild.isDisabled = true;
        }
        this.activeTabIndex = 0;
        this.sendInvitationData = false;

    }

    sendInvite(index) {
        if (index === 0) {
            this.inviteApplicantEmailChild.sendInvitation();
        }
        else {
            this.inviteApplicantViaFileChild.submit();
        }
    }

    goAllPositions() {
        this.router.navigate(["position/list"]);
        this.invitedDialogVisible = false;
    }

    copyText() {
        this.isCopied = true;
        const textArea = document.createElement('textarea');
        textArea.value = this.generalApplicationLink;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);

        setTimeout(() => {
            this.isCopied = false;
        }, 2500);
    }
}
