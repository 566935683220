import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/core/configuration.service';
import { HttpBaseService } from '@app/core/http/http-base.service';
import { LocalizationService } from '@app/core/localization.service';
import { NotifyService } from '@app/core/notify.service';
import { ApiControllerEnum, EntityStateEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs/internal/Observable';
import { PositionListItem } from '../models/position-list-item';
import { PositionFormItem } from '../models/position-form-item';
import { PositionTemplatesListItem, PositionItemWithResponsible, PositionDetailItem } from '../models/position-templates-list-item';
import { BehaviorSubject } from 'rxjs';
import { PositionDetailDashboardFilterItem } from '../models/position-detail-dashboard-filter-item';
import { PositionWaitingForApproval } from '../models/position-waiting-approval-list-item';
import { PositionApprovalFormItem } from '../models/position-approval-form-item';
import { KanbanCard, KanbanList } from '../models/shared/kanban';
import { OrganizationParameterFormItem } from '../models/organization-parameter-form-item';
import { PositionCostInfo } from '../models/position-cost-info';

@Injectable({
  providedIn: 'root'
})
export class PositionService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService,) {
    super(http, ApiControllerEnum.Position, notifyServ, localizationService, configService);
  }

  getPositionApplicantBarChartByTime(positionId: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('positionID', positionId);
    return this.getWithParams("GetPositionApplicantBarChartByTime", params);
  }

  getPositionApplicantIdCheatSuspicion(positionId: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('positionID', positionId);
    return this.getWithParams("GetPositionApplicantIdCheatSuspicion", params);
  }

  updatePositionStatus(position: any): Observable<any> {
    position.entityState = EntityStateEnum.Modified;
    return this.post("updatePositionStatus", position, false);
  }

  updatePositionClosedStatus(position: any): Observable<any> {
    position.entityState = EntityStateEnum.Modified;
    return this.post("updatePositionClosedStatus", position, false);
  }

  getListByFilter(filter: any): Observable<PositionListItem[]> {
    return this.post("getListByFilter", filter, true);
  }

  getListPositionByFilter(filter: any): Observable<PositionTemplatesListItem[]> {
    return this.post("GetListPositionByLanguageFilter", filter, true);
  }

  getPositionDetailApplicantStepScoreData(filter: any): Observable<any[]> {
    return this.post("GetPositionDetailApplicantStepScoreData", filter, true);
  }

  getPositionDetailApplicantQuestionScoreData(filter: any): Observable<any[]> {
    return this.post("GetPositionDetailApplicantQuestionScoreData", filter, true);
  }

  getApplicationStepPieChartForPosition(positionID: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('positionID', positionID);
    return this.getWithParams("getApplicationStepPieChartForPosition", params);
  }


  getPositionKanbanStepData(positionID: any,languageID: any): Observable<KanbanList[]> {
    let params = new HttpParams();
    params = params.append('positionID', positionID);
    params = params.append('languageID', languageID);
    return this.getWithParams("getPositionKanbanStepData", params);
  }


  getPositionKanbanStepApplicantData(positionID: any,positionStepId: any): Observable<KanbanCard[]> {
    let params = new HttpParams();
    params = params.append('positionID', positionID);
    params = params.append('positionStepId', positionStepId);
    return this.getWithParamsWithoutSpinner("getPositionKanbanStepApplicantData", params);
  }


  getOrganizationListForCardList(filter: any): Observable<PositionListItem[]> {
    return this.get("GetOrganizationInfoForCardList");
  }

  getOrganizationNamesWithId(): Observable<PositionListItem[]> {
    return this.get("GetOrganizationNamesWithId");
  }

  getDataByFilter(filter: any): Observable<PositionListItem> {
    return this.post("getDataByFilter", filter, true);
  }

  GetListWithOrganizationAndResponsibleForSuggest(filter: any): Observable<PositionListItem[]> {
    return this.post("getListWithOrganizationAndResponsibleForSuggest", filter, true);
  }

  GetListWithOrganizationAndResponsibleForPositionList(filter: any): Observable<PositionListItem[]> {
    return this.post("getListWithOrganizationAndResponsibleForPositionList", filter, true);
  }

  GetListWaitingForApproval(filter: any): Observable<PositionWaitingForApproval[]> {
    return this.post("GetListWaitingForApproval", filter, true);
  }

  GetListPositionWithOrganizationAndResponsible(filter: any): Observable<PositionTemplatesListItem[]> {
    return this.post("GetListPositionWithOrganizationAndResponsible", filter, true);
  }

  GetDataPositionWithResponsible(filter: any): Observable<PositionItemWithResponsible> {
    return this.post("GetDataPositionWithOrganizationAndResponsible", filter, true);
  }

  getPositionForEdit(positionID: any): Observable<any> {
    let params = new HttpParams();
    params = params.append('positionID', positionID);
    return this.getWithParams("GetPositionForEdit", params);
  }


  GetListPositionDetailsWithResponsibleAndQuestions(positionID: any): Observable<PositionDetailItem[]> {
    let params = new HttpParams();
    params = params.append('positionID', positionID);
    return this.getWithParams("GetListPositionDetailsWithResponsibleAndQuestions", params);
  }

  add(obj: PositionFormItem): Observable<any> {
    obj.entityState = EntityStateEnum.Added;
    return this.post("SaveAsync", obj, true);
  }
  saveAsync(obj: PositionFormItem): Observable<any> {
    return this.post("SavePositionAndTemplateAsync", obj, true);
  }
  // SavePositionAndTemplateAsync(obj: PositionFormItem): Observable<any> {
  //   return this.post("SavePositionAndTemplateAsync", obj, true);
  // }

  update(obj: PositionFormItem): Observable<any> {
    obj.entityState = EntityStateEnum.Modified;
    return this.post("SaveAsync", obj, true);
  }

  changeApplicationStepByKanban(obj: any): Observable<any> {
    return this.post("ChangeApplicationStepByKanban", obj, false, false);
  }

  changeApplicationStepByKanbanMultiple(obj: any): Observable<any> {
    return this.post("ChangeApplicationStepByKanbanMultiple", obj, false, false);
  }
  UpdateApprovalStatusAndSendMail(obj: PositionApprovalFormItem): Observable<PositionApprovalFormItem> {
    return this.post("UpdateApprovalStatusAndSendMail", obj, true);
  }

  UpdatePositionsApprovalStatus(filter: OrganizationParameterFormItem): Observable<any[]> {
    return this.post("UpdatePositionsApprovalStatus", filter, true);
  }
  getPositionCost(organizationId: string): Observable<PositionCostInfo[]> {
    let params = new HttpParams();
    params = params.append('organizationId', organizationId);
    return this.getWithParams("GetPositionCost", params);
  }
  searchLocation(query: string): Observable<any[]> {
    let params = new HttpParams();
    params = params.append('query', query);
    return this.getWithParams("SearchLocation",params);
  }
}
