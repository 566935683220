import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/core/configuration.service';
import { HttpBaseService } from '@app/core/http/http-base.service';
import { LocalizationService } from '@app/core/localization.service';
import { NotifyService } from '@app/core/notify.service';
import { ApiControllerEnum, EntityStateEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs/internal/Observable';
import { UserGroupListItem } from '../models/user-group-list-item';
import { CustomOrganizationFormItem, CustomOrganizationListItem, OrganizationListItem } from '../models/organization-list-item';
import { OrganizationFormItem } from '../models/organization-form-item';
import { OrgSpendReportListItem } from '../models/organization-spend-report-item';
import { CreateOrganizationSendInvitationPost } from '../models/shared/create-organization-send-invitation-post';
import { OrganizationCostListItem } from '../models/organization-management-list-item';
import { OrganizationPlan } from '../models/license-plan-list-item';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.Organization, notifyServ, localizationService, configService);
  }

  getListByFilter(filter: any): Observable<OrganizationListItem[]> {
    return this.post("getListByFilter", filter, true);
  }

  getOrganizationListForCardList(filter: any): Observable<OrganizationListItem[]> {
    return this.get("GetOrganizationInfoForCardList");
  }

  getOrganizationNamesWithId(): Observable<OrganizationListItem[]> {
    return this.get("GetListForPositionCreate");
  }

  getDataByFilter(filter: any): Observable<OrganizationListItem> {
    return this.post("getDataByFilter", filter, true);
  }

  add(obj: OrganizationFormItem): Observable<any> {
    obj.entityState = EntityStateEnum.Added;
    return this.post("SaveAsync", obj, true);
  }

  update(obj: OrganizationFormItem): Observable<any> {
    obj.entityState = EntityStateEnum.Modified;
    return this.post("SaveAsync", obj, false);
  }

  SaveOrganizationSendInvitation(obj: CreateOrganizationSendInvitationPost): Observable<any> {
    return this.post("SaveOrganizationSendInvitation", obj, true);
  }
  GetCostInformationForOrganization(): Observable<any[]>{
    return this.get("GetCostInformationForOrganization");
  }
  GetCostOrganizationWithPositionAndApplicantDetail(organizationId: string): Observable<OrganizationCostListItem>{
    return this.get("GetCostOrganizationWithPositionAndApplicantDetail?organizationID=" + organizationId);
  }

  getOrganizationByUser(): Observable<CustomOrganizationListItem> {
    return this.get("GetOrganizationByUser");
  }

  updateOrganization(obj: CustomOrganizationFormItem): Observable<any> {
    obj.entityState = EntityStateEnum.Modified;
    return this.post("UpdateOrganization", obj, false);
  }
  getPlanForOrganization(): Observable<OrganizationPlan>{
    return this.get("GetLicensePlanList");
  }
  hasAnyDepartment(): Observable<boolean>{
    return this.get("HasAnyDepartment");
  }
  saveDocuments(data: any): Observable<any>  {
    return this.post("SaveDocuments", data);
  }
}
