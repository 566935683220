import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadFileService extends HttpBaseService {
  constructor(private http: HttpClient
      , private notifyServ: NotifyService
      , private localizationService: LocalizationService
      , private configService: ConfigurationService) {
      super(http, ApiControllerEnum.UploadFile, notifyServ, localizationService, configService);
  }

  uploadAudioToS3(data: any): Observable<any[]> {
      var resp = this.post("UploadAudioToS3", data, true);
      return resp;
  }

  uploadVideoToS3(data: any): Observable<any[]> {
    var resp = this.postWithoutSpinner("UploadVideoToS3", data, true);
    return resp;
  }


  uploadVideoSimpleToS3(data: any): Observable<any[]> {
      var resp = this.post("UploadVideoToS3", data, true);
      return resp;
  }

  uploadPictureToS3(data: any): Observable<any[]> {
    var resp = this.post("UploadPictureToS3", data, true);
    return resp;
  }
}
