<div class="flex align-items-center justify-content-center position-create-user-avatar">
                    <div class="col-2 p-0 w-min mr-2">
                        <p-avatar *ngIf="(OrganizationImageURL === null || OrganizationImageURL === '' || OrganizationImageURL === undefined) && OrganizationName ?? false" class="responsibleAvatar-blue ml-2"
                        [label]="OrganizationName[0]?.toUpperCase()"
                        shape="circle" styleClass="border-2 surface-border surface-ground ">
                        </p-avatar>
                        <p-avatar *ngIf="OrganizationImageURL !== null && OrganizationImageURL !== '' && OrganizationImageURL !== undefined" class="responsibleAvatar-blue ml-2"
                                  [image]="OrganizationImageURL" shape="circle"
                                  styleClass="border-2 surface-border surface-ground ">
                        </p-avatar>
                    </div>
                    <div class="col-10 p-0 grid m-0">
                        <div class="col-12 p-0 responsibleName">
                            {{OrganizationName}}
                        </div>
                        <div class="col-12 p-0 responsibleMail">
                            {{ContactUserEmail}}
                        </div>
                    </div>
</div>
            
            