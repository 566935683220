import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum, EntityStateEnum } from '@app/shared/common/enums';
import { QuestionListItem } from '../models/question-list-item';
import { Observable } from 'rxjs';
import { QuestionFormItem } from '../models/question-form-item';

@Injectable({
  providedIn: 'root'
})
export class QuestionService extends HttpBaseService {
  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.Question, notifyServ, localizationService, configService);
}

    getDataByFilterWithChoiceContent(filter: any): Observable<QuestionListItem> {
        return this.postWithoutSpinner("GetDataByFilterWithChoiceContent", filter, true);
    }
    getQuestionPositionDashboardDataByPositionStepID(filter: any): Observable<any> {
        return this.postWithoutSpinner("GetQuestionPositionDashboardDataByPositionStepID", filter, true);
    }

    ChangeDeprecatedStatus(filter: any): Observable<any>{
        return this.postWithoutSpinner("ChangeDeprecatedStatus", filter, true);
    }
}
