import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LocalizationService } from '@app/core';
import { ExistingEmail, ExistingManager, LicenseUsageError, UserFilledFormAlready } from '@app/shared/common/constants';
import { EntityStateEnum, UserGroupEnum } from '@app/shared/common/enums';
import { GeneralApplicationInvitationPostItem } from '@app/vinter/models/general-application-invitation-post-item';
import { InvitationPostItem } from '@app/vinter/models/Invitation/invitation-post-item';
import { GeneralApplicationInvitationService } from '@app/vinter/services/general-application-invitation.service';
import { InvitationService } from '@app/vinter/services/invitation.service';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-invite-applicant-via-email',
    templateUrl: './invite-applicant-via-email.component.html',
    styleUrls: ['./invite-applicant-via-email.component.scss'],
    providers: [MessageService]

})
export class InviteApplicantViaEmailComponent {
    values: any[] | undefined = [];
    invitationList: GeneralApplicationInvitationPostItem[] = [];
    userGroupID: string = UserGroupEnum.APPLICANT;
    regexp = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$');

    @Input() positionId: string;
    @Input() organizationId: string;
    @Output() callCloseDialog: EventEmitter<any> = new EventEmitter<any>();
    @Output() sendInvite: EventEmitter<any> = new EventEmitter<any>();
    @Output() sendMailDataValidation: EventEmitter<any> = new EventEmitter<any>();

    mailItem: string;
    isWarningVisible = false;

    constructor(
        private service: GeneralApplicationInvitationService,
        private messageService: MessageService,
        private localizationService: LocalizationService,
    ) { }

    ngOnInit(): void {
    }

    addItem() {
        if (this.mailItem) {
            let newItem = null;
            let flag = this.addEvent(this.mailItem);
            if (!flag) {
                newItem = { mail: this.mailItem, dataValidation: false, errorMessage: this.localizationService.translatedTextList.NotValidEmail, isMailHasError: false };
            }
            else {
                this.values.forEach(item => {
                    if (this.mailItem === item.mail) {
                        newItem = { mail: this.mailItem, dataValidation: false, errorMessage: this.localizationService.translatedTextList["DuplicatedEmailOnList"], isMailHasError: false };
                    }
                });

                if (newItem === null) {
                    newItem = { mail: this.mailItem, dataValidation: true, errorMessage: "", isMailHasError: false };
                }
            }
            this.values.push(newItem);
            this.mailItem = '';
            this.sendEmailToParent();
        }
    }

    sendEmailToParent() {
        this.sendMailDataValidation.emit(this.values);
    }

    deleteCard(index) {
        this.values.splice(index, 1);
        this.sendEmailToParent();
    }

    sendInvitation() {
        this.invitationList = [];
        let isAllMailValid = true;
        this.values.forEach(element => {
            const isValid = this.regexp.test(element.mail)
            if (!isValid) {
                isAllMailValid = false;
                return;
            }
        })
        if (!isAllMailValid) {
            this.messageService.add({
                severity: 'error',
                summary: this.localizationService.translatedTextList.Error,
                detail: this.localizationService.translatedTextList.NotValidEmail,
                life: 3000
            });
            return
        }
        this.values.forEach(element => {
            let generalApplicationInvitationPostItem: GeneralApplicationInvitationPostItem = {
                entityState: EntityStateEnum.Added,
                email: element.mail,
                positionID: this.positionId,
            }
            this.invitationList.push(generalApplicationInvitationPostItem);
        });
        // this.service.createGeneralApplicationInvitation(this.invitationList).subscribe((response: any) => {
        //     if (response) {
        //         this.isWarningVisible = true;
        //         const existingEmails = response.split(", ");
        //         this.existingHrEmails(existingEmails);
        //     }
        //     else {
        //         this.isWarningVisible = false;
        //         this.messageService.add({
        //             severity: 'success',
        //             summary: this.localizationService.translatedTextList.Success,
        //             detail: this.localizationService.translatedTextList.OperationCompleted,
        //             life: 3000
        //         });
        //         this.sendInvite.emit(true);
        //     }
        // });
        this.service.createGeneralApplicationInvitation(this.invitationList).subscribe({
            next: (response) => {
                this.isWarningVisible = false;
                this.messageService.add({
                    severity: 'success',
                    summary: this.localizationService.translatedTextList.Success,
                    detail: this.localizationService.translatedTextList.OperationCompleted,
                    life: 3000
                });
                this.sendInvite.emit(true);
            },
            error: (err) => {
                this.isWarningVisible = true;
                const existingEmails = err.substring(4).split(", ");
                this.existingHrEmails(existingEmails);
            }
        });
    }

    existingHrEmails(existingEmails: string[]): void {
        const validationErrors: { [key: string]: string } = {};

        existingEmails.forEach((elem) => {
            const [mail, message] = elem.split('/');
            validationErrors[mail] = message;
        });

        this.values.forEach(element => {
            if (validationErrors[element.mail]) {
                if (validationErrors[element.mail] == ExistingEmail) {
                    element.errorMessage = this.localizationService.translatedTextList.EmailOrganizationMustBeUniqueMessage
                }
                if (validationErrors[element.mail] == LicenseUsageError) {
                    element.errorMessage = this.localizationService.translatedTextList.LicenseUsageErrorMessage
                }
                if (validationErrors[element.mail] == ExistingManager) {
                    element.errorMessage = this.localizationService.translatedTextList.ExistingManagerMessage
                }
                if (validationErrors[element.mail] == UserFilledFormAlready) {
                    element.errorMessage = this.localizationService.translatedTextList.UserFilledFormAlready
                }
                element.dataValidation = false;
                element.isMailHasError = true;
            }
        });
    }


    remove(event: any) {
        var index = this.invitationList.findIndex(x => x.email == event.value);
        this.invitationList.splice(index, 1);
    }

    addEvent(event: any) {
        var isEmail = this.regexp.test(event);
        if (!isEmail) {
            return false;
        }
        else {
            return true;
        }
    }
}
