import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ocean-progressbar',
  templateUrl: './ocean-progressbar.component.html',
  styleUrls: ['./ocean-progressbar.component.scss']
})
export class OceanProgressbarComponent {

  @Input() progressBarOceanScore;
  @Input() oceanScore;
  @Input() oceanParent;
  @Input() oceanCategory;


}
