import { Component } from '@angular/core';

@Component({
  selector: 'app-position-step-english-exam',
  templateUrl: './position-step-english-exam.component.html',
  styleUrls: ['./position-step-english-exam.component.scss']
})
export class PositionStepEnglishExamComponent {

}
