import { EntityStateEnum } from "@app/shared/common/enums";

export class QuestionContentListItem {
  id: string;
  questionText: string;
  contentURLType: number;
  contentURL: string;
  contentURLSigned: string;
  questionID: string;
  entityState: EntityStateEnum;
  xmin:number;
  contentDetail: string;
}
