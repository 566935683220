import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum, EntityStateEnum } from '@app/shared/common/enums';
import { PositionCategoryListItem, PositionCategoryPostItem } from '../models/position-category-list-item';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PositionCategoryService extends HttpBaseService {

    constructor(private http: HttpClient
        , private notifyServ: NotifyService
        , private localizationService: LocalizationService
        , private configService: ConfigurationService) {
        super(http, ApiControllerEnum.PositionCategory, notifyServ, localizationService, configService);
    }

    getListByFilter(filter: any): Observable<PositionCategoryListItem[]> {
        return this.post("GetListByFilter", filter, true);
    }

    add(obj: PositionCategoryPostItem): Observable<any> {
        obj.entityState = EntityStateEnum.Added;
        return this.post("SaveAsync", obj, true);
      }

}
