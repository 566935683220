<div class="grid">
    <div class="col-12">
        <a href='{{invitationTemplateFilePath}}' download="VinterMailListTemplate.xls">
            <p-button styleClass="buttonFont" pTooltip="{{'Key.downloadTemplate' | translate}}" tooltipPosition="top"><i
                    class="pi pi-download --blue-700"></i>{{"Key.excelTemplate" | translate}}
            </p-button>
        </a>
    </div>
    <div class="col-12">
        <div class="video-container" [ngClass]="isFileSelected ? 'file-selected-border' : 'file-border'"
            (dragover)="onDragover($event)" (drop)="onDrop($event)" #dropzone>

            <div *ngIf="isFileSelected" class="ml-3 file-name-text w-6 justify-content-start flex white-space-nowrap">
                {{uploadedFiles[0].name}}
            </div>
            <div [ngClass]="isFileSelected ? 'button-container-right' : 'button-container'">
                <div *ngIf="!isFileSelected" class="button">
                    <p-button label="{{'Key.ChooseFile' | translate}}" (onClick)="fileInput.click()"
                        class="file-button" styleClass="p-button-text"></p-button>
                    <div>
                        <img src="assets/vinter/icons/custom-upload-icon.svg">
                    </div>
                    <p class="drop-text">{{'Key.DropFiles' | translate}}</p>
                </div>
                <div class="button" [ngClass]="{'ml-3': isFileSelected}">
                    <input #fileInput type="file" (change)="onSelect($event)"
                        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        class="file-input" style="display: none">
                    <p-button (onClick)="handleImport()" label="{{'Key.Upload' | translate}}"
                        [hidden]="!isFileSelected || isFileChosen" styleClass="p-button-outlined"></p-button>
                    <p-button icon="pi pi-times" [hidden]="!isFileSelected" (onClick)="onRemove()"
                        styleClass="p-button-rounded p-button-text"></p-button>
                </div>
            </div>
        </div>
        <div [ngStyle]="{'display': (isWarningVisible) ? '' : 'none'}">
            <div class="m-2 flex justify-content-start send-mail-warning">
                <span class="pi pi-exclamation-triangle warning-icon flex align-item-center"></span>
                <label class="flex font-medium message-color ml-2">{{ "Key.SendMailFileWarningMessage" | translate }}</label>
            </div>
        </div>
    </div>

    <div *ngIf="isFileChosen" class="col-12 flex flex-wrap">
        <div *ngFor="let item of excelResultFromDb; let i = index" class="excel-invite-item">
            <div *ngIf="item.dataValidation" class="custom-chip-correct">
                <p-avatar label="{{item.email[0].toUpperCase()}}" styleClass="mr-2" [style]="{ 'background-color': '#E5EDFF', color: '#002995' }" shape="circle"></p-avatar>
                <p class="mail-text"> {{item.email}} </p>
                <p-button icon="pi pi-times" class="close mail" styleClass="p-button-rounded p-button-text" (click)="deleteCard(i)"></p-button>
            </div>
            <div *ngIf="!item.dataValidation" class="custom-chip-wrong" [pTooltip]="item.errorMessage" tooltipPosition="top">
                <p-avatar class="wrong" label="{{item.email[0].toUpperCase()}}" styleClass="mr-2" [style]="{ 'background-color': '#FFE3E3', color: '#9A0101' }" shape="circle"></p-avatar>
                <p class="mail-text"> {{item.email}} </p>
                <p-button icon="pi pi-times" styleClass="p-button-rounded p-button-text" class="wrongData close mail" (click)="deleteCard(i)"></p-button>
            </div>
        </div>
    </div>
    

</div>