import { QuestionCollectionFormItem } from "../question-collection-form";
import { QuestionListItem } from "../question-list-item";

export class QuestionCollectionQuestionCustomItem {
    questionCollection: QuestionCollectionFormItem;
    questions: QuestionListItem[];
}


export class QuestionCollectionStepQuestionCustomItem {
    stepInfo: QuestionCollectionFormItem;
    questions: QuestionListItem[];
}
