import { Component, EventEmitter, Input, OnInit,Output} from '@angular/core';
import { MembershipService } from '@app/vinter/services/membership.service';
import { CredentialsService } from '@app/core';
import { LicenseUnsubscribeReasonsService } from '@app/vinter/services/unsubscribe-reason.service';
import { LanguageEnum } from '@app/shared/common/enums';

@Component({
  selector: 'app-license-plan-unsubscribe-dialog',
  templateUrl: './license-plan-unsubscribe-dialog.component.html',
  styleUrls: ['./license-plan-unsubscribe-dialog.component.scss']
})
export class LicensePlanUnsubscribeDialogComponent implements OnInit {
  unsubscriptionReasonList: any[] = [];
  selectedReason: any;
  isSuccessfull: boolean;
  isFailed: boolean = true;
  isClicked: boolean;
  expirationDate: Date;
  isBeforeEndOfTheLicense: boolean
  currentLanguageId:string;
  LanguageEnum = LanguageEnum;

  @Input() isShow: boolean;
  @Output() isShowChange = new EventEmitter<boolean>()
  @Input() licenseOrgInfoId: any;

  
  constructor(
    public credentialService: CredentialsService,
    private licenseUnsubscribe: LicenseUnsubscribeReasonsService,
    private paymentService: MembershipService,

  ) { }

  @Output() dialogVisibiltyEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() isUnsubscribed = new EventEmitter<any>();
  @Output() isUnsubscriptionSuccessful = new EventEmitter<any>();
  @Output() licenseExpirationDate = new EventEmitter<any>();
  @Output() refreshPage: EventEmitter<void> = new EventEmitter<void>();


  ngOnInit(): void {
    this.getReasonDatas();
    this.currentLanguageId = this.credentialService.getLanguage();
  }
  onReasonChange(event: any){
    this.selectedReason= event.value;
}

getReasonDatas(){
  this.licenseUnsubscribe.getUnsubscribeReasonData(this.credentialService.getLanguage()).then((reasons) => {
    this.unsubscriptionReasonList = reasons;
});
}
  unsubsucribeLicense(licenseOrganizationInfoID) {
    this.paymentService.cancelSubsriptionAndUpdateLicenseOrganizationInfo({ licenseOrganizationInfoID }).subscribe(
        (x) => {
            this.isFailed = x.isFailed;
            this.expirationDate = x.expirationDate;
            if (this.expirationDate != null && !this.isFailed) {
                this.isUnsubscriptionSuccessful.emit(true);
            }
            this.licenseExpirationDate.emit(this.expirationDate);
            this.isClicked = true; 
            this.closeUnsubscriptionDialog(); 
        },
        (error) => {
            this.isFailed = true; 
            this.isClicked = true; 
            this.closeUnsubscriptionDialog(); 
        }
    );
}


  closeUnsubscriptionDialog() {
    this.isShowChange.emit(false);  
  }

  closeSuccessfullPopup(){
    this.isClicked = false;
    this.refreshPage.emit();
  }

  unsubscribe(){
    this.unsubsucribeLicense(this.licenseOrgInfoId);
  }
  }
