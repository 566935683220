import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-custom-pdf-viewer',
  templateUrl: './custom-pdf-viewer.component.html',
  styleUrls: ['./custom-pdf-viewer.component.scss']
})
export class CustomPdfViewerComponent{
  currentZoom: number = 1;
  @Output() onClose: EventEmitter<void> = new EventEmitter();
  @Input() pdfFileUrl: any;
  @Input() renderText: boolean;
  @Input() pdfFileName : string;
  @Input() isDownloadVisible:boolean;
  @ViewChild('zoomableDiv') zoomableDiv!: ElementRef;

  closeClicked(){
    this.currentZoom = 1;
    (document.body.style as any).zoom = this.currentZoom.toString();
    this.onClose.emit();
  }

  downloadPdf()
  {
      const _anchor = document.createElement('a');
      _anchor.href = this.pdfFileUrl
      _anchor.download =  this.pdfFileName
      _anchor.click();

  }

  zoomIn() {
    this.currentZoom += 0.1;
    this.applyZoom();
  }

  zoomOut() {
    this.currentZoom = Math.max(this.currentZoom - 0.1, 0.1);
    this.applyZoom();
  }

  applyZoom() {
    (document.body.style as any).zoom = this.currentZoom.toString();
  }
}
