import { Component, Input } from '@angular/core';
import { ApplicationStepStatus } from '@app/shared/common/enums';

@Component({
  selector: 'app-custom-timeline',
  templateUrl: './custom-timeline.component.html',
  styleUrls: ['./custom-timeline.component.scss']
})
export class CustomTimelineComponent {

  @Input() isClickable: boolean;
  @Input() applicationSteps: any[];
  applicationStepStatus = ApplicationStepStatus;

  ngOnInit(): void {
  }

}
