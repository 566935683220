<p-sidebar [(visible)]="visible" position="right" (onHide)="close()">
    <div class="ml-3">
        <p-button *ngIf="positionId && (!useButtonHide || useButtonHide === null || useButtonHide === undefined)" 
                    icon="pi pi-plus" label="{{ 'Key.Use' | translate }}" styleClass="buttonFont mr-2 mb-2"
            (onClick)="onButtonClick(positionId)"></p-button>
    </div>
    <div class="content-wrapper mt-2 ml-3">
        <h5 data-cy="label-position-title" class="h5-font mt-0">
            {{ positionTitle }}
        </h5>
    </div>
    <div class="content-wrapper position-card-content mt-2 ml-3 mb-5" [innerHTML]="positionDescription">
        <!-- <p>{{  positionDescription }}</p> -->
    </div>

    <div class="flex ml-3">
        <div class="cardSidebar flex grid">
            <span class="flex col-12 p-0 pb-1 justify-content-center align-items-center sideBarFont">
                <img class="custom-svg-icon pr-2" src="/assets/vinter/icons/timerGray.svg" height="25px" width="25px" />
                <span style="padding-top: 0.2rem">{{"Key.TestDuration" | translate}}</span>
            </span>
            <div class="flex col-12 p-0 justify-content-center">
                <div class="lineSidebar col-12 p-0"></div>
            </div>
            <span class="flex col-12 p-0 pt-1 justify-content-center sideBarTimer">
                <span *ngIf="questionCollectionDurationInput != 0 && (questionCollectionDurationInput ?? false)" 
                        class="pr-1">{{ questionCollectionDurationInput }}
                </span>
                <span *ngIf="questionCollectionDuration != 0 && (questionCollectionDuration ?? false)" 
                        class="pr-1">{{ questionCollectionDuration }}
                </span>
                <span>{{ "Key.Minute" | translate }}</span>
            </span>
        </div>
        <div class="cardSidebar flex grid">
            <span class="flex col-12 p-0 pb-1 justify-content-center align-items-center sideBarFont">
                <img class="custom-svg-icon pr-2" src="/assets/vinter/icons/positionDetaillanguage.svg" height="25px"
                    width="25px" />
                <span style="padding-top: 0.2rem">{{"Key.Language" | translate }}</span>
            </span>
            <div class="flex col-12 p-0 justify-content-center">
                <div class="lineSidebar col-12 p-0"></div>
            </div>
            <span class="flex col-12 pt-1 p-0 justify-content-center sideBarTimer">
                <ng-container *ngIf="positionLanguageId === language.English">
                    {{ "Key.English" | translate }}
                </ng-container>
                <ng-container *ngIf="positionLanguageId === language.Turkish">
                    {{ "Key.Turkish" | translate }}
                </ng-container>
                <ng-template *ngIf="positionLanguageId != language.Turkish && positionLanguageId != language.English">
                    Unknown Language
                </ng-template>
            </span>
        </div>
        <div class="cardSidebar flex grid">
            <span class="flex col-12 p-0 pb-1 justify-content-center align-items-center sideBarFont">
                <img class="custom-svg-icon pr-2" src="/assets/vinter/icons/stepsIcon.svg" height="25px" width="25px" />
                <span style="padding-top: 0.2rem">{{"Key.Steps" | translate}}</span>
            </span>
            <div class="flex col-12 p-0 justify-content-center">
                <div class="lineSidebar col-12 p-0"></div>
            </div>
            <span class="flex col-12 p-0 pt-1 justify-content-center sideBarTimer">
                <span class="pr-1">{{ stepCount }} </span>
            </span>
        </div>
    </div>
    <div class="content-wrapper pt-2 mt-2 ml-3">
        <h5 class="weolcome-title">{{ "Key.WelcomeMessage" | translate }}</h5>
    </div>
    <div *ngIf="videoURL ?? false" class="grid mb-4 mt-2 ml-3">
        <div class="col-4 pl-0">
            <video src="{{ videoURL }}" style="border-radius: 6px; width: 100%" controls controlslist="nodownload"
                disablepictureinpicture></video>
        </div>
        <div class="col-8 content-wrapper" [innerHTML]="welcomeText">
            <!-- <p>{{ welcomeText }}</p> -->
        </div>
    </div>
    <div *ngIf="!(videoURL ?? false)" class="mb-4 mt-2 ml-3">
        <div class="content-wrapper" [innerHTML]="welcomeText">
            <!-- <p>{{ positionDescription }}</p> -->
        </div>
    </div>

    <div class="pl-3" *ngIf="positionStepList?.length > 0">
        <p-tabView (activeIndexChange)="changeActiveIndex($event)" [activeIndex]="activeIndexStepTab">
            <p-tabPanel *ngIf="generalAppQuestions?.length > 0" header="{{ 'Key.GeneralApplication' | translate }}">
                <app-position-step-question-list
                    [generalAppQuestions]="generalAppQuestionsDisplay"></app-position-step-question-list>
            </p-tabPanel>
            <ng-container *ngFor="let step of positionStepList; let i = index">
                <p-tabPanel header="{{ step.title }}">
                    <ng-template pTemplate="content">
                        <div *ngIf="
                                (step.questionCollectionID !== null &&
                                    step.questionCollectionID !== undefined) ||
                                step.questionCollectionCategory !== undefined
                            ">
                            <app-position-step-question-list
                                [positionStepQuestionCollectionId]="step.questionCollectionID"
                                [positionStepDescription]="step.description" [questionList]="step.questions"
                                [positionStepSubCategory]="step.subCategory"
                                [questionCollectionSubCategory]="step.subCategory"
                                [positionStepCategory]="step.questionCollectionCategory">
                            </app-position-step-question-list>
                        </div>
                        <div *ngIf="
                                (step.questionCollectionID === null ||
                                    step.questionCollectionID === undefined) &&
                                step.questionCollectionCategory === undefined
                            " class="overflow-y-auto max-h-30rem">
                            <ng-container>
                                <div class="question-container">
                                    <div class="question-content">
                                        {{ step.description }}
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </ng-template>
                </p-tabPanel>
            </ng-container>
        </p-tabView>
    </div>
    <div *ngIf="positionStepList.length === 0" class="no-questions-message">
        {{ "Key.NoPositionStep" | translate }}
    </div>
</p-sidebar>