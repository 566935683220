import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { LocalizationService } from '@app/core';
import { maxImageSize } from '@app/shared/common/constants';
import { SpinnerHandlerService } from '@app/shared/components/loader/spinner-handler.service';
import { UploadFileService } from '@app/vinter/services/upload-file.service';
import { VgApiService } from '@videogular/ngx-videogular/core';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';

@Component({
  selector: 'app-picture-uploader-simple',
  templateUrl: './picture-uploader-simple.component.html',
  styleUrls: ['./picture-uploader-simple.component.scss']
})
export class PictureUploaderSimpleComponent {

  @Output() onPictureConfirm: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFileSelect: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('fileInput') fileInput: FileUpload;

  pictureUrl: string;
  media: any;
  isSelected: boolean = false;
  uploadedFiles: any[] = [];
  visible: boolean = true;
  fileName: string;
  maxSizeImage: number = maxImageSize;
  @Input() disabled: boolean;
  @Input() isUnsigned:boolean=false;
  @Output() closeDialog: EventEmitter<any> = new EventEmitter<any>();
  @Output() uploadedPictureUrl: EventEmitter<any[]> = new EventEmitter<any[]>();
  @ViewChild(FileUpload, { static: false }) fileUpload: FileUpload;

  constructor(private api: VgApiService,
      public service: UploadFileService,
      private spinner: SpinnerHandlerService,
      private messageService: MessageService,
      private localizationService: LocalizationService) { this.api = api; }

  ngOnInit() {
  }

  onFileSelected(event: any): void {
      if (event.files[0].type != "image/jpeg" && event.files[0].type != "image/png"){
          this.messageService.add({
              severity: 'error',
              summary: this.localizationService.translatedTextList.Error,
              detail: this.localizationService.translatedTextList.InvalidImageType,
              life: 3000
          });
      }
      else{
          if(event.files[0].size > this.maxSizeImage){
              this.messageService.add({
                  severity: 'error',
                  summary: this.localizationService.translatedTextList.Error,
                  detail: this.localizationService.translatedTextList.InvalidImageSize,
                  life: 3000
              });
          }
          else{
              this.fileName = event.files[0].name;
              this.onFileSelect.emit(true);
              this.pictureUrl = URL.createObjectURL(event.files[0]);
              this.isSelected = true;
          }
      }
  }

  selectFile(){
    this.fileInput.choose();
  }

onConfirm(): Promise<any> {
    if(this.pictureUrl ?? false){
        return new Promise<any>(async(resolve, reject) => {
            let blob = await fetch(this.pictureUrl).then((r) => r.blob());
            var file = new File([blob], "picture1.png");
            let formData = new FormData();
            formData.append("picture", file);
            formData.append(`isUnsigned`, JSON.stringify(this.isUnsigned));
            this.service.uploadPictureToS3(formData).subscribe(response => {
                this.onPictureConfirm.emit({ link: response, id: null });
                this.uploadedPictureUrl.emit(response);
                this.closeDialog.emit(null);
                resolve(response);
            },
            err => {

                reject(err);
            });
            this.onClear();
        });
    }else{

        return null;
    }
  }

  onClear(){
      this.isSelected = false;
      this.pictureUrl = null;
      this.uploadedFiles = [];
      this.fileUpload.clear();
  }
}
