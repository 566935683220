<div class="content-wrapper mt-6 ml-4">
    <h5 class="h5-font">{{ "Key.GeneralApplicationQuestions" | translate }}</h5>
</div>
<div class=" ml-4 my-4 custom-grid">
    {{ "Key.Questions" | translate }}{{": "}}{{generalAppQuestionDisplay.length}}
</div>
<div class="ml-4" *ngIf="generalAppQuestionDisplay.length > 0">
    <p-button *ngIf="positionEditFormMode !== positionEditMode" styleClass="p-button-clearAll" label="{{'Key.ClearAll' | translate}}" (onClick)="this.deleteAllQuestion.emit(true)"></p-button>
    <div class="overflow-y-auto">
        <ng-container>
            <div *ngFor="let question of generalAppQuestionDisplay; let i = index" class="question-container mt-3">
                <div class="grid p-2">
                    <div class="col-1 question-number ml-2 pr-0" style="width: 3%">
                        {{ i + 1 }}.
                    </div>
                    <div class="col-1 img-position px-0 pt-1" style="width: 3%">
                        <p-button icon="pi pi-trash" styleClass="buttonFont p-button-rounded p-button-danger p-button-text" (onClick)="onDelete(question)"></p-button>
                        <span class="pi pi-align-justify"></span>
                    </div>
                    <div class="col-1 img-position pl-0 pt-0" *ngIf="question.type === questionExpectedAnswerTypeEnum.OpenEnded">
                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/openEndedQuestion.svg"/>
                    </div>
                    <div class="col-1 img-position pl-0 pt-0" *ngIf="question.type === questionExpectedAnswerTypeEnum.MultipleChoice">
                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/Quiz.svg"/>
                    </div>
                    <div class="question-content flex col pt-1">
                        <p [ngClass]="{'displayMore': question.showMoreText,'description2': question.showMoreText,'description1': !question.showMoreText}" [innerHTML]="question.questionText"></p>
                        <div *ngIf="question.isMandatory" class="error-message flex">*</div>
                        <div class="ml-4 cursor-pointer" (click)="toggleDisplay(question)">
                            <p class="more-field mb-0" *ngIf="!question.showMoreText"></p>
                            <img *ngIf="!question.showMoreText" class="align-self-start more-icon ml-2" src="/assets/vinter/icons/more-icon-right.svg"/>
                            <img *ngIf="question.showMoreText" class="align-self-start more-icon ml-2" src="/assets/vinter/icons/more-icon-down.svg"/>
                        </div>
                    </div>
                    </div>
                    <div *ngIf="question.showMoreText">
                    <div class="content-position">
                        <div *ngIf="question.type === questionExpectedAnswerTypeEnum.MultipleChoice" class="answer-container">
                            <div class="flex">
                                <div class="flex flex-column gap-2">
                                    <div *ngFor="let choice of sortChoicesByOrder(question); let i = index"
                                        class="radio-button-card field-checkbox"
                                        [ngClass]="{'correct-choice': choice.isCorrect}">
                                        <div class="flex align-items-center">
                                            <p-radioButton [disabled]="true" [value]="choice.isCorrect"
                                            [ngModel]="true" ></p-radioButton>
                                            <label [for]="choice.choiceText" [ngClass]="{'choice-text': !choice.isCorrect,'correct-choice-text':choice.isCorrect}" class="ml-2">{{ choice.choiceText }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="question.type === questionExpectedAnswerTypeEnum.OpenEnded" class="video-answer-container">
                            <p class="video-answer-text">{{'Key.OpenEndedQuestionExplanation' | translate}}</p>
                        </div>
                    </div>
                    

                </div>
            </div>
        </ng-container>
    </div>
</div>

<div *ngIf="questions.length === 0" class="no-questions-message pl-3">{{'Key.NoQuestions' | translate}}</div>
