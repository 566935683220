import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CredentialsService, LocalizationService } from '@app/core';
import { AddQuestionShownType, EQuestionCategories, EQuestionContentURLType, EntityStateEnum, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { AudioUploaderSimpleComponent } from '@app/vinter/components/uploader/audio-uploader-simple/audio-uploader-simple.component';
import { PictureUploaderSimpleComponent } from '@app/vinter/components/uploader/picture-uploader-simple/picture-uploader-simple.component';
import { VideoUploaderSimpleComponent } from '@app/vinter/components/uploader/video-uploader-simple/video-uploader-simple.component';
import { ChoiceContentListItem } from '@app/vinter/models/choice-content-list-item';
import { KeywordListItem } from '@app/vinter/models/keyword-list-item';
import { QuestionConceptListItem } from '@app/vinter/models/question-concept-list-item';
import { QuestionConceptPostItem } from '@app/vinter/models/question-concept-post-item';
import { QuestionContentListItem } from '@app/vinter/models/question-content-list-item';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { ChoiceContentCustomItem } from '@app/vinter/models/shared/choice-content-custom-item';
import { QuestionConceptService } from '@app/vinter/services/question-concept.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-add-your-question',
  templateUrl: './add-your-question.component.html',
  styleUrls: ['./add-your-question.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmationService, MessageService]
})
export class AddYourQuestionComponent {
    answerTypes: any[] = [];
    selectedAnswerType: any;
    keywords: string[] = [];
    isKeywordError = false;
    questionText: string;
    choiceContent: ChoiceContentCustomItem = new ChoiceContentCustomItem();
    choiceContentArray: ChoiceContentListItem[] = [];
    keywordList: KeywordListItem[] = [];
    checkCorrectAnswer: boolean;
    checkValid: boolean = false;
    OpenEndedAnswerType: number;
    VideoAnswerType: number;
    MultipleChoiceAnswerType: number;
    uploadedPictureUrl: string;
    openUploadPicture: boolean;
    questionContentInfo = new QuestionContentListItem();
    VideoQuestionContentType: number;
    AudioQuestionContentType: number;
    ImageQuestionContentType: number;
    selectedQuestionContentType: number = 0;
    oldRadioButtonEventValue: number = 0;
    questionInputLength: number = 0;
    selectedLanguageName = '';
    questionContentTypes: any[] = [];
    formats:any[];
    @Input() disabled: boolean = false;
    @Input() questionToDisplayArray;
    foreignLanguageCategory: string;
    questionCategories: any[] = [];
    @Output() goBackOneStep: EventEmitter<any> = new EventEmitter<any>();
    @Output() saveYourQuestions: EventEmitter<QuestionListItem[]> = new EventEmitter<QuestionListItem[]>();
    @Input() yourQuestionListTitle: string;
    @Input() selectedLanguageId: string;
    @Input() questionCollectionCategory: string;
    @ViewChild(VideoUploaderSimpleComponent) childVideo:VideoUploaderSimpleComponent;
    @ViewChild(PictureUploaderSimpleComponent) childPicture:PictureUploaderSimpleComponent;
    @ViewChild(AudioUploaderSimpleComponent) childAudio:AudioUploaderSimpleComponent;
    selectedCategory: string;
    @ViewChild('bottomDiv') bottomDiv: ElementRef;
    minLenght :number=5;
    maxLenght :number=750;
    questionConceptList: QuestionConceptListItem[];
    selectedConcept: any;
    newConceptName: any;
    questionDuration: number;
    questionWeight: number = 3;
    EQuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
    orgID: string;

    constructor(
        private appInfoService: AppInfoService,
        private localizationService: LocalizationService,
        public messageService: MessageService,
        private questionConceptService: QuestionConceptService,
        private credentialsService: CredentialsService
        ) { }

    ngOnInit(): void {
        this.orgID = this.credentialsService.getMainOrganizationID();
        this.selectedLanguageName = this.appInfoService.getLanguages().find(x =>x.id === this.selectedLanguageId)?.name;
        this.VideoAnswerType = QuestionExpectedAnswerType.Video;
        this.MultipleChoiceAnswerType = QuestionExpectedAnswerType.MultipleChoice;
        this.OpenEndedAnswerType = QuestionExpectedAnswerType.OpenEnded;
        this.VideoQuestionContentType = EQuestionContentURLType.Video;
        this.AudioQuestionContentType = EQuestionContentURLType.Audio;
        this.ImageQuestionContentType = EQuestionContentURLType.Image;
        this.foreignLanguageCategory = QuestionCollectionCategoryEnum.ForeignLanguage;
        let answerType = this.appInfoService.getExpectedAnswerTypesArray().filter(x => x.id != QuestionExpectedAnswerType.Coding);
        this.answerTypes = answerType.filter(item => item.id !== QuestionExpectedAnswerType.Coding);
        this.questionContentTypes = this.appInfoService.getQuestionTypesArray().filter(x=> x.id !== EQuestionContentURLType.Text);
        this.questionCategories = this.appInfoService.getQuestionCategories();
        this.formats=[
            //'background',
            'bold',
            //'color',
            //'font',
            //'code',
            'italic',
            //'link',
            //'size',
            //'strike',
            //'script',
            'underline',
            //'blockquote',
            //'header',
            //'indent',
            'list',
            //'align',
            //'direction',
            //'code-block',
            //'formula'
            // 'image'
            // 'video'
          ];
          if(this.questionCollectionCategory == QuestionCollectionCategoryEnum.CompetenceTest){
            this.getQuestionConceptList();
          }
    }

    changeClickableData(event){
        if(event.value === this.oldRadioButtonEventValue){
            this.selectedQuestionContentType = 0;
            this.oldRadioButtonEventValue=0;
        }else{
            this.oldRadioButtonEventValue = event.value;
        }
    }

    openUpload(){
        this.openUploadPicture = true;
        }

        closeDialog(){
        this.openUploadPicture = false;
        }

        getUploadedUrl(event: any){
        this.uploadedPictureUrl = event.url;
        this.questionContentInfo.contentURL = this.uploadedPictureUrl;
        this.questionContentInfo.contentURLSigned = event.signedUrl;

        }

    onAddChoiceText() {
        this.checkAddAnswerValidity();
        if(this.checkValid == false){
            return this.messageService.add({
                severity: 'error', summary: this.localizationService.translatedTextList.Error,
                detail: this.localizationService.translatedTextList.CannotAddAnswer, life: 3000
              });
        }
        else{
            const choiceContentInfo = new ChoiceContentListItem();
            choiceContentInfo.choiceText = this.choiceContent.choiceText;
            choiceContentInfo.isCorrect = this.choiceContent.isCorrect;
            choiceContentInfo.order = this.choiceContentArray.length+1;
            this.choiceContentArray.push(choiceContentInfo);
            this.choiceContentArray = [...this.choiceContentArray];
            this.choiceContent.choiceText = null;
            this.choiceContent.isCorrect = false;
            this.checkIsThereCorrectAnswer();

            let bottomElem = this.bottomDiv.nativeElement;

            if (bottomElem) {
                bottomElem.scrollIntoView({
                    behavior: "smooth",
                    block: "start"
                  });
            }
        }
      }

      checkIsThereCorrectAnswer(){
        if((this.choiceContentArray.find(x => x.isCorrect == true)) != undefined)
            this.checkCorrectAnswer = true;
        else
            this.checkCorrectAnswer = false;

      }

      onDelete(choiceText:string){
        var index = this.choiceContentArray.findIndex(x => x.choiceText == choiceText);
        if(index != -1){
            this.choiceContentArray.splice(index,1);
            this.checkIsThereCorrectAnswer();
        }
      }

      onDeleteQuestion(question:QuestionListItem){
        var index = this.questionToDisplayArray.findIndex(x => x.questionContent.questionText == question.questionContent.questionText);
        if(index != -1){
            this.questionToDisplayArray.splice(index,1);
        }
      }


      async onAddQuestion(){
        this.checkAddQuestionValidity();
        let isMediaError = false;
        if(this.checkValid == false){
            return this.messageService.add({
                severity: 'error', summary: this.localizationService.translatedTextList.Error,
                detail: this.localizationService.translatedTextList.CannotAddQuestion, life: 3000
              });
        }
        else{
            const questionInfo = new QuestionListItem();
            questionInfo.dummyID = uuidv4();
            questionInfo.duration = this.questionDuration;
            questionInfo.weight = this.questionWeight;

            this.keywords.forEach(element => {
                const keywordInfo = new KeywordListItem();
                keywordInfo.name = element;
                this.keywordList.push(keywordInfo);
            });
            switch(this.selectedQuestionContentType){
                case this.VideoQuestionContentType:
                    const res = await this.childVideo.onConfirm();
                    if(!(res ?? false)){
                        isMediaError = true;
                    }else{
                        this.questionContentInfo.contentURLType = EQuestionContentURLType.Video;
                    }
                    break;

                case this.ImageQuestionContentType:
                    const resImage = await this.childPicture.onConfirm();
                    if(!(resImage ?? false)){
                        isMediaError = true;

                    }else{
                        this.questionContentInfo.contentURLType = EQuestionContentURLType.Image;
                    }
                    break;

                case this.AudioQuestionContentType:
                    const resAudio = await this.childAudio.onConfirm();
                    if(!(resAudio ?? false)){
                        isMediaError = true;
                    }else{
                        this.questionContentInfo.contentURLType = EQuestionContentURLType.Audio;
                    }
                    break;
            }

            if(isMediaError){
                this.messageService.add({
                    severity: 'error',
                    summary: this.localizationService.translatedTextList.Error,
                    detail: this.localizationService.translatedTextList.EmptyMediaError,
                    life: 3000
                });
            }else{
                questionInfo.keywords = this.keywordList;
                questionInfo.choiceContents = this.choiceContentArray;
                questionInfo.choiceContents.forEach((item, index) => {
                    item.order = index + 1;
                  });
                questionInfo.expectedAnswerType = this.selectedAnswerType;
                this.questionContentInfo.questionText = this.questionText;
                questionInfo.questionContent = this.questionContentInfo;
                questionInfo.questionCategory = this.selectedCategory;
                if(this.questionCollectionCategory == QuestionCollectionCategoryEnum.CompetenceTest){
                    questionInfo.questionConceptID = this.selectedConcept.id;
                }
                else{
                    questionInfo.questionConcept = this.createNewQuestionConcept();
                }
                this.saveQuestions(questionInfo);
                this.clear();
            }
        }
      }

      clear(){
        this.checkCorrectAnswer = false;
        this.choiceContent.choiceText = null;
        this.choiceContent.isCorrect = false;
        this.choiceContentArray = [];
        this.keywords = [];
        this.keywordList = [];

      }

      checkAddAnswerValidity(){
        if(this.choiceContent.choiceText == null || this.choiceContent.choiceText == ""){
            this.checkValid = false;
        }
        else
            this.checkValid = true;
      }

      checkAddQuestionValidity(){
        this.checkValid = true;

        if(this.questionDuration == null || this.questionDuration == undefined || this.questionDuration == 0){
            this.checkValid = false;
        }

        if(this.questionWeight == null || this.questionWeight == undefined || this.questionWeight == 0){
            this.checkValid = false;
        }

        if(this.selectedAnswerType == null || this.selectedAnswerType == ""){
            this.checkValid = false;
        }
        if(this.selectedAnswerType == this.MultipleChoiceAnswerType){
            if(this.questionText == null || this.questionText == "" || this.choiceContentArray.length < 2 || this.questionInputLength > 750 || this.questionInputLength < 5 )
                this.checkValid = false;
        }
        if(this.selectedAnswerType == this.OpenEndedAnswerType){
            if(this.questionText == null || this.questionText == "" || this.keywords.length < 1 || this.questionInputLength > 750 || this.questionInputLength < 5 )
                this.checkValid = false;

        }
        if(this.selectedAnswerType == this.VideoAnswerType){
            if(this.questionText == null || this.questionText == "" || this.questionInputLength > 750 || this.questionInputLength < 5 )
                this.checkValid = false;
        }

        if(this.keywords.length < 3 &&
            (this.selectedAnswerType === QuestionExpectedAnswerType.Video || this.selectedAnswerType === QuestionExpectedAnswerType.OpenEnded)){
            this.isKeywordError = true;
            this.checkValid = false;
        }

        if((this.questionCollectionCategory == QuestionCollectionCategoryEnum.CompetenceTest)){
            if(this.selectedConcept === null || this.selectedConcept === undefined)
                this.checkValid = false;
        }

        if(this.questionCollectionCategory == QuestionCollectionCategoryEnum.TechnicalTest){
            if(this.newConceptName === null || this.newConceptName === undefined || this.newConceptName === "")
                this.checkValid = false;
        }
      }

      saveQuestions(question){
        this.saveYourQuestions.emit([question]);
      }

      questionCount(event){
        if(event.textValue == ''){
          this.questionInputLength = 0;

        }
        else{
          this.questionInputLength = event.textValue.length;
        }
      }

      validateWeight(weight) {
        if (weight < 1) {
          this.questionWeight = 1;
        } else if (weight > 5) {
          this.questionWeight = 5;
        }
      }

      isSubmitDisabled(): boolean {
        const isDurationAssigned = this.questionDuration !== null && this.questionDuration !== undefined && this.questionDuration !== 0;
        const isWeightAssigned = this.questionWeight !== null && this.questionWeight !== undefined && this.questionWeight !== 0;

        if(this.questionCollectionCategory == QuestionCollectionCategoryEnum.CompetenceTest){
            const isConceptSelected = this.selectedConcept !== null && this.selectedConcept !== undefined
            return (!(this.questionInputLength >= this.minLenght && this.questionInputLength <= this.maxLenght))||!(this.keywords.length>2) || !isConceptSelected || !isDurationAssigned || !isWeightAssigned;
        }
        else if(this.questionCollectionCategory == QuestionCollectionCategoryEnum.TechnicalTest){
            const newConcept = this.newConceptName !== null && this.newConceptName !== undefined && this.newConceptName !== ""
            return (!(this.questionInputLength >= this.minLenght && this.questionInputLength <= this.maxLenght))||!(this.keywords.length>2) || !newConcept || !isDurationAssigned || !isWeightAssigned;
        }
        else{
            return (!(this.questionInputLength >= this.minLenght && this.questionInputLength <= this.maxLenght))||!(this.keywords.length>2) || !isDurationAssigned || !isWeightAssigned;
        }
      }

      getQuestionConceptList(){
        const questionConceptFilter =
        {
            languageID: this.selectedLanguageId,
            type: QuestionCollectionCategoryEnum.CompetenceTest
        };
        this.questionConceptService.getListByFilter(questionConceptFilter).subscribe(data =>{
            this.questionConceptList = data;
        });
      }

      createNewQuestionConcept(){
        const newQuestionConcept = new QuestionConceptPostItem();
        newQuestionConcept.organizationID = this.orgID;
        newQuestionConcept.languageID = this.selectedLanguageId;
        newQuestionConcept.name = this.newConceptName;
        newQuestionConcept.type = this.EQuestionCollectionCategoryEnum.TechnicalTest;
        newQuestionConcept.entityState = EntityStateEnum.Added;
        return newQuestionConcept;
      }

      validateWholeNumber(event: Event): void {
        const input = event.target as HTMLInputElement;
        const value = input.value;
        if (!/^\d+$/.test(value)) {
          input.value = value.replace(/\D/g, '');
        }
    }
}
