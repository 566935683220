<div class="flex align-items-center position-create-user-avatar">
        <div class="col-2 p-0 w-min mr-2">
            <p-avatar *ngIf="(UserImageURL === null || UserImageURL === '' || UserImageURL === undefined) && UserFirstName ?? false && UserSurname ?? false" class="responsibleAvatar-blue ml-2"
            [label]="UserFirstName[0]?.toUpperCase() + UserSurname[0]?.toUpperCase()"
            shape="circle" styleClass="border-2 surface-border surface-ground ">
            </p-avatar>
            <p-avatar *ngIf="UserImageURL !== null && UserImageURL !== '' && UserImageURL !== undefined" class="responsibleAvatar-blue ml-2"
                      [image]="UserImageURL" shape="circle"
                      styleClass="border-2 surface-border surface-ground ">
            </p-avatar>
        </div>
        <div class="col-10 p-0 grid m-0">
            <div class="col-12 p-0 responsibleName">
                {{UserFirstName + ' ' + UserSurname}}
            </div>
            <div class="col-12 p-0 responsibleMail">
                {{UserTitle ?? false ? UserTitle : UserEmail}}
            </div>
        </div>
    </div>

