import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-step-count',
  templateUrl: './step-count.component.html',
  styleUrls: ['./step-count.component.scss']
})
export class StepCountComponent{

  items: MenuItem[] | undefined=[];
  @Input() stepCount :number;
  @Input() maxCount :number;
  

  ngOnInit() {
    if(this.stepCount==1){
      this.items.push({
        label: '',
        styleClass: 'last-step' 
      })
    }
    else{
      for (let index = 0; index < this.stepCount; index++) {
        if(index==0){
          this.items.push({
            label: '',
            styleClass: 'small-step first-step' 
          })
        }
        else if(index==this.stepCount-1){
          this.items.push({
            label: '',
            styleClass: 'last-step' ,
          })
        }
        else{
          this.items.push({
            label: '',
            styleClass: 'small-step' 
          })
        }
      }

      if(this.stepCount>this.maxCount)
      {
        let hiddenCount=this.stepCount-this.maxCount;
        for (let index = this.stepCount-hiddenCount-1; index < (this.stepCount-1); index++) {
          this.items[index].styleClass='small-step hidden-step'
          
        }
      }
    }
  }
}
