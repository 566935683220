import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-custom-chip',
  templateUrl: './custom-chip.component.html',
  styleUrls: ['./custom-chip.component.scss']
})
export class CustomChipComponent {
  @Input() label :string;
  @Input() svgUrl :string;
  @Input() alt :string;
  @Input() bgColorClass :string;
  @Input() imgSize :string;
}
