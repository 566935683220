import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-position-step-video-meeting',
  templateUrl: './position-step-video-meeting.component.html',
  styleUrls: ['./position-step-video-meeting.component.scss']
})
export class PositionStepVideoMeetingComponent {
  
  @Input() positionStepDescription;

}
