import { EntityStateEnum } from "@app/shared/common/enums";

export class ChoiceContentListItem {
    id: string;
    choiceText: string;
    isCorrect?: boolean;
    questionId: string;
    draggableId: number;
    order: number;
    entityState: EntityStateEnum;
    xmin:number;
}
