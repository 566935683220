<div [ngClass]="formType !== formTypeEnum.Position ? 'card mt-4 card-shadow' : ''">
    <div *ngIf="formType === formTypeEnum.Position" class="grid flex mr-2 ml-10 -my-6">
        <div class="field col flex justify-content-end">
            <p-button data-cy="button-create-position-step" *ngIf="formType === formTypeEnum.Position" [disabled]="
                    !(selectedQuestionList.length > 0) ||
                    questionCollectionListDuration == undefined ||
                    questionCollectionListDuration === 0" 
                    iconPos="right" label="{{ 'Key.Create' | translate }}" styleClass="buttonFont"
                    pTooltip="{{showErrorMessage()}}" tooltipPosition="bottom"
                (onClick)="checkQuestionWeightForList() ? openWeightDialog() : saveAllDataForStep()"></p-button>
        </div>
    </div>
    <br>
    <div *ngIf="formType !== formTypeEnum.Position" class="mb-5">
        <p class="card-text1">
            {{ "Key.QuestionListSelectTitle" | translate }}
        </p>
        <p class="card-text2">
            {{ "Key.QuestionListSelectDescription" | translate }}
        </p>
    </div>
    <div class="grid flex height-fit question-list-card justify-content-between"
        [ngStyle]="formType !== 1 ? { width: '99%' } : { width: '98%' }">
        <div class="col-12 pb-0 pl-2 pr-1 pt-1">
            <div>
                <div class="title-label">
                    <div class="row flex justify-content-start">
                        <label data-cy="label-list-title" class="list-label-title">
                            <label class="list-label mr-5">
                                {{ "Key.QuestionListTitle" | translate }}
                            </label>
                            {{ questionCollectionFormInfo?.title }}
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 p-0">
            <hr class="mt-2 mb-0 mr-3 ml-3" />
        </div>

        <div [ngClass]="formType !== formTypeEnum.Position ? 'icon-positions-positionPage' : 'icon-positions' "
            class="col-6 w-auto flex-wrap flex align-content-center pt-0 h-5rem align-content-center question-type-icon pl-1 pb-0 pr-1">
            <div class="flex justify-content-start">
                <img class="mr-3 ml-1" src="../../../../../assets/vinter/icons/openEndedBlue.svg" width="24px" />
                <div class="mr-2 align-item-center align-self-center detailSubFont" style="color: #2885f1">
                    {{ openEndedAnswerCount }}
                </div>
                <div class="vl"></div>
                <img class="mr-3 ml-3" src="../../../../../assets/vinter/icons/multipleChoiceAnswerPurple.svg"
                    width="24px" />
                <div class="mr-2 align-item-center align-self-center detailSubFont" style="color: #722dde">
                    {{ multipleChoiceAnswerCount }}
                </div>
                <div class="vl"></div>
                <img class="mr-3 ml-3" src="../../../../../assets/vinter/icons/videoAnswerBlue.svg" width="24px" />
                <div class="mr-2 align-item-center align-self-center detailSubFont" style="color: #6255f3">
                    {{ videoAnswerCount }}
                </div>
                <div class="vl"></div>
                <img class="mr-3 ml-3" src="../../../../../assets/vinter/icons/coding-color.svg" width="24px" />
                <div class="mr-2 align-item-center align-self-center detailSubFont" style="color: #1f31d3">
                    {{ codingAnswerCount }}
                </div>
            </div>
        </div>

        <div [ngClass]="formType !== formTypeEnum.Position ? 'button-positions-positionPage' : 'button-positions' " 
            class="col-6 flex preview-buttons pl-1 pr-1 pt-0 pb-0 align-items-center w-auto">
            <div class="flex gap-1 ml-1 mr-2">
                <img src="../../../../../assets/vinter/icons/step-timer-icon.svg" width="18px" />
                <div class="flex align-item-center align-self-center step-time-font font-medium text-overflow-ellipsis">
                    {{ questionCollectionListDuration }}'
                </div>
            </div>
            <!-- <div *ngIf="setTimerButtonVisible()" class="mr-2">
                <span class="p-input-icon-left">
                    <i class="pi pi-stopwatch" style="color: #2962ff"></i>
                    <input class="w-8rem" pInputText required type="number"
                        placeholder="{{ 'Key.Time' | translate }}" min="1" inputId="integeronly"
                        (ngModelChange)="onDurationChange($event)" [(ngModel)]="questionCollectionListDuration" />
                </span>
            </div> -->
            <div *ngIf="addButtonDisabled" class="flex preview">
                <p-button [disabled]="!addButtonDisabled" icon="pi pi-eye" label="{{ 'Key.Preview' | translate }}"
                    styleClass="p-button-preview" (click)="previewQuestions()"></p-button>

                <p-button *ngIf="isAddPersonalQuestionBtnVisible" [disabled]="!addButtonDisabled" icon="pi pi-plus"
                    label="{{ 'Key.NewQuestion' | translate }}" styleClass="p-button-custom" class="ml-2"
                    (click)="goCreateNewQuestion()"></p-button>
            </div>
        </div>
    </div>

    <div>
        <span class="select-question-text-span w-auto">
            <label *ngIf="positionName != null" class="flex select-question-text-label">
                {{ "Key.SelectQuestionsText" | translate : { value1: questionCollectionFormInfo?.title ? questionCollectionFormInfo?.title : "", value2: positionName } }}
            </label>
            <label *ngIf="positionName == null" class="flex select-question-text-label">
                {{ "Key.SelectQuestionsTextWithoutPosition"  | translate : { value1: questionCollectionFormInfo?.title ? questionCollectionFormInfo?.title : "" } }}
            </label>
        </span>
        <span class="add-question-text-span">
            <label class="add-question-text-label h-auto">
                {{"Key.AddQuestionForQuestionListText" | translate : { value: language } }}
            </label>
        </span>
    </div>

    <div class="grid flex w-full">
        <div class="col-12 sm:col-12 md:col-12 lg:col-9 xl:col-9 pr-0">
            <span class="p-input-icon-right w-full">
                <i class="pi pi-search"></i>
                <input type="text" class="w-full" pInputText [(ngModel)]="searchText" (ngModelChange)="onSearchChange()"
                    placeholder="{{ 'Key.Search' | translate }}" />
            </span>
        </div>

        <div class="col-12 sm:col-12 md:col-12 lg:col-3 xl:col-3 pr-0">
            <span class="w-full flex">
                <p-dropdown class="w-full flex" [options]="organizationsCreatedBy" [(ngModel)]="selectedCreatedBy"
                    (onChange)="searchCreatedBy($event)" (onClear)="clearOrganizationSelection()"
                    optionLabel="organizationName" appendTo="body" [filter]="true" [showClear]="true"
                    placeholder="{{ 'Key.AllLibrary' | translate }}">
                    <ng-template pTemplate="selectedItem">
                        <div class="flex align-items-center gap-2" *ngIf="selectedCreatedBy">
                            <div class="text-overflow-ellipsis overflow-x-hidden">{{ selectedCreatedBy.organizationName }}</div>
                        </div>
                    </ng-template>
                </p-dropdown>
            </span>
        </div>
    </div>

    <div class="card-content-question-list question-collection-page">
        <div class="flex flex-wrap card-container grid mr-0 card-responsive">
            <ng-template ngFor let-item [ngForOf]="questionCollectionsDisplay" let-i="index">
                <p-card data-cy="component-question-list" [ngClass]="{
                        'added-item-border ': item.isAllAdded,
                        'lg:col-4 ': formType === formTypeEnum.Position,
                        'lg:col-3 ': formType !== formTypeEnum.Position}" 
                    class="col-12 md:col-6 clickable-card col-3-margin"
                    [style]="{ height: '100%', position: 'relative' }">
                    <div *ngIf="item.organizationID == mainOrgId" class="ribbon ribbon-top-right">
                        <span>{{ "Key.MyLibrary" | translate }}</span>
                    </div>

                    <ng-template pTemplate="header">
                        <div class="col-12 position-card-org-header-div pl-4">
                            <span class="position-card-org-header" [ngClass]="{
                                    technical: item.questionCollectionCategory == questionCollectionCategoryEnum.TechnicalTest,
                                    personality: item.questionCollectionCategory == questionCollectionCategoryEnum.PersonalityTest,
                                    cognitive: item.questionCollectionCategory == questionCollectionCategoryEnum.CognitiveAbility,
                                    competence: item.questionCollectionCategory == questionCollectionCategoryEnum.CompetenceTest,
                                    custom: item.questionCollectionCategory == questionCollectionCategoryEnum.Custom,
                                    pronunciation: item.questionCollectionCategory == questionCollectionCategoryEnum.Pronunciation,
                                    foreignLanguage: item.questionCollectionCategory == questionCollectionCategoryEnum.ForeignLanguage }">
                                
                                {{ "Key." + item.questionCollectionCategoryName | translate  }}
                            </span>
                        </div>
                        <!-- [ngStyle]="{'padding-left': '1.2rem','padding-right': '1.2rem','background-color': item.orgBackColor,'color': item.orgTextColor}" -->
                        <div class="grid">
                            <div class="col-10 mt-0 title-position mb-0 pb-0 pl-4">
                                <h5 data-cy="label-question-list-title" class="mt-3 ml-3 title-content">
                                    {{ item.title }}
                                </h5>
                            </div>
                        </div>
                    </ng-template>

                    <div>
                        <div class="content-wrapper library-card-content">
                            <p>{{ item.description }}</p>
                        </div>
                        <div *ngIf="item.questionCollectionCategory === foreignLanguageQuestionCollectionCategory " class="grid align-items-center gap-1 mt-2">
                            <app-custom-chip [label]="item.writingCount" [svgUrl]=" 'assets/vinter/icons/writing-icon.svg'" 
                                [alt]="'Writing'" [bgColorClass]="'writing-class'" [imgSize]="'16px'">
                            </app-custom-chip>
                            <hr class="vertical-line" />
                            <app-custom-chip [label]="item.readingCount" [svgUrl]=" 'assets/vinter/icons/reading-icon.svg' " 
                                [alt]="'Reading'" [bgColorClass]="'reading-class'" [imgSize]="'16px'">
                            </app-custom-chip>
                            <hr class="vertical-line" />
                            <app-custom-chip [label]="item.listeningCount" [svgUrl]=" 'assets/vinter/icons/listening-icon.svg' " 
                                [alt]="'Listening'" [bgColorClass]="'listening-class'" [imgSize]="'16px'">
                            </app-custom-chip>
                            <hr class="vertical-line" />
                            <app-custom-chip [label]="item.vocabularyCount" [svgUrl]=" 'assets/vinter/icons/vocabulary-icon.svg' " 
                                [alt]="'Vocabulary'" [bgColorClass]="'vocabulary-class'" [imgSize]="'16px'">
                            </app-custom-chip>
                            <hr class="vertical-line" />
                            <app-custom-chip [label]="item.grammerCount" [svgUrl]=" 'assets/vinter/icons/grammer-icon.svg' " 
                                [alt]="'Grammer'" [bgColorClass]="'grammer-class'" [imgSize]="'16px'">
                            </app-custom-chip>
                            <hr *ngIf="item.subCategory === EQuestionCollectionSubCategory.GeneralEnglish" class="vertical-line" />
                            <app-custom-chip *ngIf="item.subCategory === EQuestionCollectionSubCategory.GeneralEnglish" [label]="item.speakingCount" [svgUrl]=" 'assets/vinter/icons/speaking-icon.svg' " 
                                [alt]="'Speaking'" [bgColorClass]="'speaking-class'" [imgSize]="'16px'">
                            </app-custom-chip>
                        </div>

                        <div class="grid pl-3 mt-0">
                            <div *ngIf="item.expectedAnswerOpenEndedCount > 0" class="pr-1 pt-3">
                                <span class="mt-5">
                                    <app-custom-chip [label]="item.expectedAnswerOpenEndedCount" [svgUrl]="'assets/vinter/icons/icon-park_text-style-open-ended.svg'" 
                                        [alt]="'OpenEnded'" [bgColorClass]="'light-blue'" [imgSize]="'16px'">
                                    </app-custom-chip>
                                </span>
                            </div>
                            <div *ngIf="item.expectedAnswerMultipleChoiceCount > 0" class="pr-1 pt-3">
                                <span class="mt-5">
                                    <app-custom-chip [label]="item.expectedAnswerMultipleChoiceCount" [svgUrl]="'assets/vinter/icons/icon-park-outline_choice.svg' " 
                                        [alt]="'MultipleChoice'" [bgColorClass]="'purple'" [imgSize]="'16px'">
                                    </app-custom-chip>
                                </span>
                            </div>
                            <div *ngIf="item.expectedAnswerVideoCount > 0" class="pr-1 pt-3">
                                <span class="mt-5">
                                    <app-custom-chip [label]="item.expectedAnswerVideoCount" [svgUrl]="'assets/vinter/icons/icon-park_text-tabler_video.svg'" 
                                        [alt]="'VideoQuestion'" [bgColorClass]="'dark-blue'" [imgSize]="'16px'">
                                    </app-custom-chip>
                                </span>
                            </div>
                            <div *ngIf="item.expectedAnswerCodingCount > 0" class="pr-1 pt-3">
                                <span class="mt-5">
                                    <app-custom-chip [label]="item.expectedAnswerCodingCount" [svgUrl]="'assets/vinter/icons/coding-white.svg'" 
                                        [alt]="'CodingQuestion'" [bgColorClass]="'blue'" [imgSize]="'16px'">
                                    </app-custom-chip>
                                </span>
                            </div>
                        </div>

                        <div class="spacer"></div>
                        <hr class="mt-5" />
                        <div class="flex">
                            <div class="flex w-6 flex-wrap justify-content-start pt-2 mr-2">
                                <p-button label="{{ 'Key.View' | translate }}" styleClass="p-button-outlined"
                                    class="w-full card-button" icon="pi pi-eye" (click)="
                                        goViewQuestions(
                                            item.id,
                                            item.title,
                                            item.organizationID,
                                            item.questionCollectionCategory,
                                            item.description,
                                            item.duration,
                                            item.subCategory,
                                            true)">
                                </p-button>
                            </div>
                            <div class="flex w-6 flex-wrap justify-content-end pt-2 ml-2">
                                <p-button data-cy="button-add-question-list" *ngIf="!item.isAllAdded"
                                    [disabled]="isOceanOrForeignLangSelected" label="{{'Key.AddQuestionToList' | translate }}"
                                    class="w-full card-button" styleClass="p-button" icon="pi pi-plus"
                                    (click)="onAddClicked(item)"></p-button>
                                <p-button *ngIf="item.isAllAdded" label="{{ 'Key.Remove' | translate }}"
                                    class="w-full card-button" styleClass="p-button p-button-danger" icon="pi pi-minus"
                                    (click)="onRemoveClicked(item)"></p-button>
                            </div>
                        </div>
                    </div>
                </p-card>
            </ng-template>
        </div>
    </div>
    <br />
</div>


<p-dialog [(visible)]="dialogVisible" [draggable]="false" [modal]="true" showEffect="fade"
    [style]="{width: '30vw'}" [breakpoints]="{'960px': '75vw'}" class="dialogPage"  [closable]="false">
    <div class="grid">
        <div class="col-12" style="display: flex;justify-content: center;">
            <img style="width: 4rem;" src="../../../../../assets/vinter/icons/warning-icon.svg">
        </div>
        <div class="col-12 complete-dialog-header">
            {{'Key.ChangeWeightDialogHeader' | translate}}
        </div>
        <div class="col-12 complete-dialog-text pb-0">
            {{'Key.ChangeWeightDialogText' | translate}}
        </div>
        <div class="col-12 complete-dialog-header">
            <div class="col-6 flex justify-content-end">
                <p-button label="{{'Key.Approve' | translate}}" (onClick)="saveAllDataForStep()"
                    styleClass="mr-2 mb-2"></p-button>
            </div>
            <div class="col-6 flex justify-content-start">
                <p-button label="{{'Key.ChangeWeight' | translate}}" (onClick)="changeQuestionWeight()"
                    styleClass="mr-2 mb-2 p-button-outlined"></p-button>
            </div>
        </div>
    </div>
</p-dialog>