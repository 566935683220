import { YourQuestionListPreviewComponent } from '@app/vinter/components/question-collection/question-collection-create/your-question-list-preview/your-question-list-preview.component';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { QuestionCollectionFormItem } from '@app/vinter/models/question-collection-form';
import { QuestionCollectionFormComponent } from './question-collection-form/question-collection-form.component';
import { SelectQuestionsComponent } from './select-questions/select-questions.component';
import { SelectedQuestionCollectionItem } from '@app/vinter/models/shared/selected-question-collection-item';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { AddYourQuestionComponent } from './add-your-question/add-your-question.component';
import { CredentialsService, LocalizationService } from '@app/core';
import { QuestionCollectionListComponent } from './question-collection-list/question-collection-list.component';
import { QuestionCollectionService } from '@app/vinter/services/question-collection.service';
import { QuestionCollectionStepQuestionCustomItem } from '@app/vinter/models/shared/question-collection-question-custom-item';
import { AddQuestionShownType, EFormType, EQuestionCollectionSubCategory, PositionFormEditModeEnum, PositionStepTypeEnum, QuestionCollectionCategoryEnum, QuestionListRootEnum } from '@app/shared/common/enums';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { VINTERADMIN } from '@app/shared/common/constants';

@Component({
    selector: 'app-question-collection-create',
    templateUrl: './question-collection-create.component.html',
    styleUrls: ['./question-collection-create.component.scss']
})
export class QuestionCollectionCreateComponent {
    visible: boolean = true;
    createQuestionListSteps = [];
    activeIndex = 0;
    subStep: boolean = false;
    selectedQuestionList: SelectedQuestionCollectionItem = new SelectedQuestionCollectionItem();
    questionCollectionInfo: QuestionCollectionFormItem = new QuestionCollectionFormItem();
    questionsForQuestionList: QuestionListItem[] = [];
    yourQuestions: QuestionListItem[] = [];
    saveNewQuestionCollectionId: string;
    subQuestionStep: boolean = false;
    isPreviewCurrentCollectionOpen: boolean = false;
    PreviewCurrentType = AddQuestionShownType.PreviewCurrent;
    SaveForListType = AddQuestionShownType.SaveForList;
    SaveForStepType = AddQuestionShownType.SaveForStep;
    selectedQuestionCategory: any = null;
    saveQuestionListCategoryId: any = null;
    pageHeader: string;
    dynamicTitle: string = "QuestionCollectionName";
    questionListDuration : any ;
    showMoreText:boolean = false;

    @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
    @ViewChild(QuestionCollectionFormComponent) child: QuestionCollectionFormComponent;
    @ViewChild(SelectQuestionsComponent) selectQuestionListChild: SelectQuestionsComponent;
    @ViewChild(AddYourQuestionComponent) addYourQuestionChild: AddYourQuestionComponent;
    @ViewChild(QuestionCollectionListComponent) questionCollectionListComponent: QuestionCollectionListComponent;
    @ViewChild(YourQuestionListPreviewComponent) yourQuestionListPreviewComponent: YourQuestionListPreviewComponent;
    @Input() languageId: string;
    @Input() stepDescription: string = '';
    @Input() positionEditFormMode: PositionFormEditModeEnum;
    editModeEnum = PositionFormEditModeEnum.EditMode;
    useFromModeEnum = PositionFormEditModeEnum.UseFromMode;
    @Input() positionStepInfo;
    @Input() stepTitle: string = '';
    @Input() isNewStep: boolean;
    isAddPersonalQuestionBtnVisible: boolean;
    @Output() onStepCreated: EventEmitter<QuestionCollectionStepQuestionCustomItem> = new EventEmitter<QuestionCollectionStepQuestionCustomItem>();
    @Output() onSave: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() getQuestionCollectionId: EventEmitter<string> = new EventEmitter<string>();
    @Input() positionName: string;
    disableButton: boolean = true;
    isVinterAdminLogged: boolean;
    userGroup: string[] = [];
    questionListMode: number;
    myLibrary: QuestionListRootEnum.MyLibrary;
    vinterLibrary: QuestionListRootEnum.VinterLibrary;
    formTypeEnum = EFormType;

    constructor(
        private router: Router,
        private localizationService: LocalizationService,
        private questionCollectionService: QuestionCollectionService,
        private route: ActivatedRoute,
        private credentialService: CredentialsService,
    ) { }

    ngOnInit(): void {
        this.questionListMode = Number(this.route.snapshot.queryParamMap.get('questionListMode'));
        this.pageHeader = this.setPageHeader();
        this.setStepList();
        this.getEditData();
        this.userGroup = this.credentialService.getUserGroups().split(',');
        if(this.userGroup.some(s => s.includes(VINTERADMIN)))
            this.isVinterAdminLogged = true;
        else
            this.isVinterAdminLogged = false;
    }


    checkAddPersonalQuestionButtonVisibility(){

        this.isAddPersonalQuestionBtnVisible = (this.questionCollectionInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest  ||
            this.questionCollectionInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.CognitiveAbility ||
            this.questionCollectionInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.Pronunciation ||
            this.questionCollectionInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.ForeignLanguage) && !this.isVinterAdminLogged  ? false : true;

        if(this.positionEditFormMode == PositionFormEditModeEnum.TemplateMode && this.isVinterAdminLogged &&
            this.questionCollectionInfo.filteredQuestionCollectionCategory === QuestionCollectionCategoryEnum.PersonalityTest)
            this.isAddPersonalQuestionBtnVisible = false;
    }


    setPageHeader(){
        return "Key.NewQuestionListTemplate"
    }

    backToQuestionList(){
        if(this.questionListMode == QuestionListRootEnum.MyLibrary){
            this.router.navigate(['my-library'],{queryParams:{ activeTab:1 }});
        }
        else if(this.questionListMode == QuestionListRootEnum.VinterLibrary){
            this.router.navigate(['vinter-library'],{queryParams:{ activeTab:1 }});
        }
    }

    setStepList() {
        this.createQuestionListSteps = [
            { label: this.localizationService.translateText('QuestionListName') },
            { label: this.localizationService.translateText('SelectQuestions') },
            { label: this.localizationService.translateText('Preview') },
        ];

        for (let index = 0; index < this.createQuestionListSteps.length; index++) {
            if (index <= this.activeIndex) {
                this.createQuestionListSteps[index]['styleClass'] += ' stepLiOld';
            } else {
                this.createQuestionListSteps[index]['styleClass'] += ' stepLiNew';
            }

            if (index === (this.createQuestionListSteps.length - 1)) {
                this.createQuestionListSteps[index]['styleClass'] += ' stepLiLast';
            }
        }
    }

    getLanguage(event: string){
        this.route.queryParams.subscribe(params => {
            if(params['questionCollectionCreateTemplate'] == 1)
                this.languageId = event;
      });
    }

    enableNextButton(event: boolean){
        this.disableButton = !event;
    }

    setQestionCollectionData(questionCollection){

        this.questionCollectionInfo.questionCollectionCategory = questionCollection.questionCollectionCategory;
        this.questionCollectionInfo.filteredQuestionCollectionCategory = questionCollection.questionCollectionCategory;
        this.questionCollectionInfo.isTemprature = false;
        this.questionCollectionInfo.languageId = this.languageId;
        this.checkAddPersonalQuestionButtonVisibility();
        this.questionsForQuestionList = [];
        questionCollection?.questions?.forEach((item, index) => {
            if (questionCollection.subCategory == EQuestionCollectionSubCategory.OCEAN50 || questionCollection.subCategory == EQuestionCollectionSubCategory.OCEAN120) {
                item.choiceContents.forEach(element => {
                    element.isCorrect = true;
                });
            }
            this.questionsForQuestionList.push(item);

        });
        this.enableNextButton( this.questionsForQuestionList.length > 0)

    }

    getEditData() {
        let questionCollectionId = null;
        if (this.positionStepInfo ?? false) {
            questionCollectionId = this.positionStepInfo.questionCollectionID;
            this.questionCollectionInfo.description = this.positionStepInfo.description;
            this.questionCollectionInfo.showToApplicant = !this.positionStepInfo.isHide;
            this.questionCollectionInfo.title = this.positionStepInfo.title;
            this.questionCollectionInfo.responsible = this.positionStepInfo.responsibleID;
            this.questionCollectionInfo.duration = this.positionStepInfo.duration;
            if (questionCollectionId ?? false) {
                this.questionCollectionService.GetQuestionCollectionWihtQuestionsChoice(questionCollectionId).subscribe(element => {
                    this.setQestionCollectionData(element);
                });
            }else{
                if(this.isNewStep){
                    this.setQestionCollectionData(this.positionStepInfo);
                }
            }
        }else{
            if(this.positionEditFormMode === PositionFormEditModeEnum.EditMode){
                this.positionEditFormMode = PositionFormEditModeEnum.CreateMode;
            }
        }
    }

    saveQuestionListInfo(event, isFromEditCurrent) {
        if(this.questionCollectionInfo.title === '' || !(this.questionCollectionInfo.title ?? false) || isFromEditCurrent){
            this.questionCollectionInfo.title = event.title;
        }

        if(event.duration ?? false){
            this.questionCollectionInfo.duration = event.duration;
        }

        if(isFromEditCurrent){
            if(this.questionCollectionInfo.description === '' || !(this.questionCollectionInfo.description ?? false) || isFromEditCurrent){
                this.questionCollectionInfo.description = event.description;
            }
        }
        else{
            if(this.questionCollectionInfo.description === '' || !(this.questionCollectionInfo.description ?? false) || isFromEditCurrent){
                this.questionCollectionInfo.description = "";
            }
        }

    }

    createQuestionList() {
        this.visible = true;
    }

    getQuestionCollectionInfo(event: any) {
        this.questionCollectionInfo = event;

        this.activeIndex++;
        this.setStepList();
    }

    closeDialog(event: boolean) {
        if (event) {
            this.close();
        }
    }

    resetQuestionList(){
        this.questionsForQuestionList = [];
        this.enableNextButton(false)
    }

    goBackOneStep() {
        this.activeIndex--;
        this.setStepList();
    }

    saveQuestionCollection(event) {
        if(this.isVinterAdminLogged){
            this.questionCollectionService.saveQuestionCollectionAndQuestion(event).subscribe(questionCollectionID => {
                if (questionCollectionID != null) {
                    this.router.navigate(['/vinter-library']);
                }
            });
        }
        else{
            this.questionCollectionService.saveQuestionCollectionAndQuestion(event).subscribe(questionCollectionID => {
                if (questionCollectionID != null) {
                    this.router.navigate(['/my-library']);
                }
            });
        }
    }

    getQuestionCollectionCategoryToStepCategory(questionCollectionCategoryId) {
        if (questionCollectionCategoryId === QuestionCollectionCategoryEnum.Custom) {
            return PositionStepTypeEnum.Custom;
        } else {
            return PositionStepTypeEnum.QuestionList;
        }
    }

    saveStepInfo(event) {
        event.stepInfo.type = this.getQuestionCollectionCategoryToStepCategory(event.stepInfo.questionCollectionCategory);
        this.onStepCreated.emit(event);
    }

    next() {
        if (this.activeIndex === 0) {
            this.setQuestionInfoData();
            if (this.questionCollectionInfo ?? false) {
                if (this.questionCollectionInfo ?? false) {
                    this.activeIndex++;
                    this.setStepList();
                }
            }
            this.enableNextButton(false);

        } else if (this.activeIndex === 1) {
            this.activeIndex++;
            this.setStepList();

        }
    }

    setQuestionInfoData() {
        this.questionCollectionInfo = this.child.saveQuestionListInfo();
        this.checkAddPersonalQuestionButtonVisibility();
    }

    goViewQuestions(event: SelectedQuestionCollectionItem) {
        this.selectedQuestionList=event;
    }

    changeSubStep(){
        this.subStep = true;
    }

    goCreateNewQuestion(event: any) {
        this.subQuestionStep = true;
    }

    openPreviewQuestions(event: any) {
        this.isPreviewCurrentCollectionOpen = true;
    }

    selectedCategory(event: any) {
        this.selectedQuestionCategory = event;
    }

    saveQuestionsForQuestionList(event: any) {
        this.questionsForQuestionList = event;
        this.subStep = false;
        this.isPreviewCurrentCollectionOpen = false;
        this.enableNextButton( this.questionsForQuestionList.length > 0)
    }

    saveCreatedQuestionList(event: any) {
        this.yourQuestions = event;
    }

    saveQuestionListCategory(event: any) {
        this.saveQuestionListCategoryId = event;
    }

    saveYourQuestions(event: any) {
        this.yourQuestions = event;
        this.questionsForQuestionList = this.questionsForQuestionList.concat(this.yourQuestions);
        this.enableNextButton( this.questionsForQuestionList.length > 0)

        this.subQuestionStep = false;
    }

    saveQuestionCollectionId(event: any) {
        this.saveNewQuestionCollectionId = event;
    }

    closeSubStep() {
        this.subStep = false;
    }

    closeSubQuestionStep() {
        this.subQuestionStep = false;
    }

    closeDialogOnSave() {
        this.visible = false;
        this.getQuestionCollectionId.emit(this.saveNewQuestionCollectionId);
    }



    close() {
        this.activeIndex = 0;
        this.subStep = false;
        this.subQuestionStep = false;
        this.questionCollectionInfo.description = null;
        this.questionCollectionInfo.organizationID = null;
        this.questionCollectionInfo.questionCollectionCategory = null;
        this.questionCollectionInfo.title = null;
        this.questionCollectionInfo.description = null;
        this.questionCollectionInfo.organizationID = null;
        this.questionCollectionInfo.questionCollectionCategory = null;
        this.questionCollectionInfo.title = null;
        this.questionsForQuestionList = [];
        this.enableNextButton(false)
        this.child.resetForm();
        this.setStepList();
        this.yourQuestions = [];
        this.onClose.emit(false);
    }

    onSaveDialog(event: boolean) {
        this.onSave.emit(event);
    }

    saveQuestionListSubCategory(event: number) {
        this.questionCollectionInfo.subCategory = event;
    }

    changeInfo(event) {
        this.questionCollectionInfo.title = event.title == null ? this.questionCollectionInfo.title :
            (event.title == "" ?
                this.localizationService.translateText("Untitled") : event.title);
        this.questionCollectionInfo.duration = event.duration == null ? this.questionCollectionInfo.duration :
            event.duration == "" ? 0 : event.duration;
    }

    saveQuestionList() {
        this.yourQuestionListPreviewComponent.saveAllData();
    }

    addAllQuestionsOfCollection(event) {
        this.questionsForQuestionList.concat(event);
        this.enableNextButton( this.questionsForQuestionList.length > 0)
    }

    saveCustomStep(event) {
        event.stepInfo.type = PositionStepTypeEnum.Custom;
        event.stepInfo.subCategory = !(this.questionCollectionInfo.subCategory ?? false) ? 0 : event.subCategory;
        this.onStepCreated.emit(event);
    }
    questionCollectionDuration(event) {
      this.questionListDuration = event
    }
}
