import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CredentialsService, LocalizationService } from '@app/core';
import { VINTERADMIN, vinterOrganizationGuid } from '@app/shared/common/constants';
import { QuestionExpectedAnswerType, QuestionCollectionCategoryEnum, EQuestionCollectionSubCategory, AddQuestionShownType, EQuestionContentURLType, EQuestionCategories, OrganizationEnum, EFormType } from '@app/shared/common/enums';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { SelectedQuestionCollectionItem } from '@app/vinter/models/shared/selected-question-collection-item';
import { QuestionCollectionService } from '@app/vinter/services/question-collection.service';
import { ElementRef } from '@angular/core';
import { QuestionContentListItem } from '@app/vinter/models/question-content-list-item';
import { ChoiceContentListItem } from '@app/vinter/models/choice-content-list-item';
import { FormBuilder,FormGroup} from '@angular/forms';
import { MessageService } from 'primeng/api';

@Component({
    selector: 'app-select-questions',
    templateUrl: './select-questions.component.html',
    styleUrls: ['./select-questions.component.scss']
})
export class SelectQuestionsComponent {
    sourceQuestions: QuestionListItem[] = [];
    targetQuestions: QuestionListItem[] = [];
    tempSourceQuestions: QuestionListItem[] = [];
    vinterGuid: string;
    questionsCopyTest: QuestionListItem;
    questionsCopyTest2: QuestionListItem;
    VideoAnswerType: number;
    MultipleChoiceAnswerType: number;
    OpenEndedAnswerType: number;
    openEndedAnswerCount: number = 0;
    videoAnswerCount: number = 0;
    codingAnswerCount: number = 0;
    multipleChoiceAnswerCount: number = 0;
    imgPath: string = "assets/demo/images/vinter/vinterlogo.jpg";
    selectedItems: boolean[] = [];
    selectAllItems: boolean = false;
    questionCollectionCategories: any[] = [];
    checkboxDisabled: boolean = true;
    isPersonalityOrLanguageSelected: boolean = false;
    selectedLanguageDisplay: string = '';
    QuestionContentType = EQuestionContentURLType;

    questionExpectedAnswerTypeEnum = QuestionExpectedAnswerType;
    OCEAN50: number = EQuestionCollectionSubCategory.OCEAN50;
    OCEAN120: number = EQuestionCollectionSubCategory.OCEAN120;
    VinterOrganization = OrganizationEnum.Vinter;
    formTypeEnum = EFormType;

    isOceanSelected: boolean = false;
    isVinterAdminLogged: boolean;
    userGroup: string[] = [];
    showQuestions: boolean = true;
    questionCategoryType = EQuestionCategories;
    isLanguageSelected: boolean = false;
    writingCount: string;
    readingCount: string;
    listeningCount: string;
    vocabularyCount: string;
    grammerCount: string;
    speakingCount: string;
    categoryName: string;
    showMoreText: boolean = false;
    duration: number;
    questionListDuration: number;
    QuestionCollectionCategoryEnum = QuestionCollectionCategoryEnum;
    viewQuestionListDuration: number;
    EQuestionCollectionSubCategory = EQuestionCollectionSubCategory;

    @Input() selectedLanguage: any;
    @Input() addQuestionShownType: AddQuestionShownType;
    @Input() positionStepDescription;
    @Input() questionList;
    @Input() positionStepQuestionCollectionId;
    @Input() positionStepSubCategory;
    @Input() showDeprecatedQuestions: boolean;
    @Input() generalAppQuestions;
    @Input() selectedQuestionList: SelectedQuestionCollectionItem;
    @Input() yourQuestionListTitle: string;
    @Input() formType: number;
    @Input() isFromEditCurrent: boolean;
    @Input() yourQuestionListDescription: string;
    @Input() yourQuestionListDuration: number;
    @Input() selectedQuestions: QuestionListItem[];
    @Input() selectedQuestionCategory: any;
    @Input() currentQuestionList: any = [];
    @Input() createdQuestionList: any = [];

    @Output() saveQuestionsForQuestionList: EventEmitter<QuestionListItem[]> = new EventEmitter<QuestionListItem[]>();
    @Output() saveCreatedQuestionList: EventEmitter<QuestionListItem[]> = new EventEmitter<QuestionListItem[]>();
    @Output() saveQuestionListInfo: EventEmitter<any> = new EventEmitter<any>();

    @Output() saveQuestionListCategory: EventEmitter<string> = new EventEmitter<string>();
    @Output() saveQuestionListSubCategory: EventEmitter<number> = new EventEmitter<number>();
    @Output() closeSubStep: EventEmitter<any> = new EventEmitter<any>();


    categoryColorArray = [
        { label: QuestionCollectionCategoryEnum.TechnicalTest, 'backgroundColor': '#CFDBF9', 'textColor': '#2962FF' },
        { label: QuestionCollectionCategoryEnum.PersonalityTest, 'backgroundColor': '#FFF4D9', 'textColor': '#EFAC00' },
        { label: QuestionCollectionCategoryEnum.Custom, 'backgroundColor': '#FFDCDC', 'textColor': '#FF5454' },
        { label: QuestionCollectionCategoryEnum.CompetenceTest, 'backgroundColor': '#D0F9CF', 'textColor': '#32C52F' },
        { label: QuestionCollectionCategoryEnum.ForeignLanguage, 'backgroundColor': '#EBE2FA', 'textColor': '#722DDE' },
        { label: QuestionCollectionCategoryEnum.Pronunciation, 'backgroundColor': '#EBE2FA', 'textColor': '#722DDE' },
        { label: QuestionCollectionCategoryEnum.CognitiveAbility, 'backgroundColor': '#FFD9C0', 'textColor': '#FF8027' }
    ];

    tempDurations: { [key: string]: { duration: number, weight: number } } = {};
    editingQuestions: { [key: string]: { duration: boolean, weight: boolean } } = {};

    constructor(
        private service: QuestionCollectionService,
        private appInfoService: AppInfoService,
        public credentialService: CredentialsService,
        public localizationService: LocalizationService,
        private messageService: MessageService
    ) {}

    ngOnInit(): void {
        if(this.selectedQuestionCategory!=null && this.selectedQuestionCategory !== undefined)
            this.categoryName = this.appInfoService.getQuestionCollectionCategoryArray().find(x => x.id === this.selectedQuestionCategory).name;
        this.userGroup = this.credentialService.getUserGroups().split(',');
        if (this.userGroup.some(s => s.includes(VINTERADMIN)))
            this.isVinterAdminLogged = true;
        else
            this.isVinterAdminLogged = false;

        this.selectedLanguageDisplay = this.appInfoService.getLanguageCategories().find(x => x.id === this.selectedLanguage).name;
        this.VideoAnswerType = QuestionExpectedAnswerType.Video;
        this.MultipleChoiceAnswerType = QuestionExpectedAnswerType.MultipleChoice;
        this.OpenEndedAnswerType = QuestionExpectedAnswerType.OpenEnded;
        this.vinterGuid = vinterOrganizationGuid;
        this.fillYourQuestionListWithSelectedQuestions();
        this.getQuestionsOfQuestionList(this.addQuestionShownType === AddQuestionShownType.PreviewCurrent ? null : this.selectedQuestionList.id);
        this.selectQuestionCategory();
        this.setOceanStaticQuestions();
        this.setOceanStaticAnswer();
        this.initializeTempDurations(this.targetQuestions);
        this.initializeEditingStates(this.targetQuestions);
        this.calculateTotalDuration();
    }
    initializeTempDurations(questions) {
        questions.forEach(question => {
            if(question.id !== undefined){
                this.tempDurations[question.id] = {duration:question.duration, weight: question.weight };
            }
            else{
                this.tempDurations[question.dummyID] = {duration:question.duration, weight: question.weight };
            }
        });
    }
    initializeEditingStates(questions) {
        questions.forEach(question => {
            if(question.id !== undefined){
                this.editingQuestions[question.id] = {duration:false, weight: false };
            }
            else{
                this.editingQuestions[question.dummyID] = {duration:false, weight: false };
            }
        });
      }
    editDuration(questionId){
        this.editingQuestions[questionId].duration = !this.editingQuestions[questionId].duration;
    }
    saveDuration(questionId){
        if (this.tempDurations[questionId] !== undefined && this.tempDurations[questionId].duration > 0) {
            if(this.isFromEditCurrent){
                this.targetQuestions.find(x => x.id == questionId || x.dummyID == questionId).duration = this.tempDurations[questionId].duration;
            }
            else{
                if(this.targetQuestions.find(x => x.id == questionId || x.dummyID == questionId) ?? false){
                    this.targetQuestions.find(x => x.id == questionId || x.dummyID == questionId).duration = this.tempDurations[questionId].duration;
                }
                else{
                    this.tempSourceQuestions.find(x => x.id == questionId || x.dummyID == questionId).duration = this.tempDurations[questionId].duration;

                }
            }

            this.calculateTotalDuration();
            this.editingQuestions[questionId].duration = !this.editingQuestions[questionId].duration;
        }
        else{
            this.messageService.add({
                severity: 'error', summary: this.localizationService.translatedTextList.Error,
                detail: this.localizationService.translatedTextList.NotValidDuration, life: 3000
            });
        }
    }


    editWeight(questionId){
        this.editingQuestions[questionId].weight = !this.editingQuestions[questionId].weight;
    }
    saveWeight(questionId){
        if (this.tempDurations[questionId] !== undefined && this.tempDurations[questionId].weight > 0) {
            if(this.isFromEditCurrent){
                this.targetQuestions.find(x => x.id == questionId || x.dummyID == questionId).weight = this.tempDurations[questionId].weight;
            }
            else{
                if(this.targetQuestions.find(x => x.id == questionId || x.dummyID == questionId) ?? false){
                    this.targetQuestions.find(x => x.id == questionId || x.dummyID == questionId).weight = this.tempDurations[questionId].weight;
                }
                else{
                    this.tempSourceQuestions.find(x => x.id == questionId || x.dummyID == questionId).weight = this.tempDurations[questionId].weight;

                }
            }

            this.editingQuestions[questionId].weight = !this.editingQuestions[questionId].weight;
        }
        else{
            this.messageService.add({
                severity: 'error', summary: this.localizationService.translatedTextList.Error,
                detail: this.localizationService.translatedTextList.NotValidDuration, life: 3000
            });
        }
    }
    validateWeight(questionId: string) {
        const weight = this.tempDurations[questionId].weight;

        if (weight < 1) {
          this.tempDurations[questionId].weight = 1;
        } else if (weight > 5) {
          this.tempDurations[questionId].weight = 5;
        }
    }

    calculateTotalDuration() {
        this.questionListDuration = this.targetQuestions.reduce((sum, question) => sum + question.duration, 0);
    }

    selectQuestionCategory() {
        if (this.selectedQuestionCategory !== null) {
            if (this.selectedQuestionCategory === QuestionCollectionCategoryEnum.ForeignLanguage
                || this.selectedQuestionCategory === QuestionCollectionCategoryEnum.PersonalityTest
                || this.selectedQuestionCategory === QuestionCollectionCategoryEnum.Pronunciation
                || this.selectedQuestionCategory === QuestionCollectionCategoryEnum.CognitiveAbility) {
                this.checkboxDisabled = false;
                this.isPersonalityOrLanguageSelected = true;

            }else{
                this.isPersonalityOrLanguageSelected = false;
            }

            if (this.selectedQuestionCategory == QuestionCollectionCategoryEnum.ForeignLanguage)
                 this.isLanguageSelected = true;

            if (this.selectedQuestionCategory === QuestionCollectionCategoryEnum.PersonalityTest && !this.isVinterAdminLogged)
                this.showQuestions = false;

        }
    }


    checkSelectAllStatus() {
        if (this.selectedItems.every(item => item === true)) {
            this.selectAllItems = true;
        } else {
            this.selectAllItems = false;
        }
    }
    getStepStyle(category: any) {
        const categoryItem = this.categoryColorArray.find(item => item.label === this.selectedQuestionCategory);
        return {
          'background-color': categoryItem.backgroundColor,
          'color': categoryItem.textColor
        };
      }
    showQuestionList(subCategory) {
        if (this.isVinterAdminLogged && (subCategory == this.OCEAN50 || subCategory == this.OCEAN120)) {
            this.showQuestions = true;
        }
        else if (!this.isVinterAdminLogged && (subCategory == this.OCEAN50 || subCategory == this.OCEAN120)) {
            this.showQuestions = false;
        }
        else
            this.showQuestions = true;
    }

    getTestCases(jsonData){
        let testcases = JSON.parse(jsonData.toString()).Test_cases;
        return testcases;
    }

    setOceanStaticQuestions() {
        const questionText = this.localizationService.translateText('ExampleOceanQuestion')
        const createQuestion = (showMoreText: boolean) => {
            const question = new QuestionListItem();
            question.showMoreText = showMoreText;
            question.questionContent = new QuestionContentListItem();
            question.questionContent.questionText = questionText;
            question.isDeprecated = false;
            question.expectedAnswerType = this.questionExpectedAnswerTypeEnum.MultipleChoice;
            return question;
        };

        this.questionsCopyTest = createQuestion(false)
        this.questionsCopyTest2 = createQuestion(false)
    }

    setOceanStaticAnswer() {
        const answerTexts = [
            "OceanTestAnswer1",
            "OceanTestAnswer2",
            "OceanTestAnswer3",
            "OceanTestAnswer4",
            "OceanTestAnswer5"
        ];

        this.questionsCopyTest.choiceContents = answerTexts.map(answer => {
            const choice = new ChoiceContentListItem();
            choice.choiceText = this.localizationService.translateText(answer);
            return choice;
        });
    }

    setQuestionCounts() {
        this.openEndedAnswerCount = this.tempSourceQuestions.filter(item => item.expectedAnswerType === QuestionExpectedAnswerType.OpenEnded).length;
        this.videoAnswerCount = this.tempSourceQuestions.filter(item => item.expectedAnswerType === QuestionExpectedAnswerType.Video).length;
        this.multipleChoiceAnswerCount = this.tempSourceQuestions.filter(item => item.expectedAnswerType === QuestionExpectedAnswerType.MultipleChoice).length;
        this.codingAnswerCount = this.tempSourceQuestions.filter(item => item.expectedAnswerType === QuestionExpectedAnswerType.Coding).length;

        if (this.isLanguageSelected) {
            this.writingCount = this.tempSourceQuestions.filter(item => item.questionCategory === this.questionCategoryType.Writing).length.toString();
            this.readingCount = this.tempSourceQuestions.filter(item => item.questionCategory === this.questionCategoryType.Reading).length.toString();
            this.listeningCount = this.tempSourceQuestions.filter(item => item.questionCategory === this.questionCategoryType.Listening).length.toString();
            this.vocabularyCount = this.tempSourceQuestions.filter(item => item.questionCategory === this.questionCategoryType.Vocabulary).length.toString();
            this.grammerCount = this.tempSourceQuestions.filter(item => item.questionCategory === this.questionCategoryType.Grammer).length.toString();
            this.speakingCount = this.tempSourceQuestions.filter(item => item.questionCategory === this.questionCategoryType.Speaking).length.toString();
        }
    }

    getQuestionsOfQuestionList(id: string) {
        if (id ?? false) {
            this.service.GetQuestionCollectionWihtQuestionsChoice(id).subscribe(element => {
                if (element == null) {
                    this.isPersonalityOrLanguageSelected = true;
                    this.showQuestions = false;

                }
                else {
                    element.questions.sort((a, b) => a.order - b.order);
                    this.tempSourceQuestions = [];
                    this.sourceQuestions = element.questions;
                    let index = 0;
                    element.questions.forEach((item) => {
                        if (element.subCategory == EQuestionCollectionSubCategory.OCEAN50 || element.subCategory == EQuestionCollectionSubCategory.OCEAN120) {
                            item.choiceContents.forEach(element => {
                                element.isCorrect = true;
                            });
                        }
                        if (!item.isDeprecated)
                            this.tempSourceQuestions.push(item);

                        if (!item.isDeprecated) {
                            if (!(this.targetQuestions.find(x => x.id == item.id))) {
                                this.selectedItems[index] = false;
                            } else {
                                this.selectedItems[index] = true;
                            }
                            index++;
                        }
                    });

                    this.tempSourceQuestions[0].showMoreText = false;

                    this.checkSelectAllStatus();
                    this.setQuestionCounts();
                    this.initializeTempDurations(this.tempSourceQuestions);
                    this.initializeEditingStates(this.tempSourceQuestions);
                    this.viewQuestionListDuration = this.tempSourceQuestions.reduce((sum, question) => sum + question.duration, 0);
                }

            });
        } else {
            this.tempSourceQuestions = [];
            this.currentQuestionList.forEach((item, index) => {
                if (item.isDeprecated != true)
                    this.tempSourceQuestions.push(item);

                this.selectedItems[index] = true;
            });

            this.createdQuestionList.forEach((item, index) => {
                if (!(this.tempSourceQuestions.find(x => x === item))) {
                    this.tempSourceQuestions.push(item);
                    this.selectedItems[index + this.currentQuestionList.length] = true;
                }
            });
            this.tempSourceQuestions[0].showMoreText = false;
            this.checkSelectAllStatus();
            this.setQuestionCounts();

        }

    }

    sortChoicesByOrder(question) {
        if (question && question.choiceContents) {
            question.choiceContents.sort((a, b) => a.order - b.order);
        }
        return question.choiceContents;
    }

    selectAll(checked: boolean) {
        for (let i = 0; i < this.tempSourceQuestions.length; i++) {
            this.selectedItems[i] = checked;
            const itemIndex = this.targetQuestions.findIndex(x => this.tempSourceQuestions[i] === x);
            const itemIndexYourList = this.targetQuestions.findIndex(x => this.tempSourceQuestions[i] === x);
            if (checked && itemIndex === -1) {
                if (!this.tempSourceQuestions[i].isDeprecated)
                    this.targetQuestions.push(this.tempSourceQuestions[i]);
            } else if (!checked && itemIndex !== -1) {
                this.targetQuestions.splice(itemIndex, 1);
            }

            if (!checked && itemIndexYourList !== -1) {
                this.createdQuestionList.splice(itemIndex, 1);
            }
        }
    }

    checkChanged(index: number) {
        if (this.selectedItems[index]) {
            this.targetQuestions.push(this.tempSourceQuestions[index]);
        } else {
            if (this.tempSourceQuestions[index].id ?? false) {
                let itemIndex = this.targetQuestions.findIndex(x => this.tempSourceQuestions[index].id === x.id);
                if (itemIndex > -1) {
                    this.targetQuestions.splice(itemIndex, 1);
                }
            } else {
                let itemIndexCreated = this.createdQuestionList.findIndex(x => this.tempSourceQuestions[index] === x);
                if (itemIndexCreated > -1) {
                    this.createdQuestionList.splice(itemIndexCreated, 1);
                }
            }

            this.selectAllItems = false;
        }

        if (this.selectedItems.every(item => item === true)) {
            this.selectAllItems = true;
        }
    }

    fillYourQuestionListWithSelectedQuestions() {
        this.targetQuestions = this.selectedQuestions.slice();
    }

    moveToSourceEvent(event: any) {
        event.items.forEach(element => {
            if (!(this.sourceQuestions.find(x => x.id == element.id))) {
                const index = this.tempSourceQuestions.indexOf(element, 0);
                this.tempSourceQuestions.splice(index, 1);
            }
        });
    }

    saveQuestionList() {
        if (this.isPersonalityOrLanguageSelected) {
            this.selectAll(true);
        }
        this.calculateTotalDuration();
        this.saveQuestionsForQuestionList.emit(this.targetQuestions);
        this.saveCreatedQuestionList.emit(this.createdQuestionList);
        this.saveQuestionListInfo.emit({ title: this.yourQuestionListTitle, description: this.yourQuestionListDescription, duration: this.questionListDuration})
        if (this.isOceanSelected)
            this.saveQuestionListSubCategory.emit(EQuestionCollectionSubCategory.OCEAN50);
    }

    toggleDisplay(question: any) {
        question.showMoreText = !question.showMoreText;
        this.showMoreText = !this.showMoreText;
    }

    validateWholeNumber(event: Event): void {
        const input = event.target as HTMLInputElement;
        const value = input.value;
        if (!/^\d+$/.test(value)) {
          input.value = value.replace(/\D/g, '');
        }
    }

}

