import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/core/configuration.service';
import { HttpBaseService } from '@app/core/http/http-base.service';
import { LocalizationService } from '@app/core/localization.service';
import { NotifyService } from '@app/core/notify.service';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs/internal/Observable';
import { OrganizationLicensePlanListItem, OrganizationPlan, StripeReturnDto } from '../models/license-plan-list-item';

@Injectable({
  providedIn: 'root'
})
export class LicensePlanService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.LicensePlan, notifyServ, localizationService, configService);
  }

  GetListForSelect(): Observable<any> {
    return this.getAll("GetListForSelect");
  }
  GetLicensePlanList(): Observable<OrganizationPlan>{
    return this.get("GetLicensePlanList");
  }
  getOrganizationLicenseList(): Observable<OrganizationLicensePlanListItem>{
    return this.get("GetOrganizationLicenseList");
  }
}
