import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppInfoService } from '@app/shared/services/app-info.services';
import { PositionService } from '@app/vinter/services/position.service';
import { CredentialsService } from '@app/core';

@Component({
  selector: 'app-organization-library-list',
  templateUrl: './organization-library-list.component.html',
  styleUrls: ['./organization-library-list.component.scss']
})
export class OrganizationLibraryListComponent implements OnInit{

  data: string;
  showVinterLogo: boolean;


  constructor(private service: PositionService,
    private credentialService: CredentialsService,
    public router: Router) { }

  ngOnInit(): void {
    this.setData();

  }

  setData(){
    this.showVinterLogo = false;
    this.data=this.credentialService.getMainOrganizationID();
  }



}
