<div *ngIf="isSelected === false" class="flex justify-content-center">
   <p-fileUpload
        #fileInput
       [disabled]="disabled"
        class="fileupload"
        accept="image/*"
        (onSelect)="onFileSelected($event)"
        (onClear)="onClear()"
        [customUpload]="true"
    >

    <ng-template pTemplate="content" let-files>
        <div class="flex justify-content-center align-item-center flex-wrap">
            <div class="w-full w-3" >
                <p-button label="{{'Key.ChooseFile' | translate}}" class="file-button" styleClass="p-button-text" (onClick)="selectFile()"></p-button>
            </div>

            <div class="w-full flex justify-content-center align-item-center flex-wrap">
                <div >
                    <img src="assets/vinter/icons/custom-upload-icon.svg">
                </div>
                <div class="w-full flex justify-content-center align-item-center">
                    <p class="drop-text">{{'Key.DropFiles' | translate}}</p>
                </div>
            </div>
        </div>
    </ng-template>
</p-fileUpload>
</div>
<div *ngIf="isSelected === true" class="mt-5 upload-border">
        <img class="image-style" *ngIf="pictureUrl" [src]="pictureUrl" alt="vinter">
        <label class="url-label">{{fileName}}</label>
    <button pButton *ngIf="isSelected === true"  class="cancel-upload-selected" icon="pi pi-times" (click)="onClear()" style="margin-top:20px"></button>
</div>

