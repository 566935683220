import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/core/configuration.service';
import { HttpBaseService } from '@app/core/http/http-base.service';
import { LocalizationService } from '@app/core/localization.service';
import { NotifyService } from '@app/core/notify.service';
import { ApiControllerEnum, EntityStateEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs/internal/Observable';
import { UserManagementFormItem } from '../models/user-profile-form-item';
import { UserForUserProfileItem, UserManagementListItem } from '../models/user-profile-list-item';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.User, notifyServ, localizationService, configService);
  }

  getMemberListByFilter(filter: any): Observable<UserManagementListItem[]> {
    return this.post("getMemberListByFilter", filter, true);
  }

  getUserListForSelect(filter: any): Observable<UserManagementListItem[]> {
    return this.post("getUserListForSelect", filter, true);
  }
  
  getUserListForAccount(filter: any): Observable<UserManagementListItem[]> {
    return this.post("getUserListForAccount", filter, true);
  }

  getDataByFilter(filter: any): Observable<UserManagementListItem> {
    return this.post("GetDataByFilter", filter, true);
  }
}
