import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-organization-avatar',
  templateUrl: './organization-avatar.component.html',
  styleUrls: ['./organization-avatar.component.scss']
})
export class OrganizationAvatarComponent {

  @Input() OrganizationName: string;
  @Input() ContactUserEmail: string;
  @Input() OrganizationImageURL: string = null;
  
}
