<div class="grid mt-3 mb-2 ml-0" style="width: 32rem;">
    <div class="col-6 p-0" style="font-size: 0.6rem; color: #686E75;">
        {{'Key.Questions' | translate}}
    </div>
    <div class="col-6 p-0">
        <div class="w-full flex justify-content-between">
            <div *ngFor="let questionWithId of getCurrentStepQuestions(itemId)" class="w-full flex ">
                <div *ngIf="questionWithId.id === itemId" class="flex justify-content-end w-full gap-2">
                    <div class="flex align-items-center justify-content-center custom-display" [style.width]="calculateProgressWidth(questionWithId.correctAnsweredQuestionsCount, questionWithId.questionCount) + '%'">
                        <div class="progress-container-c">
                            <div class="progress-bar-c"
                                [style.backgroundColor]="'#37AC00'">
                                <span *ngIf="questionWithId.correctAnsweredQuestionsCount !== 0" class="progress-text-c">{{questionWithId.correctAnsweredQuestionsCount}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex align-items-center justify-content-centerr custom-display" [style.width]="calculateProgressWidth(questionWithId.incorrectAnsweredQuestionsCount, questionWithId.questionCount) + '%'">
                        <div class="progress-container-c">
                            <div class="progress-bar-c"
                                [style.backgroundColor]="'#F20505'">
                                <span *ngIf="questionWithId.incorrectAnsweredQuestionsCount !== 0" class="progress-text-c">{{questionWithId.incorrectAnsweredQuestionsCount}}</span>
                            </div>
                        </div>
                    </div>

                    <div class="flex align-items-center justify-content-center custom-display" [style.width]="calculateProgressWidth(questionWithId.notAnsweredQuestionsCount, questionWithId.questionCount) + '%'">
                        <div class="progress-container-c">
                            <div class="progress-bar-c"
                                [style.backgroundColor]="'#CFDBF9'">
                                <span *ngIf="questionWithId.notAnsweredQuestionsCount !== 0" class="progress-text-c">{{questionWithId.notAnsweredQuestionsCount}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="grid mr-2">
    <div class="col-2 pb-0">

    </div>
    <div class="col-10 pb-0 flex justify-content-end">
        <i class="pi pi-circle-fill" style="color: #37AC00; font-size: 0.4rem;">
            <span class="status-text " style="color: #4F565E;">
                {{'Key.Correct' | translate}}
            </span>
        </i>
        <i class="pi pi-circle-fill" style="color: #F20505; font-size: 0.4rem;">
            <span class="status-text" style="color: #4F565E;">
                {{'Key.Incorrect' | translate}}
            </span>
        </i>
        <i class="pi pi-circle-fill" style="color: #CFDBF9; font-size: 0.4rem;">
            <span class="status-text" style="color: #4F565E;">
                {{'Key.NotAnswered' | translate}}
            </span>
        </i>
    </div>
</div>
