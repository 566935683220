import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PositionFormEditModeEnum, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { GeneralApplicationQuestionListItem } from '@app/vinter/models/generalApplicationQuestion-list-item';

@Component({
  selector: 'app-general-application-question-view',
  templateUrl: './general-application-question-view.component.html',
  styleUrls: ['./general-application-question-view.component.scss']
})
export class GeneralApplicationQuestionViewComponent {
    questionExpectedAnswerTypeEnum = QuestionExpectedAnswerType;
    generalAppQuestionDisplay: any;
    positionEditMode = PositionFormEditModeEnum.EditMode;
    @Input() positionEditFormMode: any;
    @Input() questions : any;
    @Output() deleteQuestion: EventEmitter<number> = new EventEmitter<number>();
    @Output() deleteAllQuestion: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor() { }

    ngOnInit(): void {
        this.generalAppQuestionDisplay = this.questions;
    }

    toggleDisplay(question: any) {
        question.showMoreText = !question.showMoreText;
    }


    sortChoicesByOrder(question) {
        question.generalApplicationQuestionChoices.sort((a, b) => a.order - b.order);
        return question.generalApplicationQuestionChoices;
    }

    onDelete(question: any){
        this.deleteQuestion.emit(question);
    }
}
