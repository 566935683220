<div>
    <h5 class="h5-font mt-0 ml-3">{{'Key.Questions' | translate}}</h5>
    <div *ngIf="questions" class="overflow-y-auto max-h-30rem">
        <ng-container *ngIf="questions.length > 0">
            <div *ngFor="let question of questions; let i = index" class="question-container">
              <div class="grid">
                <div class="question-number col-1 pt-5 mr-0 pr-0" style="width: 3%;">
                    {{ i + 1 }}.
                </div>
                <div class="col-1 pt-4" *ngIf="question.expectedAnswerType === 3">
                    <p-chip class="alternative-chip" [style]="{'background-color': '#25B1FF'}"
                        icon="pi pi-video"></p-chip>
                </div>
                <div class="col-1 pt-4" *ngIf="question.expectedAnswerType === 2">
                    <p-chip class="alternative-chip" [style]="{'background-color': '#377BFF'}"
                        icon="pi pi-align-justify"></p-chip>
                </div>
                <div class="col-1 pt-4" *ngIf="question.expectedAnswerType === 1">
                    <p-chip class="alternative-chip" [style]="{'background-color': '#9858FF'}"
                        icon="pi pi-list"></p-chip>
                </div>
                <div class="question-content col-10 mt-3">{{ question.questionContent.questionText }}</div>
            </div>
                <div class="keyword-list">
                    <ng-container *ngIf="question.keywords.length > 0">
                        <p-tag *ngFor="let keyword of question.keywords" severity="primary" value="{{keyword.name }}">
                        </p-tag>
                    </ng-container>
                    <ng-container *ngIf="question.choiceContents?.length > 0">
                      <div class="choices-container flex">
                        <ng-template ngFor let-item [ngForOf]="question.choiceContents">
                          <div class="choice-item flex">
                            <div class="col-1">
                                <p-checkbox [(ngModel)]="item.isCorrect" [binary]="true" [disabled]="true"></p-checkbox>
                            </div>
                            <div class="col-11 p-0">
                                <p-tag [ngClass]="[item.isCorrect ? 'true-color': '']" class="w-full flex"
                                    value="{{item.choiceText}}"></p-tag>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
        <div *ngIf="questions.length === 0" class="no-questions-message">{{'Key.NoQuestions' | translate}}</div>
    </div>
</div>
