import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { EQuestionContentURLType, QuestionExpectedAnswerType } from '@app/shared/common/enums';
import { QuestionCollectionListItem } from '@app/vinter/models/question-collection-list';
import { QuestionListItem } from '@app/vinter/models/question-list-item';
import { QuestionCollectionService } from '@app/vinter/services/question-collection.service';

@Component({
  selector: 'app-position-step-personality-test',
  templateUrl: './position-step-personality-test.component.html',
  styleUrls: ['./position-step-personality-test.component.scss']
})
export class PositionStepPersonalityTestComponent {
  questions: QuestionListItem[] = [];
  questionCollections: QuestionCollectionListItem []=[];
  questionCollectionDisplay: QuestionCollectionListItem[]= [];
  private questionCollectionId: string;
  questionExpectedAnswerTypeEnum = QuestionExpectedAnswerType;
  QuestionContentType=EQuestionContentURLType;
  selectedRadioValue: any;
  @Input() 
  set positionStepQuestionCollectionId(value: string) {
    this.questionCollectionId = value;
     
    if(this.positionStepQuestionCollectionId)
      this.getQuestionsByQuestionCollectionId();
    else
      this.questions=[];
  }
  get positionStepQuestionCollectionId(): string {
    return this.questionCollectionId;
  }

  constructor(private service: QuestionCollectionService,
    public router: Router,
    ) { }

    ngOnInit(): void {
    }
    toggleDisplay(question:any) {
      question.showMoreText = !question.showMoreText;
    }
    getQuestionsByQuestionCollectionId(){
      this.service.getListByFilter({id: this.questionCollectionId}).subscribe(questionCollections => {
        this.questionCollections = questionCollections;
      });
      this.service.GetQuestionCollectionWihtQuestionsChoice(this.questionCollectionId).subscribe((result)=>{
        this.questions = result.questions;
        for(let question of this.questions){
          question.showMoreText=false
        }
      });
     
    }
}
