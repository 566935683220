import { EntityStateEnum } from "@app/shared/common/enums";
import { KeywordListItem } from "./keyword-list-item";
import { QuestionContentListItem } from "./question-content-list-item";
import { ChoiceContentListItem } from "./choice-content-list-item";
import { QuestionCollectionListItem } from "./question-collection-list";
import { QuestionConceptListItem } from "./question-concept-list-item";
import { QuestionConceptPostItem } from "./question-concept-post-item";

export class QuestionListItem {
  id: string;
  questionType: number;
  expectedAnswerType: number;
  order: number;
  duration: number;
  weight: number;
  questionContent: QuestionContentListItem;
  keywords: KeywordListItem[];
  choiceContents:ChoiceContentListItem[];
  originQuestionCollection:QuestionCollectionListItem;
  showMoreText:boolean;
  isDeprecated?: boolean;
  entityState:EntityStateEnum;
  xmin:number;
  questionCategory: string;
  questionConcept: QuestionConceptPostItem;
  questionConceptID: string;
  dummyID: string;
}
