import { Injectable } from '@angular/core';
import { ConnectionCriteriaFormulaTypeEnum, ConnectionCriteriaMatchEnum, QuestionCollectionCategoryEnum, QuestionExpectedAnswerType, StatusEnum, TargetDataTypeEnum, YesNoEnum, ActiveEnum, PositionStepType, EApplicantBehaviourEventType, EQuestionContentURLType, LicenseTypeEnum, OrganizationSortEnum, ELanguages, LanguageEnum, EVoiceSpeechSpeed, PositionStepTypeEnum, EDeviceType, StepNameCategoryNameEnum, OfferRejectedReasons, EQuestionCategories, WritingAnalysisMap, WringErrorColorEnum, OrganizationPositionSortEnum, EApprovalStatus, EAutomationType, UserGroupEnum, EOrganizationAccountType, ELicenseType, ELicensePaymentStatus, MailDeliveryStatus, EInvitationStatus, EDealRange, EDealRangeWithoutTrial, EOneShotLicensePaymentStatus, WorkModels, EOrganizationApiKeyExpirationRangeType } from '../common/enums';
import { LocalizationService, CredentialsService } from '@app/core';
import { LayoutService } from '@app/layout/service/app.layout.service';
import { AttributeList } from '../common/constants';

@Injectable({ providedIn: 'root' })
export class AppInfoService extends LayoutService{
  constructor(private localizationService: LocalizationService, private credentialsService: CredentialsService) {
    super();
   }

  public get title() {
    return 'Vinter';
  }

  getStatusArray(): any {
    return Object.keys(StatusEnum).filter(key => typeof StatusEnum[key] === 'number')
      .map(key => ({ id: StatusEnum[key], name: this.localizationService.translateText(key) }));
  }

  getActiveStatusArray(): any {
    return Object.keys(ActiveEnum).filter(key => typeof ActiveEnum[key] === 'number')
      .map(key => ({ id: ActiveEnum[key], name: this.localizationService.translateText(key) }));
  }
  getApprovalStatusArray(): any {
    return Object.keys(EApprovalStatus).filter(key => typeof EApprovalStatus[key] === 'number')
      .map(key => ({ id: EApprovalStatus[key], name: this.localizationService.translateText(key) }));
  }

  getModelOptionsArray(): any {
    return Object.keys(WorkModels).filter(key => typeof WorkModels[key] === 'number')
      .map(key => ({ id: WorkModels[key], name: this.localizationService.translateText(key) }));
  }

  getAnswerTypesArray(): any {
    return Object.keys(QuestionExpectedAnswerType).filter(key => typeof QuestionExpectedAnswerType[key] === 'number')
      .map(key => ({ id: QuestionExpectedAnswerType[key], name: this.localizationService.translateText(key) }));
  }

  getPositionStepTypeArray(): any {
    return Object.keys(PositionStepType).filter(key => typeof PositionStepType[key] === 'number')
      .map(key => ({ id: PositionStepType[key], name: this.localizationService.translateText(key) }));
  }
  getPositionStepTypeEnumArray(): any {
    return Object.keys(PositionStepTypeEnum).filter(key => typeof PositionStepTypeEnum[key] === 'number')
      .map(key => ({ id: PositionStepTypeEnum[key], name: this.localizationService.translateText(key) }));
  }

  getExpectedAnswerTypesArray(): any {
    return Object.keys(QuestionExpectedAnswerType).filter(key => typeof QuestionExpectedAnswerType[key] === 'number')
      .map(key => ({ id: QuestionExpectedAnswerType[key], name: this.localizationService.translateText(key) }));
  }

  getMailDeliveryStatusArray(): any {
    return Object.keys(MailDeliveryStatus).filter(key => typeof MailDeliveryStatus[key] === 'number')
      .map(key => ({ id: MailDeliveryStatus[key], name: this.localizationService.translateText(key) }));
  }

  getAutomationTypesArray(): any {
    return Object.keys(EAutomationType).filter(key => typeof EAutomationType[key] === 'number')
      .map(key => ({ id: EAutomationType[key], name: this.localizationService.translateText(key) }));
  }

  getQuestionCollectionCategoryArray(): any {
    return Object.keys(QuestionCollectionCategoryEnum).filter(key => typeof QuestionCollectionCategoryEnum[key] === 'string')
      .map(key => ({ id: QuestionCollectionCategoryEnum[key], name: this.localizationService.translateText(key) }));
  }
  getLanguageCategories(): any {
    return Object.keys(LanguageEnum).filter(key => typeof LanguageEnum[key] === 'string')
      .map(key => ({ id: LanguageEnum[key], name: this.localizationService.translateText(key) }));
  }
  getLicenseTypeArray(): any {
    return Object.keys(LicenseTypeEnum).filter(key => typeof LicenseTypeEnum[key] === 'number')
      .map(key => ({ id: LicenseTypeEnum[key], name: this.localizationService.translateText(key) }));
  }
  getOrganizationSortArray(): any {
    return Object.keys(OrganizationSortEnum).filter(key => typeof OrganizationSortEnum[key] === 'number')
      .map(key => ({ id: OrganizationSortEnum[key], name: this.localizationService.translateText(key) }));
  }
  getOrganizationPositionSortArray(): any {
    return Object.keys(OrganizationPositionSortEnum).filter(key => typeof OrganizationPositionSortEnum[key] === 'number')
      .map(key => ({ id: OrganizationPositionSortEnum[key], name: this.localizationService.translateText(key) }));
  }
  getYesNoArray(): any {
    return Object.keys(YesNoEnum).filter(key => typeof YesNoEnum[key] === 'number')
      .map(key => ({ id: YesNoEnum[key], name: this.localizationService.translateText(key) }));
  }


  getTargetDataType(): any{
    return Object.keys(TargetDataTypeEnum).filter(key => typeof TargetDataTypeEnum[key] === 'number')
    .map(key => ({ id: TargetDataTypeEnum[key], name: key }));
  }

  getApplicantBehaviourEventTypes(): any{
    return Object.keys(EApplicantBehaviourEventType).filter(key => typeof EApplicantBehaviourEventType[key] === 'number')
    .map(key => ({ id: EApplicantBehaviourEventType[key], name: this.localizationService.translateText(key)}));
  }

  getQuestionTypesArray(): any {
    return Object.keys(EQuestionContentURLType).filter(key => typeof EQuestionContentURLType[key] === 'number')
      .map(key => ({ id: EQuestionContentURLType[key], name: this.localizationService.translateText(key) }));
  }

  getLanguages(): any{
    return Object.keys(ELanguages).filter(key => typeof ELanguages[key] === 'string')
    .map(key => ({ id: ELanguages[key], name: this.localizationService.translateText(key)}));
  }

  getVoiceSpeechSpeedTypes(): any {
    return Object.keys(EVoiceSpeechSpeed).filter(key => typeof EVoiceSpeechSpeed[key] === 'number')
      .map(key => ({ id: EVoiceSpeechSpeed[key], name: this.localizationService.translateText(key) }));
  }

  getDeviceTypes(): any {
    return Object.keys(EDeviceType).filter(key => typeof EDeviceType[key] === 'number')
      .map(key => ({ id: EDeviceType[key], name: this.localizationService.translateText(key) }));
  }

  getQuestionCollectionCategories(): any {
    return Object.keys(QuestionCollectionCategoryEnum).filter(key => typeof QuestionCollectionCategoryEnum[key] === 'string')
      .map(key => ({ id: QuestionCollectionCategoryEnum[key], name: this.localizationService.translateText(key) }));
  }
  getUserGroupName(): any {
    return Object.keys(UserGroupEnum).filter(key => typeof UserGroupEnum[key] === 'string')
      .map(key => ({ id: UserGroupEnum[key], name: this.localizationService.translateText(key) }));
  }

  getStepNameByCategories(): any {
    return Object.keys(StepNameCategoryNameEnum).filter(key => typeof StepNameCategoryNameEnum[key] === 'string')
      .map(key => ({ id: StepNameCategoryNameEnum[key], name: this.localizationService.translateText(key) }));
  }

  getOfferRejectTypes(): any {
    return Object.keys(OfferRejectedReasons).filter(key => typeof OfferRejectedReasons[key] === 'number')
      .map(key => ({ id: OfferRejectedReasons[key], name: this.localizationService.translateText(key) }));
  }
  getQuestionCategories(): any {
    return Object.keys(EQuestionCategories).filter(key => typeof EQuestionCategories[key] === 'string')
      .map(key => ({ id: EQuestionCategories[key], name: this.localizationService.translateText(key) }));
  }

  getWritingAnalysisMap(): any {
    return Object.keys(WritingAnalysisMap).filter(key => typeof WritingAnalysisMap[key] === 'string')
      .map(key => ({ id: WritingAnalysisMap[key], name: this.localizationService.translateText(key) }));
  }

  geInvitationStatusArray(): any {
    return Object.keys(EInvitationStatus).filter(key => typeof EInvitationStatus[key] === 'string')
      .map(key => ({ id: EInvitationStatus[key], name: this.localizationService.translateText(key) }));
  }

  getWritingErrorListMap(): any {
    return Object.keys(WringErrorColorEnum).filter(key => typeof WringErrorColorEnum[key] === 'string')
      .map(key => ({ id: WringErrorColorEnum[key], name: key }));
  }

  getAccountType(): any {
    return Object.keys(EOrganizationAccountType).filter(key => typeof EOrganizationAccountType[key] === 'number')
      .map(key => ({ id: EOrganizationAccountType[key], name: this.localizationService.translateText(key) }));
  }
  getLicenseTypeName(): any {
    return Object.keys(ELicenseType).filter(key => typeof ELicenseType[key] === 'number')
      .map(key => ({ id: ELicenseType[key], name: this.localizationService.translateText(key) }));
  }

  getLicensePaymentStatus(): any {
    return Object.keys(ELicensePaymentStatus).filter(key => typeof ELicensePaymentStatus[key] === 'number')
      .map(key => ({ id: ELicensePaymentStatus[key], name: this.localizationService.translateText(key) }));
  }

  getOneShotLicensePaymentStatus(): any {
    return Object.keys(EOneShotLicensePaymentStatus).filter(key => typeof EOneShotLicensePaymentStatus[key] === 'number')
      .map(key => ({ id: EOneShotLicensePaymentStatus[key], name: this.localizationService.translateText(key) }));
  }
  getTranslatedAttributeList(): { name: string; code: string }[] {
    return AttributeList.map(attribute => {
      return {
        name: this.localizationService.translateText(attribute.name),
        code: attribute.code
      };
    });
  }

  getLicenseDealRange(): any {
    return Object.keys(EDealRange).filter(key => typeof EDealRange[key] === 'number')
      .map(key => ({ id: EDealRange[key], name: this.localizationService.translateText(key) }));
  }

  getLicenseDealRangeWithoutTrial(): any {
    return Object.keys(EDealRangeWithoutTrial).filter(key => typeof EDealRangeWithoutTrial[key] === 'number')
      .map(key => ({ id: EDealRangeWithoutTrial[key], name: this.localizationService.translateText(key) }));
  }

  getOrganizationApiKeyRangeTypesArray(): any {
    return Object.keys(EOrganizationApiKeyExpirationRangeType).filter(key => typeof EOrganizationApiKeyExpirationRangeType[key] === 'number')
      .map(key => ({ id: EOrganizationApiKeyExpirationRangeType[key], name: this.localizationService.translateText(key) }));
  }
}
