import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GeneralApplicationFormRoutingModule } from './general-application-form-routing.module';
import { GeneralApplicationFormComponent } from './general-application-form/general-application-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TranslateModule } from '@ngx-translate/core';
import { ImageModule } from 'primeng/image';
import { TagModule } from 'primeng/tag';
import { SpeedDialModule } from 'primeng/speeddial';
import { CardModule } from 'primeng/card';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ToolbarModule } from 'primeng/toolbar';
import { TableModule } from 'primeng/table';
import { InputSwitchModule } from 'primeng/inputswitch';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { SharedVinterModule } from '@app/shared/module/shared/sharedvinter.module';
import { MessagesModule } from 'primeng/messages';
import { TimelineModule } from 'primeng/timeline';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { NgOtpInputModule } from 'ng-otp-input';
import { FileUploadModule } from 'primeng/fileupload';
import { PdfViewerModule } from 'ng2-pdf-viewer';


@NgModule({
  declarations: [
    GeneralApplicationFormComponent
  ],
  imports: [
    CommonModule,
    GeneralApplicationFormRoutingModule,
    CommonModule,
    NgOtpInputModule,
    TranslateModule,
    ImageModule,
    TagModule,
    InputTextareaModule,
    SpeedDialModule,
    InputTextModule,
    CardModule,
    AutoCompleteModule,
    ToolbarModule,
    TableModule,
    InputSwitchModule,
    FormsModule,
    ButtonModule,
    ReactiveFormsModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    ToastModule,
    TooltipModule,
    SharedVinterModule,
    MessagesModule,
    TimelineModule,
    SplitButtonModule,
    MenuModule,
    ToggleButtonModule,
    SelectButtonModule,
    DropdownModule,
    RadioButtonModule,
    FileUploadModule,
    PdfViewerModule
  ]
})
export class GeneralApplicationFormModule { }
