<div class="grid pt-6">
    <div class="col">
        <h3>{{createdQuestionCollection.title}}</h3>
        <p class="descFont">{{createdQuestionCollection.description}}</p>
        <div class="mt-6">
            <span class="position-card-org-header"
            [ngStyle]="{'padding-left': '1.2rem','padding-right': '1.2rem','background-color': 'rgba(208, 249, 207, 1)','color': 'rgba(50, 197, 47, 1)'}">{{'test'}}</span>
        </div>
        <div class="flex w-full mt-6">
            <div class="cardSidebar flex grid ">
                <span class="flex col-12 p-0 pb-1  justify-content-center align-items-center sideBarFont">
                    <img class="custom-svg-icon pr-2" src="/assets/vinter/icons/positionDetaillanguage.svg"
                        height="25px" width="25px" />
                    <span style=" padding-top: 0.2rem;">{{"Key.Language" | translate}}</span>
                </span>
                <div class="flex col-12 p-0 justify-content-center">
                    <div class="lineSidebar col-12 p-0"></div>
                </div>
                <span class="flex col-12 pt-1 p-0 justify-content-center sideBarTimer">
                    {{language}}
                </span>
            </div>
        </div>
    </div>
    <div class="vl"></div>
    <div class="col w-full select-question-page select-question-page-h">
        <h4>{{"Key.Questions" | translate}}</h4>
        <div *ngIf="previewQuestions" class=" list-max-height">
            <ng-container *ngIf="previewQuestions.length > 0">
                <div *ngFor="let question of previewQuestions; let i = index" class="question-container">

                    <div class="grid">
                        <div class="question-number col-1 pt-5 mr-0 pr-0" style="width: 3%;">
                            {{ i + 1 }}.
                        </div>
                        <div class="col-1 w-min pt-4 pr-0 ml-2 img-position"
                            *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Video">
                            <img class="custom-svg-question-icon align-self-center"
                                src="/assets/vinter/icons/videoQuestion.svg" />
                        </div>
                        <div class="col-1 w-min pt-4 pr-0 ml-2 img-position"
                            *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.OpenEnded">
                            <img class="custom-svg-question-icon align-self-center"
                                src="/assets/vinter/icons/openEndedQuestion.svg" />
                        </div>

                        <div class="col-1 w-min pt-4 pr-0 ml-2 img-position"
                            *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice">
                            <img class="custom-svg-question-icon align-self-center"
                                src="/assets/vinter/icons/Quiz.svg" />
                        </div>
                        <div class="col-1 w-min pt-4 pr-0 ml-2 img-position"
                            *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Coding">
                            <img class="custom-svg-question-icon align-self-center"
                                src="/assets/vinter/icons/coding.svg" />
                        </div>


                        <div class="question-content col">
                            <div  class="flex align-items-center">
                                <div class=" text-blue-400 flex align-items-center">
                                    <i class="pi pi-stopwatch static text-xs cursor-auto mr-1"></i>
                                    {{question.duration}}'
                                </div>
                                <div class="border-right-1 mx-2 h-1rem border-gray-100"></div>
                                <div class=" text-blue-800 flex align-items-center">
                                    <img class="mr-1" src="/assets/vinter/icons/question-weight-icon.svg" height="16px" width="16px" />
                                    <div>{{question.weight}}</div>
                                </div>
                            </div>

                            <div class="flex">
                                <div [ngClass]="{'displayMore': question.showMoreText,'description2': question.showMoreText,'description1': !question.showMoreText}"
                                    [innerHTML]="question.questionContent.questionText">
                                </div>
                                <div class="flex align-items-start cursor-pointer" (click)="toggleDisplay(question)">
                                    <p class="more-field mb-0" *ngIf="!question.showMoreText">{{'Key.More' | translate}}</p>
                                    <img *ngIf="!question.showMoreText" class="align-self-start more-icon ml-2"
                                        src="/assets/vinter/icons/more-icon-right.svg" />
                                    <img *ngIf="question.showMoreText" class="align-self-start more-icon ml-2"
                                        src="/assets/vinter/icons/more-icon-down.svg" />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div *ngIf="question.showMoreText">
                        <div class="content-position">
                            <div *ngIf="question.questionContent.contentURLType == QuestionContentType.Image"
                                class="mb-3">
                                <p-image [src]="question.questionContent.contentURLSigned ?? question.questionContent.contentURL"
                                    [imageStyle]="{'width': '14rem','border-radius':'6px'}" [preview]="true"></p-image>
                            </div>

                            <div *ngIf="question.questionContent.contentURLType == QuestionContentType.Video">
                                <video class="mb-3" [src]="question.questionContent.contentURLSigned ?? question.questionContent.contentURL"
                                    style="border-radius:6px;width: 14rem;" controls controlslist="nodownload"
                                    disablepictureinpicture></video>
                            </div>

                            <div *ngIf="question.questionContent.contentURLType == QuestionContentType.Audio">
                                <audio class="mb-3" [src]="question.questionContent.contentURLSigned ?? question.questionContent.contentURL" style="width: 50%;"
                                    controls controlslist="nodownload"></audio>
                            </div>


                            <div *ngIf="question.keywords.length > 0" class="question-container-sideBar-text">
                                <div class="flex mb-3">
                                    <div class="keyword-text">
                                        {{'Key.Keywords' | translate}}
                                    </div>
                                    <div>
                                        <p-badge class="radius" value={{question.keywords.length}}></p-badge>
                                    </div>
                                </div>
                                <div>
                                    <div class="inline -ml-2" *ngFor="let item of question.keywords">
                                        <p-tag class="keyword-tag keyword-value-color" value={{item.name}}></p-tag>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice"
                                class="answer-container">
                                <div class="flex">
                                    <div class="flex flex-column gap-2">
                                        <div *ngFor="let choice of question.choiceContents; let i = index"
                                            class="radio-button-card field-checkbox"
                                            [ngClass]="{'correct-choice': choice.isCorrect}">
                                            <div class="flex align-items-center">
                                                <p-radioButton [disabled]="true" [value]="choice.isCorrect"
                                                [ngModel]="true" ></p-radioButton>
                                                <label [for]="choice.choiceText"
                                                    [ngClass]="{'choice-text': !choice.isCorrect,'correct-choice-text':choice.isCorrect}"
                                                    class="ml-2" [innerHTML]="choice.choiceText"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.OpenEnded"
                                class="video-answer-container">
                                <p class="video-answer-text">{{'Key.OpenEndedQuestionExplanation' | translate}}</p>
                            </div>

                            <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Video"
                                class="video-answer-container">
                                <p class="video-answer-text">{{'Key.VideoQuestionExplanation' | translate}}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </ng-container>
            <div *ngIf="previewQuestions.length === 0" class="no-questions-message">{{'Key.NoQuestions' | translate}}
            </div>
        </div>
    </div>
</div>

<p-toast position="bottom-center"></p-toast>
