import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService } from '@app/core/configuration.service';
import { HttpBaseService } from '@app/core/http/http-base.service';
import { LocalizationService } from '@app/core/localization.service';
import { NotifyService } from '@app/core/notify.service';
import { ApiControllerEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs/internal/Observable';
import { InvitationPostItem } from '../models/Invitation/invitation-post-item';
import { InvitationCheckItem, ResendMailItem } from '../models/Invitation/invitation-check-item';

@Injectable({
  providedIn: 'root'
})
export class GeneralApplicationInvitationService extends HttpBaseService {

  constructor(private http: HttpClient
    , private notifyServ: NotifyService
    , private localizationService: LocalizationService
    , private configService: ConfigurationService) {
    super(http, ApiControllerEnum.GeneralApplicationInvitation, notifyServ, localizationService, configService);
  }

  getInvitedMemberListByFilter(filter: any): Observable<InvitationPostItem[]> {
    return this.post("getInvitedMemberListByFilter", filter, true);
  }

  checkInvitationOnDB(obj: InvitationCheckItem[]): Observable<InvitationCheckItem[]> {
    return this.post("CheckInvitationOnDB", obj, true);
  }

  createGeneralApplicationInvitation(invitationList: any): Observable<any> {
    return this.post("createGeneralApplicationInvitation", invitationList, true, false);
  }

  resendInvitationMail(obj: ResendMailItem): Observable<ResendMailItem> {
    return this.post("ResendInvitationMail", obj, true);
  }

  getListByFilter(filter: any): Observable<InvitationCheckItem[]> {
    return this.post("GetListByFilter", filter, true);
  }
}
