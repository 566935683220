import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigurationService, HttpBaseService, LocalizationService, NotifyService } from '@app/core';
import { ApiControllerEnum, EntityStateEnum } from '@app/shared/common/enums';
import { Observable } from 'rxjs';
import { PositionStepListItem } from '../models/position-step-list';
import { PositionStepFormItem } from '../models/position-step-form';

@Injectable({
  providedIn: 'root'
})
export class PositionStepService extends HttpBaseService{

    constructor(private http: HttpClient
        , private notifyServ: NotifyService
        , private localizationService: LocalizationService
        , private configService: ConfigurationService) {
        super(http, ApiControllerEnum.PositionStep, notifyServ, localizationService, configService);
      }

      getListByFilter(filter: any): Observable<PositionStepListItem[]> {
        return this.post("GetListByFilter", filter, true);
      }

      getDataByFilter(filter: any): Observable<PositionStepListItem> {
        return this.post("GetDataByFilter", filter, true);
      }

      getPositionStepNameList(filter: any): Observable<any[]> {
        return this.post("GetPositionStepNameList", filter, true);
      }

      addList(obj: PositionStepFormItem[]): Observable<PositionStepFormItem[]> {
        return this.post("SavePositionStepList", obj, true);
      }
}
