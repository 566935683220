import { EntityStateEnum } from "@app/shared/common/enums";

export class KeywordListItem {
  id: string;
  name: string;
  positionId: string;
  questionId: string;
  entityState: EntityStateEnum;
  xmin:string;

}
