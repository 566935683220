import { Component, Input } from '@angular/core';
import { EQuestionCategories } from '@app/shared/common/enums';

@Component({
  selector: 'app-custom-progressbar',
  templateUrl: './custom-progressbar.component.html',
  styleUrls: ['./custom-progressbar.component.scss']
})
export class CustomProgressbarComponent {

  @Input() answeredQuestionsWithId;
  @Input() itemId;
  @Input() positionStepId;
  @Input() isGrammarVocabulary;

  QuestionCategories = EQuestionCategories;


  getCurrentStepQuestions(id) {
    if (!(this.isGrammarVocabulary ?? false)) {
      return this.answeredQuestionsWithId.filter(x => x.id === id);
    }
    else {
      let vocabulary = this.answeredQuestionsWithId.find(x => x.id === this.QuestionCategories.Vocabulary && x.positionStepId === this.positionStepId);
      let grammer = this.answeredQuestionsWithId.find(x => x.id === this.QuestionCategories.Grammer && x.positionStepId === this.positionStepId);

      let grammerVocabularyObj = {
        id: 1,
        category: 'grammerVocabulary',
        questionCount: vocabulary.questionCount + grammer.questionCount,
        answeredQuestionsCount: vocabulary.answeredQuestionsCount + grammer.answeredQuestionsCount,
        notAnsweredQuestionsCount: (vocabulary.questionCount + grammer.questionCount) - (vocabulary.answeredQuestionsCount + grammer.answeredQuestionsCount),
        correctAnsweredQuestionsCount: vocabulary.correctAnsweredQuestionsCount + grammer.correctAnsweredQuestionsCount,
        incorrectAnsweredQuestionsCount: vocabulary.incorrectAnsweredQuestionsCount + grammer.incorrectAnsweredQuestionsCount,
        averageScores: (vocabulary.totalScores + grammer.totalScores) / (vocabulary.questionCount + grammer.questionCount),
        totalScores: (vocabulary.totalScores + grammer.totalScores) * 100
      }

      let grammerVocabularyList = [];
      grammerVocabularyList.push(grammerVocabularyObj);

      return grammerVocabularyList;
    }

  }

  calculateProgressWidth(answeredQuestionsCount: number, maxCount): number {
    return (answeredQuestionsCount / maxCount) * 100;
  }

}
