<div>
                    <h5 class="h5-font mt-5">{{'Key.QuestionList' | translate}}</h5>
                    <div *ngIf="questions" class="overflow-y-auto max-h-50rem">
                        <ng-container *ngIf="questions.length > 0">
                            <div *ngFor="let question of questions; let i = index" class="question-container">
                                <div class="grid ">
                                    <div class="question-number col-1 pt-5 mr-0 pr-0" style="width: 3%;">
                                        {{ i + 1 }}.
                                    </div>
                                    <div class="col-1 pt-4" *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.Video">
                                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/videoQuestion.svg"/>   
                                    </div>
                                    <div class="col-1 pt-4" *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.OpenEnded">
                                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/openEndedQuestion.svg"/>   
                
                                    </div>
                                    <div class="col-1 pt-4" *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice">
                                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/Quiz.svg"/>   
                                    </div>
                                    <div class="question-content flex col-10 pl-0">
                                        <p [ngClass]="{'displayMore': question.showMoreText,'description2': question.showMoreText,'description1': !question.showMoreText}"
                                        [innerHTML]="question.questionContent.questionText">
                                            <!-- {{ question.questionContent.questionText }} -->
                                        </p>
                                       
                                        <div class="flex align-items-start cursor-pointer" (click)="toggleDisplay(question)">
                                            <p class="more-field mb-0" *ngIf="!question.showMoreText">{{'Key.More' | translate}}</p> 
                                            <img *ngIf="!question.showMoreText" class="align-self-start more-icon ml-2" src="/assets/vinter/icons/more-icon-right.svg"/>
                                            <img *ngIf="question.showMoreText" class="align-self-start more-icon ml-2" src="/assets/vinter/icons/more-icon-down.svg"/>
                                        </div>
                                    </div>
                                </div>    
                                
                                  <div *ngIf="question.showMoreText">                           
                                    <div class="content-position">              
                                        <div *ngIf="question.expectedAnswerType === questionExpectedAnswerTypeEnum.MultipleChoice" class="answer-container">
                                            <div class="flex">
                                                <div class="flex flex-column gap-2">
                                                    <div *ngFor="let choice of question.choiceContents; let i = index"
                                                        class="radio-button-card field-checkbox">
                                                        <div class="flex align-items-center">
                                                            <p-radioButton [disabled]="true" [value]="choice.choiceText"
                                                                [(ngModel)]="selectedRadioValue"></p-radioButton>
                                                            <label [for]="choice.choiceText" class="choice-text ml-2" [innerHTML]="choice.choiceText"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <div *ngIf="questions.length === 0" class="no-questions-message">No questions available.</div>
                    </div>
                </div>
                