<div>
    <div class="overflow-y-auto max-h-30rem">
        <ng-container>
            <div class="question-container">
                <div class="grid">
                    <div class="col-1 pt-4">
                        <img class="custom-svg-question-icon align-self-center" src="/assets/vinter/icons/videoQuestion.svg"/>  
                    </div>
                    <div class="question-content col-10">
                        {{positionStepDescription}}
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
